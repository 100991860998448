.settings {
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;

    & > div {
      display: flex;
      align-items: center;

      & > span {
        margin-right: 0;
        margin-left: 16px;
      }
    }

    &-version {
      font-weight: bold;
      color: #000;
    }

    &-str {
      min-width: 340px;
      color: #222;
    }

    &-copy {
      cursor: pointer;
      width: 16px;
      height: 16px;
      margin-left: 16px;
    }

    &-disabled {
      cursor: default;

      justify-content: center;

      width: 65px;
      margin-right: 32px;
      margin-left: 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      color: #8590a6;
      text-align: center;

      background: #edf2f9;
      border-radius: 12px;
    }

    .settings-enabled {
      cursor: pointer;

      margin-right: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      color: #4d84ff;
    }

    .settings-enabled-single {
      margin-right: 8px;
    }
  }

  &-default {
    cursor: default;

    justify-content: center;

    width: 60px;
    padding-right: 8px;
    padding-left: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #a36b2b;
    text-align: center;

    background: #ecc99d;
    border-radius: 12px;
  }

  &-choose {
    cursor: pointer;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    color: #4d84ff;
  }
}

.color-button {
  background: linear-gradient(270deg, #794ce8 0%, #e74caf 100%) !important;
}

.copy-btn {
  cursor: pointer;

  display: block;

  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 12px;

  background: url('~@/assets/icons/ic_copy.png') no-repeat;
  background-size: cover;
}

.es-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
