.layout-container {
  height: 100%;
  background: #f8f9fe !important;

  .fixed-box {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: transparent;
  }

  .sidebar-wrapper {
    flex: 0 0 256px !important;

    width: 256px !important;
    min-width: 256px !important;
    max-width: 256px !important;

    background: #fff;

    .logo-wrapper {
      cursor: pointer;

      display: flex;
      place-content: center center;
      align-items: center;

      height: 64px;
      margin-bottom: 8px;

      .version {
        width: 32px;
        height: 20px;
        margin-left: 12px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        color: #fff;
        text-align: center;

        background: linear-gradient(310deg, #199de8 0%, #04f1a9 100%);
        border-radius: 8px;
      }

      .logo {
        width: 164px;
        height: 28px;
        background: url('~@/assets/icons/logo_cobo.png') no-repeat;
        background-size: 164px 28px;
      }
    }

    .back-wrapper {
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 12px;
      margin-left: 40px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #4d84ff;

      .back-icon {
        transform: rotate(90deg);
        width: 12px;
        height: 12px;
        margin-right: 6px;
      }
    }
  }

  .user-info-box {
    position: absolute;
    bottom: 144px;
    width: 100%;
    padding: 0 24px;
  }

  .footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 125px;

    border-top: 1px solid #edf2f9;
  }

  .menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-right: 32px;
    padding-left: 32px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    color: #222;

    background: #f8f9fe;
    border-bottom: 1px solid #edf2f9;

    .menu-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .go-to-V2-button {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 168px;
      height: 44px;
      margin-right: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      color: #4d84ff;

      background: #fff;
      border: 2px solid #e7ebf2;
      border-radius: 12px;

      .go-to-V2-button-icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        background: url('~@/assets/icons/ic_goto@2x.png') no-repeat;
        background-size: 16px 16px;
      }
    }
  }

  .menu-wrapper {
    margin-left: 24px;
    padding-right: 24px;

    .menu-icon {
      width: 20px;
      height: 20px;
    }

    .menu-safe-icon {
      background: url('~@/assets/icons/ic_home_off.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-safe-on-icon {
      background: url('~@/assets/icons/ic_home_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-bot-icon {
      background: url('~@/assets/icons/ic_bot.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-bot-on-icon {
      background: url('~@/assets/icons/ic_bot_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-team-icon {
      background: url('~@/assets/icons/ic_team.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-team-on-icon {
      background: url('~@/assets/icons/ic_team_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-settings-icon {
      background: url('~@/assets/icons/ic_settings.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-settings-on-icon {
      background: url('~@/assets/icons/ic_settings_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-investment-icon {
      background: url('~@/assets/icons/ic_tools_off@3x.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-investment-on-icon {
      background: url('~@/assets/icons/ic_tools_on@3x.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-me-icon {
      background: url('~@/assets/icons/ic_me_off@2x.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-me-on-icon {
      background: url('~@/assets/icons/ic_me_on@2x.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-account-icon {
      background: url('~@/assets/icons/ic_account.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-account-on-icon {
      background: url('~@/assets/icons/ic_account_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-org-icon {
      background: url('~@/assets/icons/ic_org.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-org-on-icon {
      background: url('~@/assets/icons/ic_org_on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-transaction-on-icon {
      background: url('~@/assets/icons/ic_transaction-on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-transaction-icon {
      background: url('~@/assets/icons/ic_transaction-off.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-trading-on-icon {
      background: url('~@/assets/icons/ic_trade-on.png') no-repeat;
      background-size: 20px 20px;
    }

    .menu-trading-icon {
      background: url('~@/assets/icons/ic_trade-off.png') no-repeat;
      background-size: 20px 20px;
    }

    :global {
      .ant-menu {
        border: none;
      }

      .ant-menu-item {
        height: 44px;
        margin: 0;
        padding-left: 18px !important;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #8590a6;

        border-radius: 12px !important;

        :hover {
          color: #4d84ff;
        }

        .ant-menu-title-content {
          margin-left: 16px;
        }
      }

      .ant-menu-item:not(:last-child) {
        margin-bottom: 8px;
      }

      .ant-menu-inline {
        ::after {
          border-right: 0 solid transparent;
        }
      }

      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: #4d84ff;
      }

      .ant-menu-item-selected {
        border-radius: 8px;

        .ant-menu-title-content {
          font-family: Ubuntu-Medium, Ubuntu;
          color: #fff;
        }
      }

      .ant-menu-light:not(.ant-menu-horizontal)
        .ant-menu-item:not(.ant-menu-item-selected):hover,
      .ant-menu-light
        > .ant-menu:not(.ant-menu-horizontal)
        .ant-menu-item:not(.ant-menu-item-selected):hover {
        color: rgb(133, 144, 166);
        background-color: #fff;
      }
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background: #f8f9fe;
  }

  .tooltip {
    cursor: pointer;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin-right: 16px;
    margin-left: 8px;

    vertical-align: sub;

    background: url('~@/assets/icons/ic_tips.png') no-repeat;
    background-size: cover;
  }
}
