.edit-safe-modal {
  display: block;

  .form-item {
    margin-top: 32px;
  }

  .username-item {
    margin-top: 0;
  }

  .settings-item-label {
    display: block;

    height: 20px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .settings-item-input-address {
    height: 48px;
  }

  :global {
    .ant-input {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;

      border-radius: 12px;
      caret-color: #4d84ff;
    }

    .ant-input[disabled] {
      color: #8590a6;
      border-color: #e7ebf2;
    }

    .ant-input[disabled]:hover {
      border-color: #e7ebf2;
      border-right-width: 1.5px;
    }

    .ant-input:hover {
      border-color: #e7ebf2;
      border-right-width: 1.5px;
    }

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: none;
    }
  }
}
