.cobo-table-wrapper {
  overflow: auto;

  .cobo-table {
    :global {
      .ant-table {
        background: transparent;

        .ant-table-tbody {
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;

          .ant-table-placeholder {
            :hover {
              background-color: #fff;
            }
          }
        }
      }

      .ant-table-thead > tr > th,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        position: relative;
        padding: 12px 24px;
        overflow-wrap: break-word;
      }

      .ant-table-column-sorters {
        display: flex;
        flex: auto;
        align-items: center;
        justify-content: flex-start;

        .ant-table-column-title {
          position: relative;
          z-index: 1;

          display: flex;
          flex: 0;

          margin-right: 8px;

          white-space: nowrap;
        }
      }

      .ant-table-thead {
        background: #fcfdff;
        border-radius: 12px 12px 0 0;
        box-shadow: inset 0 -1px 0 0 #edf2f9;

        th::before {
          display: none;
          width: 0;
        }
      }

      .ant-table-thead > tr > th {
        background: #fcfdff;
      }

      .ant-table-thead
        > tr
        > th:not(
          :last-child,
          .ant-table-selection-column,
          .ant-table-row-expand-icon-cell,
          [colspan]
        )::before {
        content: '';
        display: none;
        width: 0 !important;
      }

      .ant-table-thead > tr > .ant-table-cell {
        height: 40px;

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: normal !important;
        line-height: 16px;
        color: #8590a6;
      }

      .ant-table-tbody > tr > td {
        padding: 16px 24px;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #222;

        background: rgb(255 255 255 / 0%);
        border-bottom: 1px solid #edf2f9;
      }

      .ant-table-tbody > tr:hover > td {
        background-color: #f1f5ff;
      }

      .ant-table-container table {
        background: #fff;
        border: 1px solid #edf2f9;
        border-radius: 12px;
      }

      .ant-table-container .ant-table-tbody > tr:last-child td {
        border: none;
      }

      .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 12px;
      }

      .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 12px;
      }
    }
  }
}

.title-with-tooltip-box {
  font-family: Ubuntu;
  font-size: 12px;
  color: #8590a6;

  .tip-icon {
    cursor: pointer;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }

  .tip-icon-info {
    background: url('~@/assets/icons/role_tip.png') no-repeat;
    background-size: 16px 16px;
  }

  .table-title {
    display: inline-block;
    line-height: 16px;
    vertical-align: middle;
  }
}
