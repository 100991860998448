.permission-modal {
  transition: visibility 0.5s;

  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-top: 0;
      }
    }
  }

  .subtitle {
    margin-top: 4px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8994ab;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 32px;

  .action-btn {
    flex: 1;
  }

  .action-btn + .action-btn {
    margin-left: 32px;
  }
}

.table-actions {
  display: flex;
  flex-direction: row;

  .btn {
    cursor: pointer;

    width: 58px;
    min-width: 48px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
  }

  .edit {
    width: 40px;
    min-width: 40px;
  }

  .btn + .btn {
    margin-left: 30px;
  }
}

.view-permission-page {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 8px;

    .add-permission-btn {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .permission-name-wrapper {
    display: flex;
    flex-direction: row;

    .permission-name {
      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #222;
    }

    .label {
      margin-left: 8px;
      padding: 2px 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      border-radius: 6px;

      &.strategy {
        color: #6b3bed;
        background-color: #f3efff;
      }

      &.customized {
        color: #26c165;
        background-color: #e8fff1;
      }

      &.imported {
        color: #ffa521;
        background-color: #fff2de;
      }
    }
  }
}

.permission-names {
  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #222;
  word-break: break-all;
}

.permission-label {
  display: inline-block;

  height: 20px;
  margin-left: 8px;
  padding: 0 8px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  word-break: keep-all;

  border-radius: 6px;
}
