.name-logo {
  display: flex;
  place-content: center center;
  align-items: center;

  width: 20px;
  height: 20px;

  color: #fff;

  border-radius: 10px;
}
