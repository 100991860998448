.settings-item {
  display: block;
  // padding: 32px;
  .settings-content {
    padding: 0 32px 40px;
  }

  .settings-header {
    display: flex;
    justify-content: space-between;
    // height: 32px;
    padding: 16px 32px;

    background: rgb(248 249 254 / 50%);
    box-shadow: 0 -1px 0 0 #edf2f9 inset;

    .settings-header-tips {
      cursor: pointer;
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-tips {
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }

    .settings-title {
      height: 32px;
      margin-bottom: 0;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      color: #222;
    }

    .button-box {
      display: flex;
      flex-wrap: nowrap;

      & > div:not(:last-child) {
        margin-right: 16px;
      }
    }

    &-button {
      width: 82px;
      height: 32px;
      border-radius: 8px;
    }
  }
}
