.address-table-container {
  .btn-wrapper {
    text-align: right;
  }
}

.add-address-icon {
  width: 12px;
  height: 12px;
}

.add-address-button {
  width: 160px !important;
  height: 40px !important;
  margin-bottom: 20px;

  font-family: Ubuntu-Bold;
  font-size: 16px;
  color: #fff;

  background-color: #4d84ff;
  border-radius: 12px !important;
}

.address-table {
  .address-label {
    padding: 2px 12px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;

    background: #a36b2b;
    border-radius: 12px;
  }

  .address-label-ellipsis {
    cursor: pointer;

    overflow: hidden;
    display: inline-block;

    max-width: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .address-more {
    cursor: pointer;

    display: inline-block;

    height: 20px;
    margin-left: 5px;
    padding: 0 3px;

    font-family: Ubuntu-Medium;
    font-size: 12px;
    color: #fff;
    text-align: center;

    background: #4d84ff;
    border-radius: 10px;
  }

  .active {
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #65c97a;
  }

  .inactive {
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #e74e5a;
  }
}

.tab-content-address {
  padding-bottom: 40px;
  text-align: center;
  border-radius: 20px;

  .image {
    width: 160px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .address-image {
    width: 80px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .text {
    margin-bottom: 5px;
    font-size: 16px;
    color: #8590a6;
  }

  .link {
    cursor: pointer;
    font-size: 16px;
    color: #4d84ff;
  }
}

.safe {
  cursor: pointer;
  display: flex;
  align-items: center;

  .safe-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .safe-info {
    .safe-name {
      display: block;

      height: 20px;
      margin-bottom: 4px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .safe-address {
      height: 20px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #222;
    }
  }
}

.safe-roles {
  display: flex;

  height: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d84ff;

  .safe-role-more {
    cursor: pointer;

    width: 20px;
    height: 20px;
    margin-left: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    text-align: center;

    background: #4d84ff;
    border-radius: 10px;
  }

  .safe-role {
    cursor: pointer;
  }
}

.action-button {
  cursor: pointer;

  height: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d84ff;
}
