.webhook-content {
  display: flex;
  flex-direction: column;

  margin: 24px 0 40px;
  padding: 16px 24px;

  background: #f0f7fe;
  border-radius: 12px;
}

.mode {
  display: block;

  width: 40px;
  height: 24px;
  margin: 22px 0;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #222;
  text-align: center;

  &.or {
    background: #ecc99d;
  }

  &.and {
    background: #70f6fc;
  }
}

.trigger-content {
  display: flex;
  flex-direction: column;

  margin: 24px 0 0;
  padding: 16px 24px;

  background: #f0f7fe;
  border-radius: 12px;

  .mode {
    margin: 4px 0;
  }

  p:last-of-type {
    display: none;
  }

  .trigger-checks {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .trigger-tip {
    margin-top: 8px;
  }

  .trigger-item {
    padding: 16px 0;
    background: #f0f7fe;
    border-radius: 12px;
  }

  .trigger-item + .trigger-item {
    margin-top: 24px;
  }

  .trigger-option-wrapper {
    display: flex;
    flex-direction: row;

    .trigger-name {
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    .trigger-checkbox {
      margin-left: 16px;

      span {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }

      :global {
        .ant-checkbox + span {
          padding-right: 0;
          padding-left: 8px;
        }

        .ant-checkbox {
          font-size: 20px;
          line-height: 20px;

          .ant-checkbox-inner {
            width: 20px;
            height: 20px;

            font-size: 20px;
            line-height: 20px;

            background: url('~@/assets/icons/checkbox_off_reg.png') no-repeat;
            background-size: 20px 20px;
            border: none;
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: url('~@/assets/icons/checkbox_on_reg.png') no-repeat;
            background-size: 20px 20px;
            border: none;
          }

          .ant-checkbox-inner::after {
            display: none;
          }
        }
      }
    }

    .trigger-checkbox + .trigger-checkbox {
      margin-left: 32px;
    }
  }

  .trigger-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .name {
      min-width: 130px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    .trigger-content {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;
      align-items: center;

      .reward-amount-selector {
        flex: 1;
        min-width: 120px;
        max-width: 340px;
      }

      .over {
        margin-right: 14px;
        margin-left: 14px;

        font-family: Ubuntu;
        font-size: 20px;
        line-height: 24px;
        color: #222;
      }

      .reward-amount-input {
        flex: 1;

        min-width: 120px;
        max-width: 340px;
        padding: 10px 16px;

        border: 1.5px solid #e7ebf2;
        border-radius: 12px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .time-range-selector {
        flex: 1;
        min-width: 120px;
        max-width: 340px;
        margin-left: 40px;
      }

      .ratio-token-selector {
        flex: 1;
        min-width: 120px;
        max-width: 207px;
      }

      .ratio-op-selector {
        flex: 1;
        min-width: 120px;
        max-width: 160px;
        margin-left: 16px;
      }

      .text-input {
        flex: 1;

        min-width: 120px;
        max-width: 340px;
        margin-left: 16px;
        padding: 12px 16px;

        border: 1.5px solid #e7ebf2;
        border-radius: 12px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        :global {
          .ant-input-suffix {
            font-family: Ubuntu;
            font-size: 20px;
            line-height: 24px;
            color: #222;
          }
        }
      }

      .ratio-input {
        min-width: 120px;
        max-width: 188px;
      }

      .ratio-text {
        margin-left: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }

      .time-every {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }
    }
  }

  .trigger-item-wrapper + .trigger-item-wrapper {
    margin-top: 24px;
  }

  .token-ratio-hint {
    display: flex;
    flex-direction: row-reverse;

    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  // reward receiving
  .hint {
    margin-top: -8px;
    margin-bottom: 24px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  .safe-address-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 8px;

    .safe-address-label {
      min-width: 160px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    .safe-address-value {
      flex: 1;

      max-width: 720px;
      padding: 12px 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      background: #f9f9f9;
      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
    }
  }

  // withdraw
  .withdraw-tokens {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;

      .tip-icon {
        cursor: pointer;

        width: 16px;
        height: 16px;
        margin-left: 6px;

        vertical-align: middle;

        background: url('~@/assets/icons/ic_info.png') no-repeat;
        background-size: 16px 16px;
      }
    }
  }

  .slippage-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .title {
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    .slippage {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;

      .name {
        font-family: Ubuntu;
        font-size: 14px;
        line-height: 24px;
        color: #222;
      }

      .content {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        max-width: 720px;
        padding: 0;

        .wrapper {
          display: flex;
          flex-direction: row;

          .recommend-slippage {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            min-width: 52px;
            height: 32px;
            padding: 4px 8px;

            font-family: Ubuntu-Bold, Ubuntu;
            font-size: 12px;
            font-weight: bold;
            line-height: 24px;
            color: #8590a6;

            border: 1.5px solid #e7ebf2;
            border-radius: 12px;

            &.selected {
              color: #4d84ff;
              border: 1.5px solid #4d84ff;
            }
          }

          .recommend-slippage + .recommend-slippage {
            margin-left: 8px;
          }

          .slippage-input {
            width: 112px;
            height: 32px;
            margin-left: 8px;
            padding: 4px 8px 4px 12px;

            &.slippage-input-focus {
              border-color: #4d84ff !important;
            }

            :global {
              .ant-input {
                font-family: Ubuntu-Medium, Ubuntu;
                font-size: 12px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }

        .fill {
          flex: 1;
        }
      }
    }
  }

  .notice {
    margin-top: 24px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;

    &-text {
      margin-bottom: 0;
      color: #a36b2b;
    }
  }

  .tokens-select {
    flex: 1;
    max-width: 720px;
  }

  // notification
  .notification-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .notification-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      background: url('~@/assets/icons/ic_email.png') no-repeat;
      background-size: cover;
    }

    .notification-content-text {
      flex: 1;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }

  .lamp {
    margin-top: 24px;
  }
}

.section-switch-box {
  display: flex;
  flex-direction: row;
  align-items: center;

  .section-switch-title {
    margin-right: 12px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    color: #000;
  }
}
