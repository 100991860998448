.sign-up-body {
  position: relative;
  width: 480px;
  margin: 0 auto;

  .sign-up-title {
    height: 40px;
    margin-top: 80px;
    margin-bottom: 48px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;
  }

  .sign-up-form {
    width: 100%;

    .form-forgot-password-item-box {
      position: relative;
      margin-bottom: 24px;
    }

    .form-password-item-box {
      position: relative;
      margin-bottom: 20px !important;
    }

    .sign-up-button {
      width: 480px;
      height: 56px;
      padding: 16px 0;

      background: #4d84ff;
      border-radius: 12px;

      span {
        height: 24px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #fff;
      }
    }

    :global {
      .ant-form-item-label > label {
        position: relative;

        display: block;
        align-items: center;

        height: 20px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }

      .ant-form-item {
        margin-bottom: 32px;
        transition: none 0.3s 0.017s linear;
      }

      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        content: '';
        display: none;
      }

      .ant-form-item-label > label::after {
        content: '';
      }

      .ant-btn-primary[disabled] {
        opacity: 0.3;
        background: #4d84ff;
      }
    }
  }

  .sign-up-text {
    height: 24px;
    margin-top: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;

    .sign-up-text-button {
      padding: 0 0 0 10px;
      color: #4d84ff;

      span {
        font-weight: bold;
      }
    }

    :global {
      .ant-btn-text:active,
      .ant-btn-text:hover {
        color: #4d84ff;
        background: transparent;
        border-color: transparent;
      }
    }
  }

  .policy-text {
    height: 24px;
    margin-bottom: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;

    .policy-href {
      cursor: pointer;
      color: #4d84ff;
      text-decoration: underline;
    }
  }
}

.check-password-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .check-password-item {
    width: 50%;
    margin-bottom: 12px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 16px;
    color: #8590a6;
    vertical-align: middle;

    .check-password-tooltip {
      display: inline-block;

      width: 8px;
      height: 8px;
      margin-right: 10px;

      border-radius: 8px;

      &.success {
        background-color: #58bf72;
      }

      &.normal {
        background-color: #e7ebf2;
      }
    }
  }
}
