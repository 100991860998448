.chain-search {
  display: flex;
  align-items: center;
  margin-right: 32px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .active {
    font-family: Ubuntu-Medium, Ubuntu;
    font-weight: 500;
    color: #4d84ff;
    border: 2px solid #4d84ff;
  }

  button {
    margin-left: 12px;
    background: transparent;
    border-radius: 8px;
  }

  .chain-box {
    cursor: pointer;

    position: relative;
    top: 1px;

    width: 24px;
    height: 24px;
    margin-left: 12px;
  }

  .chain-search-inner {
    height: 32px;
    line-height: 32px;
  }

  img {
    width: 24px !important;
    height: 24px !important;
  }
}
