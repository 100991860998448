.item-content {
  display: flex;
  flex: 1;
  justify-content: flex-start;

  .item-input-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-right: 40px;

    .item-input-box {
      display: flex;
      flex-direction: column;

      .item-input-error {
        :global {
          .ant-input-number-input-wrap {
            input {
              color: #ff4d4f;
            }
          }
        }
      }
    }

    .item-input-label {
      margin-top: 12px;
      margin-right: 16px;

      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;
      color: #222;
    }

    .item-input-text {
      margin-top: 4px;
      margin-left: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
    }
  }
}

.slider-content {
  margin-top: 60px;
}
