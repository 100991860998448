.actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  padding-bottom: 32px;

  .action-btn {
    flex: 1;
  }

  .action-btn + .action-btn {
    margin-left: 32px;
  }
}

.add-permission-page {
  .contract-info-wrapper {
    margin-top: 16px;
  }

  .add-contract-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 32px;

    .contract {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #22233f;
    }
  }
}
