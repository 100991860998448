.add-token-notice-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 0 16px;

  p {
    margin-bottom: 0;
  }

  .label {
    margin-top: 0;
    margin-bottom: 0;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;

    .action {
      cursor: pointer;
      color: #4d84ff;
    }

    p {
      margin-bottom: 0;
    }
  }
}
