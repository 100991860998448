.gas-container {
  height: 44px;
  margin-right: 16px;

  .gas-wrapper {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    height: 44px;
    padding: 0 12px;

    background: #fff;
    border: 2px solid #e7ebf2;
    border-radius: 12px;

    .gas-text {
      height: 16px;
      margin-right: 6px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #222;
    }

    .gas-number {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      color: #fff;
      text-align: center;

      background: #e74e5a;
      border-radius: 8px;
    }

    .arrow {
      transform: rotate(180deg);

      width: 12px;
      height: 12px;

      background: url('~@/assets/images/arrow_up.png') no-repeat;
      background-size: 12px 12px;
    }

    .arrow-down {
      transform: rotate(0deg);
    }
  }

  .low-gas-wrapper {
    border: 2px solid #e74e5a;
  }

  .gas-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/gas.png') no-repeat;
    background-size: 16px 16px;
  }
}

.gas-dropdown {
  :global {
    .ant-dropdown-menu {
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%);

      .ant-dropdown-menu-item {
        padding: 0;
        background: #fff;
      }
    }
  }
}

.gas-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 36px;
  padding: 0 12px;

  background: rgb(255 255 255 / 0%);

  .chain {
    display: flex;
    align-items: center;

    .chain-icon {
      margin-right: 12px;
    }

    .balance-number {
      height: 20px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #242d4c;
    }

    .low-gas-number {
      color: #e74e5a;
    }
  }

  .deposit {
    height: 20px;
    margin-left: 48px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
  }
}

.detail-btn {
  cursor: pointer;

  height: 44px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  color: #4d84ff;
  text-align: center;

  border-top: 1px solid #edf2f9;
}

.no-gas-btn {
  border: none;
}
