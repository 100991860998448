.chain-select {
  :global {
    .ant-select-item-option:not(.ant-select-item-option-disabled) {
      color: #222;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      &::after {
        content: '';

        position: absolute;
        top: 16px;
        right: 16px;

        width: 12px;
        height: 12px;

        background: url('~@/assets/icons/ic_right.png') no-repeat;
        background-size: 12px 12px;
      }

      .ant-select-item-option-content {
        color: #4d84ff;
      }
    }
  }
}
