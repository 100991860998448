.filters {
  display: flex;
  flex-flow: row wrap;
  margin-top: 24px;
  margin-bottom: 20px;

  .filter-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filter-name {
      margin-right: 8px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #222;
    }

    :global {
      .ant-select {
        width: 200px;
        height: 32px;
        line-height: 32px;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
        background: transparent;
        border: 1.5px solid #e7ebf2;
        border-radius: 8px;
      }

      .ant-select-focused .ant-select-selector,
      .ant-select-selector:focus,
      .ant-select-selector:active,
      .ant-select-open .ant-select-selector {
        box-shadow: none !important;
      }

      .ant-select-selection-item {
        display: flex;
        align-content: center;
        align-items: center;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        color: #222;
      }

      .ant-select-arrow {
        right: 16px;
      }
    }
  }

  .arrow_icon {
    width: 12px;
    height: 12px;
    transition: transform 300ms;
  }

  .filter-item + .filter-item {
    margin-left: 24px;
  }
}
