.cobo-input-container {
  .cobo-input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .error-input {
      border: 1.5px solid #e74e5a;
    }

    &.with-suffix {
      :global {
        .ant-input-affix-wrapper {
          padding: 10px 16px;
          border: 1.5px solid #e7ebf2;
          border-radius: 12px;
        }

        .ant-input-affix-wrapper:hover {
          border-color: #e7ebf2;
        }

        .ant-input {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #222;

          caret-color: #4d84ff;
        }

        .ant-input[disabled] {
          color: #8590a6;
        }
      }
    }

    &.without-suffix {
      :global {
        .ant-input-affix-wrapper {
          .ant-input {
            padding: 0;
            border: none;
            border-radius: 0;
          }
        }

        .ant-input {
          padding: 10px 16px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #222;

          border: 1.5px solid #e7ebf2;
          border-radius: 12px;
          caret-color: #4d84ff;
        }

        .ant-input[disabled] {
          color: #8590a6;
        }

        .ant-input:focus,
        .ant-input-focused {
          border-color: #e7ebf2;
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }

  .error-msg {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #e74e5a;

    .error-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      background: url('~@/assets/icons/ic_error_info.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  .note {
    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

:global {
  [class^='ant-input'],
  [class*=' ant-input'] {
    font-family: Ubuntu;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:focus-within {
    border-color: #e7ebf2;
    outline: 0;
    box-shadow: none;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 1;
    border-color: #e7ebf2;
    border-right-width: 1.5px;
  }
}
