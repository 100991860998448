.success-body {
  position: relative;
  width: 480px;
  margin: 0 auto;
  margin-top: 64px;

  .sign-up-image {
    width: 96px;
    height: 96px;
    margin: 0 auto;

    background: url('~@/assets/images/congratulations.png') no-repeat;
    background-size: cover;
  }

  .sign-up-title {
    user-select: none;

    height: 40px;
    margin-top: 20px;
    margin-bottom: 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;
  }

  .sign-up-subtitle {
    user-select: none;

    height: 24px;
    margin-bottom: 40px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .sign-up-button {
    width: 480px;
    height: 56px;
    margin: 0 auto;
    padding: 16px 0;

    background: #4d84ff;
    border-radius: 12px;

    span {
      height: 24px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #fff;
    }
  }
}
