.console-wrapper {
  .console-header {
    display: flex;
    flex-wrap: nowrap;
    place-content: center center;

    width: 100%;
    height: 56px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 56px;
    color: #4d84ff;

    background: rgb(248 249 254 / 40%);
    box-shadow: inset 0 -1px 0 0 #edf2f9;
  }

  .console-action-wrapper {
    padding: 24px;

    .console-action-warning {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 10px 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #fa8c16;

      background-color: rgb(232 110 44 / 10%);
      border-radius: 12px;

      .warning-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        background-image: url('~@/assets/icons/ic_warning_16px.png');
        background-repeat: no-repeat;
        background-size: 20px 20px;
      }
    }
  }

  .authorization-name {
    display: flex;
    flex-wrap: nowrap;
    place-content: center space-between;
    align-items: center;

    height: 56px;
    margin-bottom: 0;
    padding-left: 24px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow:
      inset 0 -1px 0 0 #edf2f9,
      inset 0 1px 0 0 #edf2f9;
  }

  .authorization-content {
    display: flex;
    flex-direction: column;
  }

  .authorization-permissions-wrapper {
    padding: 20px 24px;
  }

  .authorization-permissions-title {
    height: 24px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #222;
  }

  .authorization-permissions {
    margin-top: 12px;
    padding: 16px;
    background: #f0f7fe;
    border-radius: 12px;

    &-item {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .name-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        margin-bottom: 10px;

        .name {
          height: 20px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222;
        }
      }

      .safe-roles {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;

        :last-child {
          margin-right: 0;
        }

        .safe-role {
          cursor: pointer;

          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;

          height: 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 8px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          color: #fff;

          background: #8590a6;
          border-radius: 6px;

          .safe-role-mine {
            width: 6px;
            height: 6px;
            margin-left: 8px;

            background: #65c97a;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .authorization-footer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .authorize-btn {
      flex: 1;
    }

    .authorize-btn + .authorize-btn {
      margin-left: 16px;
    }

    .authorize-btn-inner {
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .unAuthorize-button {
      background: #e74e5a;

      &:hover,
      &:focus,
      &:active {
        background: #e74e5a;
      }
    }
  }
}
