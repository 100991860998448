.my-bots-tabs {
  margin-top: 40px;

  :global {
    .ant-tabs-nav {
      margin-bottom: 20px;
    }
  }
}

.tip-icon {
  cursor: pointer;

  display: inline-block;

  width: 16px;
  height: 16px;
  margin-left: 8px;

  vertical-align: middle;

  background: url('~@/assets/images/tips.png') no-repeat;
  background-size: 16px 16px;
}

:global {
  .tabs-box {
    .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs-nav {
      margin-bottom: 20px;
    }

    .ant-tabs-nav-list {
      height: 40px;
      padding: 4px;
      background: #e7ebf2 !important;
      border-radius: 8px !important;
    }

    .ant-tabs-tab {
      display: flex;
      place-content: center center;

      width: 135px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;

      background: #e7ebf2 !important;
      border: none !important;
      border-radius: 6px !important;
    }

    .ant-tabs-tab-active {
      background-color: #fff !important;

      .ant-tabs-tab-btn {
        color: #4d84ff !important;
      }
    }
  }
}
