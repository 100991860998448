.cobo-breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;

  .breadcrumb-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .breadcrumb-item {
    cursor: pointer;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #777e90;

    &.current {
      cursor: pointer;
      font-family: Ubuntu-Medium, Ubuntu;
      font-weight: 500;
      color: #222;
    }
  }

  .breadcrumb-divider {
    transform: rotate(-90deg);

    width: 20px;
    height: 10px;
    margin-right: 4px;
    margin-left: 4px;

    color: #848e9c;
  }
}
