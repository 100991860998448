.collateral-ratio-op-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;
  margin-bottom: 32px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 24px;
  color: #222;

  .name {
    min-width: 130px;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .token-icon {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      margin-left: 8px;
    }

    .ratio-input-wrapper {
      margin-left: 16px;
    }

    .text-input {
      flex: 1;
      flex-shrink: 0;

      width: 340px;
      min-width: 120px;
      max-width: 340px;
      padding: 10px 16px;

      border: 1.5px solid #e7ebf2;
      border-radius: 12px;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      :global {
        .ant-input-suffix {
          font-family: Ubuntu;
          font-size: 20px;
          line-height: 24px;
          color: #222;
        }
      }
    }

    .ratio-input {
      min-width: 120px;
      max-width: 188px;
    }
  }
}
