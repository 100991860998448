.cobo-input-container {
  .input-number {
    overflow: auto;
    box-sizing: border-box;
    border: 1.5px solid #e7ebf2;
    border-radius: 12px;
  }

  .error-input {
    border: 1.5px solid #e74e5a;
  }

  .cobo-input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .error-msg {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #e74e5a;

    .error-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      background: url('~@/assets/icons/ic_error_info.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  :global {
    .ant-input-number-lg input {
      height: 44px;
      font-family: Ubuntu-Medium, Ubuntu !important;
      border: none !important;
    }

    .ant-input-number {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      border: none !important;
      border-right: 0;
      caret-color: #4d84ff;

      transition: none;

      // &:hover {
      //   border-color: #e7ebf2;
      //   border-right-width: 0;
      // }
    }

    .ant-input-number:focus,
    .ant-input-number-focused {
      border-color: #e7ebf2;
      border-right-width: 0;
      outline: 0;
      box-shadow: none;
    }

    .ant-input-number-group-addon {
      padding: 0 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      color: #222;

      background-color: #fff;
      border: none !important;
      border-left: none;
    }
  }
}

.cobo-input-container-disabled {
  :global {
    .ant-input-number-group-addon {
      cursor: not-allowed;
      color: #8590a6;
      background: #f5f5f5;
    }

    .ant-input-number-group-wrapper {
      .ant-input-number-input-wrap {
        .ant-input-number-input {
          cursor: not-allowed;
          color: #8590a6;
          background: #f5f5f5;
        }
      }
    }
  }
}
