.tip-icon {
  cursor: pointer;

  display: block;

  width: 16px;
  height: 16px;
  margin-left: 8px;

  background: url('~@/assets/icons/ic_warning_error.png') no-repeat;
  background-size: cover;
}

.low-gas-popover {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 360px;

  .close-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #222;
  }

  .low-gas-popover-content {
    margin: 0;

    p {
      margin-bottom: 8px;

      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #222;
    }

    .deposit {
      cursor: pointer;

      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #4d84ff;
    }
  }
}

:global {
  .ant-popover-content .ant-popover-inner {
    border-radius: 12px;
    box-shadow: 0 6px 24px 0 rgb(0 0 0 / 8%);

    .ant-popover-inner-content {
      padding: 16px;
      color: rgb(0 0 0 / 85%);
    }
  }
}
