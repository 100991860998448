.wrapper {
  width: 100%;

  .title {
    width: 100%;
    height: 56px;
    padding-left: 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
    color: #222;

    border-bottom: 1px solid #edf2f9;
  }

  .service-wrapper {
    padding: 20px 0 0;

    .service-title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .service-title-icon {
        width: 32px;
        height: 32px;
        background-size: 32px 32px;

        &.farming-icon {
          background-image: url('~@/assets/icons/bots/ic_claim_green.png');
        }

        &.withdraw-icon {
          background-image: url('~@/assets/icons/bots/ic_withdraw_green.png');
        }

        &.deleverage-icon {
          background-image: url('~@/assets/icons/bots/ic_deleverage_green.png');
        }

        &.single-sign-icon {
          background-image: url('~@/assets/icons/bots/ic_single_sig_auth.png');
        }
      }

      .service-title {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
        color: #000;
      }

      .tip-icon {
        cursor: pointer;

        width: 16px;
        height: 16px;
        margin-left: 8px;

        vertical-align: middle;

        background: url('~@/assets/images/tips.png') no-repeat;
        background-size: 16px 16px;
      }
    }

    .service-actions {
      .service-btn {
        cursor: pointer;

        width: 96px;
        height: 32px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 32px;
        color: #4d84ff;
        text-align: center;

        background-color: rgb(77 132 255 / 10%);
        border-radius: 12px;
      }
    }
  }
}
