.value-monitor-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .func-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 100%;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    // color: #262626;
    line-height: 24px;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        color: #8590a6;
      }
    }

    &.active {
      font-weight: 500;
      color: #4d84ff;

      span {
        color: #4d84ff;
      }
    }
  }

  .contract-items {
    display: flex;
    flex-direction: column;

    padding: 24px 24px 8px;

    background-color: #f0f7fe;
    border-radius: 12px;

    &.disabled {
      background-color: #f9f9f9;
    }
  }

  .item {
    margin-bottom: 16px;

    .contract-link {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #4d84ff;
    }
  }

  .function-select {
    max-width: 440px;

    .func-option {
      &.active {
        div {
          color: #262626;

          span {
            color: #262626;
          }
        }
      }
    }
  }

  .indicator-value {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 160px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  .indicator-item {
    display: flex;
    flex-direction: row;

    .indicator-item-value {
      flex: 1;
    }

    .indicator-item-name {
      flex-shrink: 0;
      min-width: 340px;
      max-width: 440px;
    }

    .indicator-item-name,
    .indicator-item-value {
      padding-right: 24px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .param-input-wrapper {
    max-width: unset;
  }

  .param-item {
    display: flex;
    flex-direction: row-reverse;

    .param-input-name {
      margin-right: 16px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 48px;
      color: #222;
    }

    .param-input-value {
      flex: 1;

      max-width: 720px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #262626;
    }
  }

  .param-item + .param-item {
    margin-top: 16px;
  }

  .contract-item:last-child {
    .item {
      margin-bottom: 0;
    }
  }

  .error-message {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-start;
    align-items: center;

    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #e74e5a;
  }

  .error-icon {
    display: block;

    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_warning_error.png') no-repeat;
    background-size: 16px 16px;
  }
}

.indicator-type {
  flex-shrink: 0;

  margin-right: 16px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;

  &.read {
    color: #2b09ab !important;
  }

  &.write {
    color: #016fa0 !important;
  }
}
