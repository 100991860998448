.cobo-button-wrapper {
  .cobo-button {
    height: auto;
    padding: 10px 20px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;

    border-width: 0;
    border-radius: 12px;
  }

  :global {
    .ant-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 100%;

      color: #8590a6;
      text-shadow: none;

      background: #f3f4f6;
      box-shadow: none;
    }

    // .ant-btn:hover,
    // .ant-btn:focus {
    //   color: #8590a6;
    //   background: #f3f3f3;
    // }

    .ant-btn-text:not(:disabled, .ant-btn-disabled):hover {
      color: #8590a6;
      background: #f3f3f3;
    }

    .ant-btn-primary {
      color: #fff;
      background-color: #4d84ff;

      &:hover {
        background-color: #0078f5;
      }

      &:focus {
        background-color: #0078f5;
      }
    }

    .ant-btn-primary[disabled] {
      color: #fff;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      color: #fff;
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: #fff;
      text-shadow: none;

      opacity: 0.5;
      background-color: #4d84ff;
      border-color: transparent;
      box-shadow: none;
    }

    .white-button {
      color: #4d84ff;
      background-color: #fff;
      border: 2px solid #4d84ff;
      border-radius: 12px;

      &:hover {
        color: #4d84ff;
        background-color: #fff;
      }

      &:focus {
        color: #4d84ff;
        background-color: #fff;
      }
    }
  }
}
