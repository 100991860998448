.multi-sign-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .multi-sign-img {
    width: 96px;
    height: 96px;
    margin: 32px auto 0;

    background-image: url('~@/assets/images/png_confirm.png');
    background-repeat: no-repeat;
    background-size: 96px 96px;
  }

  .multi-sign-title {
    margin: 20px 20px 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;
  }

  .multi-sign-subtitle {
    margin-bottom: 32px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .go-safe-btn {
    cursor: pointer;

    font-size: 16px;
    font-weight: bold;
    color: #4d84ff;
    text-decoration: underline;
  }

  .action-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
