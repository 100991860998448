.organization-body {
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  .organization-empty-image {
    width: 160px;
    height: 160px;
    margin: 40px auto;

    background: url('~@/assets/images/empty_organization.png') no-repeat;
    background-size: cover;
  }

  .organization-empty-text {
    margin: 0;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;

    .organization-empty-link {
      cursor: pointer;
      color: #4d84ff;
    }
  }
}
