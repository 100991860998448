.defi-operation {
  .set-action {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .name {
      min-width: 80px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 40px;
      color: #222;
    }

    .action-btn-wrapper {
      display: flex;
      flex-flow: row wrap;

      .action-btn {
        cursor: pointer;

        margin-bottom: 16px;
        margin-left: 16px;
        padding: 8px 40px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #fff;

        background: #4d84ff;
        border-radius: 12px;
      }
    }
  }

  .operations {
    display: flex;
    flex-direction: column;
  }
}
