.option-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  .token-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .token-icon {
      top: 0;
      width: 32px;
      height: 32px;
    }

    .token-name-wrapper {
      overflow: visible;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .token-symbol {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }

      .token-name {
        margin-top: 4px;

        font-family: Ubuntu;
        font-size: 12px;
        line-height: 16px;
        color: #8590a6;
      }
    }
  }

  .balance-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .balance {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #222;
    }

    .selected {
      width: 20px;
      height: 20px;
      margin-left: 16px;

      background: url('~@/assets/images/png_token_selected.png') no-repeat;
      background-size: 20px 20px;
    }

    .un-selected {
      width: 20px;
      height: 20px;
      margin-left: 16px;
    }
  }
}
