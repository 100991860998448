.code-modal {
  position: relative;

  .code-modal-body {
    position: relative;
    display: block;

    .code-modal-code-box {
      display: inline-block;
    }
  }

  :global {
    .ant-modal-title {
      text-align: center;
    }
  }
}
