.notification-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .notification-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;

    background: url('~@/assets/icons/ic_email.png') no-repeat;
    background-size: cover;
  }

  .notification-content-text {
    flex: 1;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

.notification-emails {
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  padding: 16px 24px;

  background: #fff;
  border-radius: 12px;

  .email-role {
    width: 68px;
    height: 20px;
    margin-left: 40px;
    padding: 0 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;

    border-radius: 6px;
  }

  .email-role-admin {
    color: #a36b2b;
    background: #ecc99d;
  }

  .email-role-operator {
    color: #26c165;
    background: #e8fff1;
  }

  .notification-text {
    height: 20px;
    margin-bottom: 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .notification-email {
    display: flex;
    justify-content: space-between;

    min-width: 320px;
    height: 20px;
    margin-bottom: 16px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #222;

    .email {
      overflow: hidden;
      min-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .view-more {
    cursor: pointer;

    width: 72px;
    height: 20px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #4d84ff;
  }
}
