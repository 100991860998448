.safe-role-disabled {
  margin-top: 16px;
}

.safe-role {
  display: flex;
  place-content: center space-between;
  align-items: center;

  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;

  .safe-role-text {
    height: 20px;
    margin-bottom: 0;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  .safe-role-buttons {
    display: flex;
    flex-wrap: nowrap;
  }

  .safe-role-button {
    height: 40px;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .safe-role-button-to-safe {
    height: 40px;
  }
}

.safe-roles {
  display: flex;
  align-items: center;
  margin-bottom: 26px;

  .safe-roles-text {
    height: 20px;
    margin-right: 12px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

.safe-role-item {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 16px;
  padding: 6px 0 6px 12px;

  font-family: Ubuntu-Bold, Ubuntu;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;

  background: #8590a6;
  border-radius: 8px;

  .safe-role-item-name {
    margin-right: 8px;
  }

  .delete-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;

    .line {
      width: 2px;
      height: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

      opacity: 0.3;
      background: #fff;
      border-radius: 1px;
    }

    .delete-view {
      cursor: pointer;
      flex: 1;
      padding-right: 8px;

      .delete-icon {
        width: 8px;
        height: 8px;
        margin-left: 8px;
      }
    }
  }
}
