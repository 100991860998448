.support-container {
  display: block;
  padding: 24px 0;

  .support-item-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    padding: 0 40px;
  }

  .support-icon {
    cursor: pointer;

    display: block;

    width: 24px;
    height: 24px;
    margin-right: 30px;
    margin-bottom: 16px;

    color: #b0b9c6;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(1) {
      margin-left: 4px;
    }

    &:nth-child(3) {
      margin-right: 4px;
    }
  }

  .support-icon-bg {
    width: 32px;
    height: 32px;
    margin-bottom: 0;
  }
}

.support-popover {
  width: 328px;

  .support-popover-title {
    display: flex;
    place-content: center space-between;
    align-items: center;

    height: 20px;
    margin-bottom: 8px;

    h2 {
      height: 20px;
      margin-bottom: 0;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: #222;
    }

    .support-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: #222;
    }
  }

  .support-popover-content {
    p {
      height: 20px;
      margin: 0;

      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #8590a6;

      .color-blue {
        cursor: pointer;
        color: #4d84ff;
      }
    }
  }
}

:global {
  #support-container .ant-popover {
    .ant-popover-content {
      .ant-popover-inner-content {
        padding: 16px;
      }
    }
  }
}
