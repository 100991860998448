.code-modal {
  position: relative;

  .code-modal-text {
    height: 24px;
    margin-bottom: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }

  .code-modal-body {
    position: relative;
    display: block;

    .code-modal-code-box {
      display: inline-block;
    }
  }

  .parse-button {
    position: absolute;
    top: 20px;
    right: -10px;

    span {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #4d84ff;
    }
  }

  .code-modal-tooltip {
    cursor: pointer;

    display: block;

    height: 20px;
    margin-top: 24px;
    padding-bottom: 48px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
  }

  :global {
    .ant-btn-text:active,
    .ant-btn-text:hover {
      color: #4d84ff;
      background: transparent;
      border-color: transparent;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

:global {
  .ant-tooltip-inner {
    background: #0a0a0a;
    border-radius: 5px;
    box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%);
  }

  #code-modal .ant-tooltip-inner {
    width: 400px;
  }
}
