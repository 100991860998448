.cobo-input-box {
  display: inline-block;
  width: 100%;

  :global {
    .ant-input-affix-wrapper {
      height: 48px;
      padding: 12px 16px;
      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
    }

    .ant-input {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      caret-color: #4d84ff;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      z-index: 1;
      border-color: #4d84ff;
      box-shadow: none;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #40a9ff;
      outline: 0;
      box-shadow: none;
    }

    .ant-input-suffix .ant-input-clear-icon,
    .ant-input-password-icon {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
