.notification-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .notification-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;

    background: url('~@/assets/icons/ic_email.png') no-repeat;
    background-size: cover;
  }

  .notification-content-text {
    flex: 1;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

.notification-email {
  width: 580px;
  margin-left: 16px;
  padding: 16px 12px;

  background: #f3f8ff;
  border-radius: 12px;

  .email {
    margin-bottom: 2px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #222;
  }

  .address {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    color: #8590a6;
  }
}

.notification-emails {
  display: flex;
  flex-direction: column;

  .notification-text {
    height: 20px;
    margin-bottom: 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .group {
    overflow: hidden scroll;
    max-height: 338px;
  }

  .select-text {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #222;
  }

  :global {
    .ant-checkbox + span {
      padding-right: 0;
      padding-left: 0;
    }

    .ant-checkbox-group-item {
      display: flex;
      align-content: center;
      align-items: center;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-img {
    width: 96px;
    height: 96px;
    margin-bottom: 20px;

    background: url('~@/assets/icons/png_sent.png') no-repeat;
    background-size: cover;
  }

  .success-text {
    margin: 0;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    color: #222;
    text-align: center;
  }
}
