.container {
  display: flex;
  align-items: center;

  p {
    margin-right: 12px;
    margin-bottom: 0;

    font-size: 14px;
    color: #222;
    text-align: right;
  }

  //button {
  //  color: #8590A6;
  //  margin-right: 12px;
  //  text-align: center;
  //  font-family: Ubuntu-Medium, Ubuntu;
  //  font-size: 12px;
  //  background: transparent;
  //  border-radius: 8px;
  //  border:2px solid #E7EBF2;
  //  &:not(:first-of-type){
  //    color: #8590a6 !important;
  //  }
  //  &:focus{
  //    border-color: #4d84ff !important;
  //    color: #4d84ff !important;
  //  }
  //  &:hover{
  //    font-weight: 500;
  //    border: 2px solid #4d84ff !important;
  //    border-color: #4d84ff !important;
  //    color: #4d84ff !important;
  //  }
  //}
  //
  //.active {
  //  border: 2px solid #4d84ff;
  //  span{
  //    color: #4d84ff;
  //    font-weight: 500 !important;
  //  }
  //}
}
