.collapse-wrapper {
  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000;

  :global {
    .ant-collapse {
      background: #fdf7ed;
      border: none;
      border-radius: 12px;

      .ant-collapse-item {
        border: none;

        .ant-collapse-content {
          background: #fdf7ed;
          border: none;
          border-radius: 12px;
        }
      }

      .ant-collapse-header {
        padding: 12px 16px;
      }

      .ant-collapse-extra {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #4d84ff;
      }

      .ant-collapse-content-box {
        padding: 0 16px 12px;
      }
    }
  }
}
