.bot-item {
  cursor: pointer;

  overflow: hidden;

  box-sizing: border-box;
  width: 200px;
  margin: 20px 0 0 20px;

  border: 1px solid #e7ebf2;
  border-radius: 12px;

  transition: 0.2s;

  &:hover {
    border: 1px solid #4d84ff;
  }

  .bot-item-button {
    transition: 0.2s;
  }

  &-header {
    height: 116px;
    padding: 16px;
    background: #f3f4f6;

    &-top {
      display: flex;
      flex-wrap: nowrap;
      place-content: center space-between;
      align-items: center;
    }

    .pool-id {
      overflow: hidden;

      max-width: 80px;
      height: 16px;
      padding: 0 4px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;

      background: #8590a6;
      border-radius: 3px;
    }

    &-icon {
      cursor: pointer;

      width: 14px;
      height: 14px;

      background: url('~@/assets/icons/ic_view.png') no-repeat;
      background-size: 14px 14px;
    }

    &-tokens {
      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;
    }

    &-token {
      width: 24px;
      height: 24px;
      margin-left: -6px;
      border-radius: 12px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    .bot-name-box {
      display: flex;
      place-content: center flex-start;
      align-items: center;

      .bot-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .bot-type {
        height: 17px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #8590a6;
      }
    }

    .bot-header-name {
      overflow: hidden;

      width: 164px;
      height: 20px;
      margin: 12px 0;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-safe {
    overflow: hidden;
    display: flex;
    place-content: center space-between;
    align-items: center;

    margin-bottom: 12px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
    text-overflow: ellipsis;
    white-space: nowrap;

    .name-logo {
      margin-right: 8px;
    }

    .bot-item-safe-info-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-info {
      display: flex;
      flex-direction: row;

      .bot-item-safe-name {
        overflow: hidden;
        display: inline-block;

        max-width: 110px;

        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .bot-item-safe-info {
        flex: 1;
      }
    }
  }

  &-content {
    padding: 16px;
  }

  &-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  &-customize-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 8px;

    background: url('~@/assets/images/png_robot.png') no-repeat;
    background-size: 20px 20px;
  }

  &-title {
    display: flex;
    flex-direction: row;
    place-content: center space-between;
    align-items: center;

    margin-bottom: 12px;

    &-inner {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      place-content: center flex-start;
      align-items: center;
    }
  }

  &-run {
    width: 24px;
    min-width: 24px;
    height: 24px;

    background: url('~@/assets/icons/ic_bot_run.png') no-repeat;
    background-size: 24px 24px;
  }

  &-name {
    overflow: hidden;

    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bot-data-item {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    &:first-of-type {
      margin-top: 12px;
    }

    .last-triggered {
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
      white-space: nowrap;
    }

    .bot-item-data-label {
      height: 16px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: #8590a6;
      white-space: nowrap;
    }

    .bot-data-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .bot-data-content {
      height: 16px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: #222;
    }

    .success-content {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #222;
    }

    .fail-content {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #e74e5a;
    }

    .updated-time {
      width: 64px;
      height: 32px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #222;
      text-align: right;
      white-space: wrap;

      &.failed {
        color: #ff4d4f;
      }
    }
  }

  .leverage-info {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #e7ebf2;
  }

  .bot-item-buttons {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-radius: 0 0 12px 12px;
    box-shadow: inset 0 1px 0 0 #ecf1f8;

    .bot-item-button {
      width: calc(100% / 2 - 0.5px);
      height: 44px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 44px;
      color: #4d84ff;
      text-align: center;
    }

    .bot-item-button-split {
      width: 1px;
      height: 16px;
      background: #e7ebf2;
    }
  }
}
