.pending-tx-btn {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: content-box;
  height: 40px;
  padding-right: 20px;
  padding-left: 16px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  color: #ffab2b;

  background: rgb(255 191 46 / 20%);
  border-radius: 12px;
}

.tx-content {
  display: flex;
  flex-direction: column;

  .content {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: #222;

    .gnosis-safe {
      cursor: pointer;
      font-family: Ubuntu-Medium;
      color: #4d84ff;
      text-decoration: underline #4d84ff;
    }
  }

  .content + .content {
    margin-top: 12px;
  }
}
