.content {
  display: flex;
  flex-direction: column;

  padding: 16px 24px 24px;

  background: #f0f7fe;
  border-radius: 12px;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #000;
    }

    .subtitle {
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }
  }

  .item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }
    }

    .item-content {
      flex: 1;
      max-width: 720px;
    }
  }
}
