.trigger-container {
  .select-content {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .select-content span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 24px;
  }

  .trigger-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.has-error {
      align-items: flex-start;

      .trigger-content {
        align-items: flex-start;
      }
    }

    .name {
      min-width: 130px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    .trigger-name {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;

      .tip-icon {
        cursor: pointer;

        width: 16px;
        height: 16px;
        margin-left: 6px;

        vertical-align: middle;

        background: url('~@/assets/icons/ic_info.png') no-repeat;
        background-size: 16px 16px;
      }
    }

    .common-trigger-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .trigger-item {
        margin-left: 16px;

        .trigger-select {
          min-width: 150px;
        }

        .trigger-input {
          width: 290px;
        }
      }
    }

    .trigger-content {
      display: flex;
      flex: 1;
      flex-direction: row-reverse;
      align-items: center;

      .reward-amount-selector {
        flex: 1;
        min-width: 120px;
        max-width: 340px;
      }

      .over {
        margin-right: 14px;
        margin-left: 14px;

        font-family: Ubuntu;
        font-size: 20px;
        line-height: 24px;
        color: #222;
      }

      .reward-amount-input {
        flex: 1;

        min-width: 120px;
        max-width: 340px;
        padding: 10px 16px;

        border: 1.5px solid #e7ebf2;
        border-radius: 12px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .time-range-selector {
        flex-shrink: 0;

        width: 340px;
        min-width: 120px;
        max-width: 340px;
        margin-left: 40px;
      }

      .ratio-token-selector {
        flex: 1;
        min-width: 120px;
        max-width: 207px;
      }

      .ratio-op-selector {
        flex: 1;
        min-width: 120px;
        max-width: 160px;
        margin-left: 16px;
      }

      .ratio-input-wrapper {
        margin-left: 16px;
      }

      .text-input {
        flex: 1;
        flex-shrink: 0;

        width: 340px;
        min-width: 120px;
        max-width: 340px;
        padding: 10px 16px;

        border: 1.5px solid #e7ebf2;
        border-radius: 12px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        :global {
          .ant-input-suffix {
            font-family: Ubuntu;
            font-size: 20px;
            line-height: 24px;
            color: #222;
          }
        }
      }

      .ratio-input {
        min-width: 120px;
        max-width: 188px;
      }

      .ratio-text {
        margin-left: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }

      .time-every {
        flex-shrink: 0;

        margin-right: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 48px;
        color: #222;
      }

      .gas-price-over {
        flex-shrink: 0;

        margin-right: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 48px;
        color: #222;
      }

      .trigger-gas-price-placeholder {
        flex-shrink: 0;

        width: 364px;
        min-width: 364px;
        max-width: 364px;
        margin-left: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 48px;
        color: #222;
      }
    }

    .current-gas-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-top: 12px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;

      .current-gas-icon {
        width: 14px;
        height: 14px;
        margin-right: 3px;

        background: url('@/assets/icons/ic_gas.png') no-repeat;
        background-size: cover;
      }
    }
  }

  .trigger-item-wrapper + .trigger-item-wrapper {
    margin-top: 24px;
  }

  .token-ratio-hint {
    display: flex;
    flex-direction: row-reverse;

    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}
