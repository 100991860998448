.bot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 24px 32px 48px;

  .bot-card {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding-top: 24px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;

    .bot-title {
      margin-bottom: 40px;
      margin-left: 32px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #222;
    }
  }

  .bot-content {
    flex: 1;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 32px;

    border-top: 1px solid #edf2f9;

    .action-buttons {
      display: flex;
      flex-direction: row;

      .action-btn + .action-btn {
        margin-left: 16px;
      }
    }
  }
}
