.tokens-select-option-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .token-item-wrapper {
    display: flex;
    flex-direction: row;

    .token-icon {
      width: 24px;
      height: 24px;
    }

    .token-label {
      margin-left: 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #262626;
    }

    .token-plus {
      margin-right: 4px;
      margin-left: 4px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #181c27;
    }
  }
}
