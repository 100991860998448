.md-title {
  margin-bottom: 8px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
}

.md-subtitle {
  margin-bottom: 0;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.md-content {
  margin-bottom: 0;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #8590a6;

  + .md-content {
    margin-top: 12px;
  }

  + .md-title {
    margin-top: 39px;
  }

  + .md-subtitle {
    margin-top: 24px;
  }
}

.md-ol {
  padding-left: 15px;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #8590a6;
}

.md-icon {
  cursor: pointer;
  height: 24px;

  + .md-title {
    margin-top: 32px;
  }
}

.md-icon + .md-icon {
  margin-left: 8px;
}

.md-img {
  display: block;
  min-width: 504px;
  height: 84px;
  margin-top: 24px;
}

.chain-icon {
  width: 24px;
  height: 24px;
  margin-left: -6px;
  border-radius: 12px;

  :first-of-type {
    margin-left: 0;
  }
}

.select-icon {
  display: inline-block;

  width: 24px;
  height: 24px;
  margin-right: 12px;

  vertical-align: middle;
}
