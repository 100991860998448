.rewards-manage {
  padding: 16px 24px;
  background: #f0f7fe;
  border-radius: 12px;

  :global {
    .ant-select-selection-placeholder {
      color: #8590a6 !important;
    }

    .ant-radio + span {
      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
    }

    .ant-radio-wrapper-checked {
      .ant-radio-checked + span {
        color: #222;
      }
    }
  }
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    margin-bottom: 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #000;
  }

  .subtitle {
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }
}

.claim-and-vest-tip {
  margin-top: 24px;
  margin-bottom: 24px;
}

.operation-item {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0;
  }

  &.operation-radio {
    margin-top: 16px;
  }

  .operation-item-label {
    height: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }

  .reward-token {
    display: flex;
    place-content: center flex-start;
    align-items: center;
    margin-left: 16px;

    &-icon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    &-label {
      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }
  }
}

.operation-item-radio {
  justify-content: flex-start;
}

.add-address-wrapper {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 20px;

  line-height: 20px;

  .add-address {
    cursor: pointer;
    font-weight: bold;
    color: #4d84ff;
  }
}

.advance-settings {
  width: 100%;

  &-item {
    display: flex;
    place-content: center space-between;
    align-items: center;

    width: 100%;
    margin-bottom: 24px;

    .operation-item-content {
      flex: 1;
      max-width: 720px;
    }

    .reward-token-label {
      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;

      min-width: 120px;
    }
  }
}

.advance-settings + .advance-settings {
  margin-top: 24px;
}

.expert-settings {
  width: 100%;
  margin-top: 16px;

  .expert-settings-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }

  .slippage-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .tooltip-wrapper {
    display: flex;
    flex-direction: column;

    .tooltip-content {
      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }

    .safe-address {
      margin-top: 4px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 20px;
      color: #8590a6;
    }
  }

  .recommend-slippage + .recommend-slippage {
    margin-left: 8px;
  }

  .slippage-input {
    width: 112px;
    height: 32px;
    margin-left: 8px;
    padding: 4px 8px 4px 12px !important;

    &.slippage-input-focus {
      border-color: #4d84ff !important;
    }

    :global {
      .ant-input {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 12px !important;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .recommend-slippage {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 52px;
    height: 32px;
    padding: 4px 8px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    color: #8590a6;

    border: 1.5px solid #e7ebf2;
    border-radius: 12px;

    &.selected {
      color: #4d84ff;
      border: 1.5px solid #4d84ff;
    }
  }
}
