.slippage-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.tooltip-wrapper {
  display: flex;
  flex-direction: column;

  .tooltip-content {
    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }

  .safe-address {
    margin-top: 4px;

    font-family: Ubuntu;
    font-size: 12px;
    line-height: 20px;
    color: #8590a6;
  }
}

.recommend-slippage + .recommend-slippage {
  margin-left: 8px;
}

.slippage-input {
  width: 112px;
  height: 32px;
  margin-left: 8px;
  padding: 4px 8px 4px 12px !important;

  &.slippage-input-focus {
    border-color: #4d84ff !important;
  }

  :global {
    .ant-input {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.recommend-slippage {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 52px;
  height: 32px;
  padding: 4px 8px;

  font-family: Ubuntu-Bold, Ubuntu;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  color: #8590a6;

  border: 2px solid #e7ebf2;
  border-radius: 12px;

  &.selected {
    color: #4d84ff;
    border: 2px solid #4d84ff;
  }
}
