.portfolios {
  display: flex;
  flex-wrap: nowrap;
  place-content: center flex-start;
  align-items: center;
}

.portfolio-board {
  width: 100%;
  margin-right: 24px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

  &:last-of-type {
    margin-right: 0;
  }

  .portfolio-title-box {
    display: flex;
    flex-wrap: nowrap;
    place-content: center space-between;
    align-items: center;

    height: 56px;
    padding: 0 16px 0 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 56px;
    color: #222;

    border-radius: 12px 12px 0 0;
    box-shadow: inset 0 -1px 0 0 #edf2f9;
  }

  .portfolio-content {
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    padding: 16px 32px;
  }

  .portfolio-item {
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
    align-items: flex-start;

    height: 56px;
    margin-right: 62px;

    .portfolio-title {
      flex: 1;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 24px;
      line-height: 28px;
      color: #222;
    }

    .color-green {
      color: #65c97a;
    }

    .portfolio-sub-title {
      font: 400 14px Ubuntu;
      line-height: 20px;
      color: #8590a6;
    }
  }

  .view-button {
    height: 32px;
  }

  .button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-end;
    align-items: center;
  }

  .next-icon {
    width: 12px;
    height: 12px;
    background: url('~@/assets/icons/ic_next.png') no-repeat;
    background-size: 12px 12px;
  }
}

.auth-portfolio-board {
  flex-shrink: 0;
  min-width: 480px;
  max-width: 532px;

  .portfolio-item {
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.all-portfolio-board {
  width: 736px;

  .portfolio-content {
    justify-content: space-between;
  }
}
