.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;

  .card-inner {
    flex: 1;

    width: 100%;
    padding: 24px;

    background-color: #fff;
    border-radius: 12px;

    &.disabled {
      background-color: #f9f9f9;
    }

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      .card-name {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #000;
      }

      .tip-icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }

    .card-content {
      margin-top: 16px;
    }
  }

  .delete-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
  }

  .tip-icon {
    cursor: pointer;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }
}
