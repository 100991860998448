.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;

  .header-logo {
    cursor: pointer;
    display: block;
    margin: 20px 40px;

    .header-logo-icon {
      width: 164px;
      height: 28px;
      background: url('~@/assets/icons/logo_cobo.png') no-repeat;
      background-size: 164px 28px;
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;

    .header-button-box {
      height: 40px;
      margin: 16px 24px;

      :global {
        .ant-btn {
          height: 40px;
          padding: 10px 24px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #fff;

          background: #4d84ff;
          border-radius: 12px;
        }
      }
    }

    .header-language-selector {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 32px;

      .arrow-animation {
        margin-right: 0;
      }

      :global {
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding-right: 0;
        }

        .ant-select-selector {
          padding-right: 0;
          padding-left: 8px;

          .ant-select-selection-item {
            line-height: 44px;
          }
        }

        .ant-select-arrow {
          position: absolute;
          right: 0;
          font-weight: bold;
          color: #222;
        }

        .ant-select {
          width: 60px;
          vertical-align: middle;
        }
      }
    }
  }

  .language-icon {
    position: relative;
    top: 25px;
    width: 20px;
    height: 20px;
  }
}
