.round {
  :global {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        font-size: 20px;
        line-height: 20px;

        background: url('~@/assets/icons/checkbox_off.png') no-repeat;
        background-size: 20px 20px;
        border: none;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: url('~@/assets/icons/checkbox_on.png') no-repeat;
        background-size: 20px 20px;
        border: none;
      }

      .ant-checkbox-inner::after {
        display: none;
      }
    }
  }
}

.reg {
  :global {
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        font-size: 20px;
        line-height: 20px;

        background: url('~@/assets/icons/checkbox_off_reg.png') no-repeat;
        background-size: 20px 20px;
        border: none;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: url('~@/assets/icons/checkbox_on_reg.png') no-repeat;
        background-size: 20px 20px;
        border: none;
      }

      .ant-checkbox-inner::after {
        display: none;
      }
    }
  }
}
