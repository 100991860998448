.strategy-list {
  position: relative;
  min-width: 1200px;
  margin-top: 24px;

  thead {
    tr {
      height: 52px;
    }
  }

  tbody {
    tr {
      height: 86px;
    }
  }

  .tip-icon {
    cursor: pointer;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/icons/role_tip.png') no-repeat;
    background-size: 16px 16px;
  }

  .chain-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .table-title {
    vertical-align: middle;
  }

  .iconGroup-box {
    margin-right: 16px;
  }

  .strategy-name {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    .strategy-icons-box,
    .protocol-box {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 42px;
      height: 42px;
      margin-right: 16px;
    }

    .strategy-icons {
      width: 24px;
      height: 24px;
    }

    .strategy-icon-box {
      position: absolute;
      top: -17px;
      left: -24px;
    }

    .strategy-name-tags {
      display: flex;
      flex-wrap: nowrap;
      place-content: center left;
      align-items: center;
    }

    .strategy-name-tag {
      height: 20px;
      margin-right: 8px;
      padding: 0 4px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 20px;
      color: #8590a6;

      background: rgb(133 144 166 / 7%);
      border-radius: 4px;
    }

    .strategy-name-title {
      display: flex;
      place-content: center left;
      align-items: center;
      margin-bottom: 0;

      .strategy-content-box {
        display: flex;
      }

      h2 {
        margin-bottom: 0;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: #222;
      }
    }

    .strategy-name-box {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    .strategy-name-new {
      flex-shrink: 0;

      width: 38px;
      height: 16px;
      margin-left: 8px;

      background: url('~@/assets/icons/strategy/tag_new.png') no-repeat;
      background-size: 38px 16px;
    }

    .strategy-name-content {
      margin: 0 8px 0 0;
      padding: 0 8px;

      font-size: 12px;
      line-height: 20px;
      color: #8590a6;

      background: rgb(133 144 166 / 10%);
      border-radius: 4px;
    }
  }

  .apy-new-icon {
    display: block;

    width: 29px;
    height: 11px;
    margin-right: 8px;

    background: url('~@/assets/icons/strategy/new.png') no-repeat;
    background-size: 29px 11px;
  }

  .strategy-token-box {
    display: flex;
    flex-wrap: nowrap;
    place-content: center left;
    align-items: center;
  }

  .strategy-token-item {
    cursor: pointer;

    display: block;

    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .strategy-token-more {
    display: block;

    min-width: 24px;
    height: 24px;
    padding: 0 12px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    text-align: center;
    white-space: nowrap;

    background: #b0b9c6;
    border-radius: 12px;
  }

  .strategy-service {
    cursor: pointer;

    .strategy-service-content {
      display: flex;

      img {
        width: 32px;
        height: 32px;

        &:not(:first-of-type) {
          margin-left: 12px;
        }
      }
    }
  }

  .view-button {
    width: 112px;
    height: 32px;
  }

  .button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-end;
    align-items: center;
  }

  .next-icon {
    width: 12px;
    height: 12px;
    background: url('~@/assets/icons/ic_next.png') no-repeat;
    background-size: 12px 12px;
  }
}
