.strategy-detail-container {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  .strategy-detail-header {
    display: flex;
    flex-direction: column;
  }

  .strategy-detail-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    margin-top: 24px;

    .strategy-detail-item {
      overflow: hidden;

      width: 100%;

      background: #fff;
      border: 1px solid #edf2f9;
      border-radius: 12px;
      box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);
    }

    .strategy-detail-left {
      flex: 1;
      margin-right: 32px;
    }

    .strategy-detail-right {
      width: 360px;
    }

    .strategy-detail-description {
      margin-top: 32px;
    }
  }
}
