.add-modal {
  .edit-modal-input {
    height: 48px;
    border: 2px solid #e7ebf2;
    border-radius: 10px;

    -webkit-text-fill-color: #222;
  }

  .edit-modal-button {
    width: 360px;
    height: 48px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #fff;

    background-color: #4d84ff;
    border-radius: 8px;
  }

  .optional {
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8590a6;
  }

  .tip-box {
    margin-top: 16px;
  }

  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
      margin-right: 0;
    }
  }
}
