.login-body {
  position: relative;
  width: 480px;
  margin: 0 auto;
}

.login-form {
  width: 100%;

  .form-forgot-password-box {
    cursor: pointer;

    position: relative;
    top: -40px;

    float: right;
    display: block;

    height: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4d84ff;
  }

  .form-login-box {
    position: relative;
    margin-bottom: 24px;
  }

  .form-password-item-box {
    position: relative;
    margin-bottom: 48px !important;
  }

  .login-button {
    width: 480px;
    height: 56px;
    padding: 16px 0;

    background: #4d84ff;
    border-radius: 12px;

    span {
      height: 24px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #fff;
    }
  }

  .sign-up-text {
    height: 24px;
    margin-top: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;

    .sign-up-button {
      padding: 0 0 0 10px;
      color: #4d84ff;

      span {
        font-weight: bold;
      }
    }

    :global {
      .ant-btn-text:active,
      .ant-btn-text:hover {
        color: #4d84ff;
        background: transparent;
        border-color: transparent;
      }
    }
  }

  :global {
    .ant-form-item-label > label {
      position: relative;

      display: block;
      align-items: center;

      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .ant-form-item {
      margin-bottom: 32px;
      transition: none 0.3s 0.017s linear;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      content: '';
      display: none;
    }

    .ant-form-item-label > label::after {
      content: '';
    }

    .ant-input-affix-wrapper {
      height: 48px;
      padding: 12px 16px;
      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
    }

    .ant-btn-primary[disabled] {
      opacity: 0.3;
      background: #4d84ff;
    }

    .ant-btn-primary[disabled]:hover {
      background: #4d84ff;
    }

    .ant-btn-primary:hover {
      background: #4d84ff;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      transition: none 0.3s 0.017s linear;
    }

    .ant-form-item-with-help .ant-form-item-explain {
      position: absolute;
      transition: none 0.3s 0.017s linear;
    }
  }
}
