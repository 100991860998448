.form-name-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  min-width: 160px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 24px;
  color: #222;

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/icons/ic_info.png') no-repeat;
    background-size: 16px 16px;
  }
}
