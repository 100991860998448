.auth-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background: url('~@/assets/images/png_bg.png') no-repeat;
  background-size: cover;

  .auth-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 480px;
    margin: 0 auto;
    padding-top: 64px;

    .auth-title {
      width: 480px;
      height: 40px;
      margin-bottom: 32px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #222;
      text-align: center;
    }

    .join-text {
      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;

      .org-name {
        font-weight: bold;
        color: #222;
      }
    }

    .address-box {
      height: 64px;
      margin-top: 24px;
      margin-bottom: 32px;
      padding: 0 24px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 64px;
      color: #222;
      text-align: center;

      background: rgb(231 235 242 / 30%);
      border: 2px solid #e7ebf2;
      border-radius: 16px;
    }

    .auth-btn {
      width: 360px;
      height: 48px;
      background: #4d84ff;
      border-radius: 12px;

      :global {
        .children-wrapper {
          line-height: 28px;
        }
      }
    }
  }
}

.auth-footer {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
}
