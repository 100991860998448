.tooltip-content {
  display: flex;
  flex-flow: column warp;
  justify-content: center;
}

.collateral-ratio-slider-container {
  position: relative;
  display: block;
  width: 530px;
  padding-left: 50px;

  .collateral-ratio-slider {
    position: relative;
  }

  .hideLabel {
    :global {
      .ant-slider-handle {
        display: none;
      }
    }
  }

  .max-label {
    display: flex;
    flex-flow: column warp;
    justify-content: center;

    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 16px;
    color: #222;
    text-align: center;
  }

  .min-label {
    display: flex;
    flex-flow: column warp;
    justify-content: center;

    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 16px;
    color: #eb5952;
    text-align: center;
  }

  :global {
    .ant-slider-with-marks {
      margin-bottom: 42px;
    }

    .ant-tooltip:has(.hide-tooltip) {
      display: none;
    }

    .ant-slider-disabled .ant-slider-rail {
      background: rgb(133 144 166 / 20%) !important;
    }

    .ant-slider {
      .ant-slider-step {
        .ant-slider-dot {
          top: 0;

          width: 3px;
          height: 6px;

          background: #222;
          border: none;
          border-radius: 1px;

          &:first-of-type {
            background: #eb5952;
          }
        }
      }

      .ant-slider-rail {
        height: 6px;
        background: rgb(133 144 166 / 20%);
        border-radius: 1px;
      }

      .ant-slider-handle {
        width: 15px;
        height: 20px;
        margin-top: -6px;

        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMyIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDIzIDI4IiBmaWxsPSJub25lIj4KICA8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9kXzU5OF80MTkwOSkiPgogICAgPHJlY3QgeD0iNSIgeT0iMyIgd2lkdGg9IjEzIiBoZWlnaHQ9IjE4IiByeD0iMyIgZmlsbD0iIzREODRGRiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgICA8cmVjdCB4PSIxMCIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iNiIgcng9IjEiIGZpbGw9IndoaXRlIi8+CiAgPC9nPgogIDxkZWZzPgogICAgPGZpbHRlciBpZD0iZmlsdGVyMF9kXzU5OF80MTkwOSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIzIiBoZWlnaHQ9IjI4IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CiAgICAgIDxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CiAgICAgIDxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgogICAgICA8ZmVPZmZzZXQgZHk9IjIiLz4KICAgICAgPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMiIvPgogICAgICA8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KICAgICAgPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMDYgMCIvPgogICAgICA8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd181OThfNDE5MDkiLz4KICAgICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfNTk4XzQxOTA5IiByZXN1bHQ9InNoYXBlIi8+CiAgICA8L2ZpbHRlcj4KICA8L2RlZnM+Cjwvc3ZnPg==')
          no-repeat;
        background-size: cover;
        border: none;
        border-radius: 0;
        box-shadow: none;

        transition: none;
        // transform: translateX(-50%);
      }
    }
  }
}

:global {
  .ant-slider-tooltip {
    z-index: 200;

    .ant-tooltip-inner {
      padding: 6px 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #fff;
      text-align: center;

      background: #4d84ff;
      border-radius: 8px;
      box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%);
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: linear-gradient(
        to right bottom,
        rgb(77 132 255 / 65%),
        rgb(77 132 255 / 75%)
      );

      background: #4d84ff;
    }
  }
}
