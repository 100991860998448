.acl-setting-container {
  position: relative;

  display: flex;
  flex-direction: column;

  margin-top: 16px;
  margin-bottom: 32px;
  padding: 24px;

  background: #f0f7fe;
  border-radius: 12px;

  .acl-setting-hint {
    margin-top: -16px;
    margin-bottom: 24px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  .trades {
    margin-bottom: 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #000;
  }

  .token-item-wrapper + .token-item-wrapper {
    margin-top: 32px;
  }

  .token-item-wrapper {
    position: relative;

    .action-wrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16px;

      .action {
        cursor: pointer;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #4d84ff;
      }

      .action + .action {
        margin-right: 32px;
      }
    }
  }

  .token-selector-box {
    width: 612px;
    max-width: 612px;
  }
}
