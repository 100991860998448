.action-button {
  cursor: pointer;
  font-family: Ubuntu-Medium;
  color: #4d84ff;
}

.edit-modal {
  top: 25%;

  .delete-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .edit-modal-input {
    height: 48px;
    border: 2px solid #e7ebf2;
    border-radius: 10px;

    -webkit-text-fill-color: #222;
  }

  .add-address-button[disabled] {
    opacity: 1;
    background: #f5f5f5 !important;
  }

  .edit-modal-select {
    width: 100%;

    :global {
      .ant-modal-body {
        padding: 32px 32px 0;
      }
    }
  }

  .edit-modal-button {
    width: 360px;
    height: 48px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #fff;

    background-color: #4d84ff;
    border-radius: 8px;
  }
}

.remove-modal {
  top: 25%;

  .tips {
    width: 100%;
    min-height: 48px;
    margin-top: 24px;
    padding: 0 20px;
    padding-top: 16px;
    padding-bottom: 18px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #222;

    background-color: rgba(rgb(232 110 44 / 10%));
    border-radius: 12px;
  }

  .remove-modal-input {
    height: 48px;
    border: 2px solid #e7ebf2;
    border-radius: 10px;
  }

  .remove-modal-okbutton {
    width: 292px;
    height: 48px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #fff;

    background-color: #4d84ff;
    border: none;
    border-radius: 8px;
  }

  .remove-modal-cancelbutton {
    width: 292px;
    height: 48px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #8590a6;

    background-color: #f3f4f6;
    border: none;
    border-radius: 8px;

    &:hover {
      color: #8590a6;
      background-color: #f3f4f6;
    }
  }
}

.address-more {
  cursor: pointer;

  display: inline-block;

  height: 20px;
  margin-left: 5px;
  padding: 0 3px;

  font-family: Ubuntu-Medium;
  font-size: 12px;
  color: #fff;
  text-align: center;

  background: #4d84ff;
  border-radius: 10px;
}

.unlink-notice-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .unlink-authorize-label {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 20px;
    padding-right: 8px;
    padding-left: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #a36b2b;

    background: #ecc99d;
    border-radius: 12px;
  }
}

.tab-content-address {
  padding-bottom: 40px;
  text-align: center;
  border-radius: 20px;

  .image {
    width: 160px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .address-image {
    width: 80px;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .text {
    margin-bottom: 5px;
    font-size: 16px;
    color: #8590a6;
  }

  .link {
    cursor: pointer;
    font-size: 16px;
    color: #4d84ff;
  }
}

.header {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;

  .invite-icon {
    width: 12px;
    height: 12px;
  }

  .invite-button {
    width: 109px;
    height: 40px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #fff;

    background-color: #4d84ff;
    border-radius: 12px;
  }
}

.invite-modal {
  top: 25%;

  .invite-container {
    padding: 0 32px;
  }

  .segmented {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  .invite-modal-input {
    height: 46px;
    border: 2px solid #e7ebf2;
    border-radius: 10px;
  }

  .invite-modal-select {
    width: 100%;
  }

  .invite-modal-button {
    width: 360px;
    height: 48px;

    font-family: Ubuntu-Bold;
    font-size: 16px;
    color: #fff;

    background-color: #4d84ff;
    border-radius: 8px;
  }

  :global {
    .ant-btn-primary[disabled] {
      color: white;
      background-color: rgb(77 132 255 / 100%);

      &:hover {
        color: white;
        background-color: rgb(77 132 255 / 100%);
      }
    }

    .ant-modal-body {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  .invite-link-container {
    display: block;

    height: 160px;
    padding: 24px 32px 40px;

    background: #f3f4f6;
    border-radius: 0 0 20px 20px;
    box-shadow: inset 0 1px 0 0 #edf2f9;

    .invite-link-title {
      height: 24px;
      margin-bottom: 24px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #222;
      text-align: center;
    }

    .invite-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .copy-btn {
        cursor: pointer;

        width: 136px;
        height: 48px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 48px;
        color: #fff;
        text-align: center;

        background: #4d84ff;
        border-radius: 12px;
      }

      .invite-input {
        width: 464px;
        height: 48px;
      }
    }
  }
}

.address-table-container {
  .btn-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
}

.add-address-icon {
  width: 12px;
  height: 12px;
}

.add-address-button {
  width: 160px !important;
  height: 40px !important;
  margin-bottom: 20px;

  font-family: Ubuntu-Bold;
  font-size: 16px;
  color: #fff;

  background-color: #4d84ff;
  border-radius: 12px !important;
}

.address-table {
  .address-label {
    padding: 2px 12px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;

    background: #a36b2b;
    border-radius: 12px;
  }

  .address-label-ellipsis {
    cursor: pointer;

    overflow: hidden;
    display: inline-block;

    max-width: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .address-more {
    cursor: pointer;

    display: inline-block;

    height: 20px;
    margin-left: 5px;
    padding: 0 3px;

    font-family: Ubuntu-Medium;
    font-size: 12px;
    color: #fff;
    text-align: center;

    background: #4d84ff;
    border-radius: 10px;
  }

  .active {
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #65c97a;
  }

  .inactive {
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #e74e5a;
  }

  .address-button {
    cursor: pointer;
    margin-right: 8px;
    font-family: Ubuntu-Medium;
    color: #4d84ff;
  }
}

.blue-address {
  height: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d84ff;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  padding-bottom: 32px;

  &.actions-bottom-0 {
    padding-bottom: 0;
  }

  .action-btn {
    flex: 1;
  }

  .action-btn + .action-btn {
    margin-left: 32px;
  }
}

.optional {
  height: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8590a6;
}

.add-modal,
.invite-container {
  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
      margin-right: 0;
    }
  }
}

.modal-item {
  margin-bottom: 5px;
  font-family: Ubuntu-Medium;
  font-size: 14px;
  color: #222;
}

.email-field {
  width: 100%;
  height: 48px;
  margin-top: 24px;

  font-family: Ubuntu-Medium;
  line-height: 42px;
  color: #222;
  text-align: center;

  background-color: #f9f9f9;
  border: 2px solid #e7ebf2;
  border-radius: 10px;
}

.org-settings-container {
  width: 100%;
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;

  .org-settings-profile-form {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 32px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;

    .inner-form {
      padding-right: 32px;
      padding-bottom: 32px;
      padding-left: 32px;
      border-bottom: 1px solid #edf2f9;

      .inner-form-item {
        max-width: 480px;
      }

      :global {
        .ant-form-item-label {
          padding-bottom: 8px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222;
        }
      }
    }

    .submit-wrapper {
      display: flex;
      flex-direction: row-reverse;

      margin-top: 32px;
      padding-right: 32px;
      padding-left: 32px;

      .submit-button {
        :global {
          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }
      }
    }
  }
}

.edit-confirm-content {
  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222;
}
