.detail-container {
  padding: 24px 32px;

  .header-wrapper {
    display: flex;
    align-items: center;
  }

  .content {
    margin-top: 24px;

    :global {
      .ant-tabs-nav {
        margin-bottom: 24px;
      }
    }
  }
}

.home-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 32px;
  margin-bottom: 28px;

  font-family: Ubuntu-bold, Ubuntu;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
  color: #222;
}

.tab-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px 40px 40px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
}

.module-img {
  width: 160px;
  height: 160px;
  margin-bottom: 16px;

  background: url('~@/assets/images/png_module.png') no-repeat;
  background-size: contain;
}

.label {
  margin-bottom: 24px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 24px;
  color: #222;
}

.collapse-wrapper {
  display: flex;
  width: 100%;
  max-width: 720px;
  margin-top: 40px;

  .module-desc-collapse {
    flex: 1;

    :global {
      .ant-collapse {
        background: rgb(248 249 254 / 50%);
        border: 1px solid #edf2f9;
        border-radius: 12px;

        .ant-collapse-item-active {
          .ant-collapse-header {
            box-shadow: inset 0 -1px 0 0 #edf2f9;
          }
        }

        .ant-collapse-item {
          .ant-collapse-content {
            background: rgb(248 249 254 / 50%);
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .ant-collapse-content-box {
              padding: 16px;

              font-family: Ubuntu;
              font-size: 12px;
              line-height: 20px;
              color: #222;
            }
          }
        }
      }
    }
  }
}
