.authorize-container {
  display: flex;
  flex-direction: column;

  margin-top: 31px;
  padding-top: 24px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;

  .authorize-steps {
    display: flex;
    flex-direction: column;

    margin-bottom: 40px;
    padding-right: 32px;
    padding-left: 32px;

    .steps-title {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #222;
    }

    .steps-box {
      position: relative;
      overflow: hidden;
      margin-top: 40px;
    }
  }

  .authorize-content {
    display: flex;
    flex-direction: column;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 32px;

  border-top: 1px solid #edf2f9;

  .action-buttons {
    display: flex;
    flex-direction: row;

    .action-btn + .action-btn {
      margin-left: 16px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;

  .section-title {
    padding: 16px 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow:
      inset 0 -1px 0 0 #edf2f9,
      inset 0 1px 0 0 #edf2f9;

    .tip-icon {
      cursor: pointer;

      display: inline-block;

      width: 16px;
      height: 16px;
      margin-left: 6px;

      vertical-align: middle;

      background: url('~@/assets/images/tips.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  .section-content {
    padding-right: 32px;
    padding-left: 32px;

    .section-item {
      margin-top: 32px;
    }

    .section-item:last-child {
      margin-bottom: 32px;
    }

    &.token-approval-table-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
