.cobo-select {
  position: relative;

  .arrow_icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
    transition: transform 300ms;
  }

  .delete-icon {
    width: 12px;
    height: 12px;
    margin-left: 12px;
    color: #fff;
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global {
    .ant-select {
      width: 100%;
      height: 48px;

      .ant-select-item-empty {
        padding: 12px;
      }

      .ant-select-item {
        line-height: 24px;
        color: rgba(133, 144, 166, 85%);
      }
    }

    .ant-select:not(
        .ant-select-disabled,
        .ant-select-customize-input,
        .ant-pagination-size-changer
      ):hover
      .ant-select-selector {
      border-color: #e7ebf2 !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px;
      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #e7ebf2;
      border-right-width: 2px;
    }

    .ant-select:not(.ant-select-disabled):focus .ant-select-selector {
      border-color: #e7ebf2;
      border-right-width: 2px;
    }

    .ant-select:not(.ant-select-disabled):active .ant-select-selector {
      border-color: #e7ebf2;
      border-right-width: 2px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      display: flex;
      align-content: center;
      align-items: center;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
    }

    .ant-select-single .ant-select-selector {
      color: #222;
    }

    .ant-select-disabled .ant-select-selector .ant-select-selection-item,
    .ant-select-disabled
      .ant-select-selector
      .ant-select-selection-placeholder {
      color: #8590a6;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: #e7ebf2;
      border-right-width: 2px;
      box-shadow: none;
    }

    .ant-select-status-error.ant-select:not(
        .ant-select-disabled,
        .ant-select-customize-input
      ).ant-select-open
      .ant-select-selector,
    .ant-select-status-error.ant-select:not(
        .ant-select-disabled,
        .ant-select-customize-input
      ).ant-select-focused
      .ant-select-selector {
      border-right-width: 2px;
      box-shadow: none;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 48px;
      padding: 0 16px;
    }

    .ant-select-multiple .ant-select-selection-item {
      height: 32px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 12px;
      font-weight: bold;
      line-height: 32px;
      color: #fff;

      background: #4d84ff;
      border-radius: 8px;
    }

    .ant-select-multiple .ant-select-selection-item-remove svg {
      margin-right: 8px;
      margin-left: 8px;
      color: #fff;
    }

    .ant-select-multiple.ant-select-disabled
      .ant-select-selector
      .ant-select-selection-item {
      color: #fff;
    }

    .ant-select-multiple.ant-select-disabled
      .ant-select-selector
      .ant-select-selection-placeholder {
      font-family: Ubuntu-Medium, Ubuntu;
      line-height: 44px;
      color: #8590a6;
    }

    .ant-select-multiple .ant-select-selection-placeholder {
      font-family: Ubuntu-Medium, Ubuntu;
      line-height: 44px;
    }

    .ant-select-selection-overflow-item + .ant-select-selection-overflow-item {
      margin-left: 12px;
    }

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      max-width: calc(100% - 60px);
    }
  }

  .tag-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 6px 0 6px 12px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;

    background: #4d84ff;
    border-radius: 8px;

    .label {
      margin-right: 12px;
    }

    .delete-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .line {
        width: 2px;
        height: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

        opacity: 0.3;
        background: #fff;
        border-radius: 1px;
      }

      .delete-view {
        cursor: pointer;
        flex: 1;
        padding-right: 8px;

        .delete-icon {
          width: 8px;
          height: 8px;
          margin-left: 8px;
        }
      }
    }
  }

  &.gray-cobo-select {
    .tag-wrapper {
      &.gray {
        background: #8590a6;
      }
    }

    :global {
      .ant-select-multiple .ant-select-selection-item {
        background: #8590a6;
      }
    }
  }
}

:global {
  .ant-select-dropdown {
    padding: 4px 0 !important;
  }

  .ant-select-dropdown .ant-select-item {
    color: rgba(133, 144, 166, 85%);
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 600;
    color: rgba(0, 0, 0, 85%);
    background-color: #f1f5ff;
  }

  .ant-select-dropdown
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f1f5ff;
  }

  .ant-select-dropdown-empty {
    .ant-select-item-empty {
      padding: 12px 8px 8px 16px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }
}
