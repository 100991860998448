.lamp-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px;

  background: rgb(232 110 44 / 10%);
  border-radius: 12px;

  .lamp {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_color_warning.png') no-repeat;
    background-size: cover;
  }

  .note-text {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #fa8c16;
  }
}
