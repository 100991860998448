.webhook-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 304px;

  .copy-btn {
    cursor: pointer;

    display: block;

    width: 20px;
    min-width: 20px;
    height: 20px;

    background: url('~@/assets/icons/ic_copy.png') no-repeat;
    background-size: cover;
  }

  .btn-box {
    display: flex;
    align-items: center;
  }

  .btn {
    width: 160px;
    height: 40px;

    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}
