.divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .divider-with-label {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .label {
      margin-right: 20px;
      margin-left: 20px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
    }
  }

  .divider {
    flex: 1;
    height: 1px;
    background-color: #e7ebf2;
  }
}
