.pag-wrapper {
  .pag-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;

    .prev-btn {
      width: 24px;
      height: 24px;
      background: url('~@/assets/icons/ic_pag_prev.png') no-repeat;
      background-size: 24px;

      &.disabled {
        background: url('~@/assets/icons/ic_pag_prev_off.png') no-repeat;
        background-size: 24px;
      }
    }

    .next-btn {
      width: 24px;
      height: 24px;
      background: url('~@/assets/icons/ic_pag_next.png') no-repeat;
      background-size: 24px;

      &.disabled {
        background: url('~@/assets/icons/ic_pag_next_off.png') no-repeat;
        background-size: 24px;
      }
    }

    .jump {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #8590a6;
    }
  }

  :global {
    .ant-pagination-item {
      line-height: 32px;
      border: none !important;

      a {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        color: #8590a6;
      }
    }

    .ant-pagination-item:hover {
      a {
        color: #4d84ff;
      }
    }

    .ant-pagination-item-active {
      a {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        color: #4d84ff;
      }
    }

    .ant-pagination-options {
      margin-left: 41px;
    }

    .ant-pagination-options-quick-jumper {
      font-family: Ubuntu;
      font-size: 14px;
      color: #8590a6;

      input {
        height: 24px;
        margin: 0 6px;
        padding-top: 2px;
        padding-bottom: 2px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;

        border: 1px solid #e7ebf2;
        border-radius: 4px;
      }

      input:hover {
        border-color: #4d84ff;
      }

      input:focus {
        border-color: #4d84ff;
      }
    }
  }
}
