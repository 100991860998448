.section-container {
  display: flex;
  flex-direction: column;

  .section-title {
    padding: 16px 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow:
      inset 0 -1px 0 0 #edf2f9,
      inset 0 1px 0 0 #edf2f9;

    .tip-icon {
      cursor: pointer;

      display: inline-block;

      width: 16px;
      height: 16px;
      margin-left: 6px;

      vertical-align: middle;

      background: url('~@/assets/images/tips.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  .section-content {
    padding-right: 32px;
    padding-left: 32px;

    .section-item {
      margin-top: 32px;
    }

    .section-item:last-child {
      margin-bottom: 32px;
    }
  }

  .protocols {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .protocol {
    display: flex;
    align-items: center;
    margin-right: 16px;

    .protocol-icon {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .protocol-name {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 24px;
      color: #222;
    }
  }
}
