.farming {
  display: flex;
  align-items: center;

  .tokens {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    place-content: center flex-start;
    align-items: center;

    width: 24px;
    height: 24px;

    &.tokens-1 {
      width: 24px;
    }

    &.tokens-2 {
      width: 42px;

      &:nth-child(1) {
        z-index: 2;
      }

      &:nth-child(2) {
        z-index: 1;
      }
    }

    &.tokens-3 {
      width: 42px;
      height: 42px;

      .token {
        &:nth-child(1) {
          z-index: 3;
        }

        &:nth-child(2) {
          z-index: 2;
        }

        &:nth-child(3) {
          z-index: 1;
          top: 18px;
          left: 9px;
        }
      }
    }

    &.tokens-4 {
      width: 36px;
      height: 36px;

      .token {
        &:nth-child(1) {
          z-index: 4;
        }

        &:nth-child(2) {
          z-index: 3;
        }

        &:nth-child(3) {
          z-index: 2;
          top: 18px;
          left: 0;
        }

        &:nth-child(4) {
          z-index: 1;
          top: 18px;
          left: 18px;
        }
      }
    }
  }

  .token {
    position: absolute;
    top: 0;
    left: 0;

    width: 24px;
    height: 24px;
    margin-left: -6px;

    border-radius: 16px;

    &:nth-child(2) {
      z-index: 1;
      left: 16px;
    }
  }

  .strategy-info {
    margin-left: 16px;
  }

  .strategy-name {
    display: flex;
    align-items: center;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 26px;
    color: #222;

    .strategy-name-new {
      flex-shrink: 0;

      width: 38px;
      height: 16px;
      margin-left: 8px;

      background: url('~@/assets/icons/strategy/tag_new.png') no-repeat;
      background-size: 38px 16px;
    }
  }

  .strategy-protocols {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .strategy-protocol {
      margin-right: 8px;
      padding: 0 8px;

      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 20px;
      color: #8590a6;

      background: rgb(133 144 166 / 7.29%);
      border-radius: 4px;
    }
  }
}

.trading {
  display: flex;
  align-items: center;

  .token {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }

  .strategy-name {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    color: #222;
  }
}

.bundle-list {
  :global {
    .ant-table-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 0 !important;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 0 !important;
    }

    .ant-table-thead {
      background: #fcfdff;
      border-radius: 0 !important;
      box-shadow: inset 0 -1px 0 0 #edf2f9;
    }

    .ant-table-content table {
      border: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .initiator {
    display: inline-block;

    margin-right: 8px;
    padding: 0 8px;

    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    color: #000;

    background: rgb(133 144 166 / 7.29%);
    border-radius: 4px;
  }

  .bundle-actions {
    display: flex;
    align-items: center;

    .btn {
      cursor: pointer;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      color: #4d84ff;
    }

    .btn + .btn {
      margin-left: 30px;
    }
  }

  .service-name {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #222;
  }

  .service-info {
    display: flex;
    align-items: center;

    .service-type {
      margin-top: 2px;

      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
    }
  }
}
