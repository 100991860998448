.userinfo-container {
  padding: 0 24px;

  .current-org {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 48px;
    padding: 10px 12px 10px 13px;

    background: #fff;
    border: 1px solid #e7ebf2;
    border-radius: 12px;
  }

  .current-org-content {
    cursor: pointer;
    display: flex;
    align-items: center;

    .org-info {
      margin-left: 10px;

      .org-name {
        overflow: hidden;

        max-width: 124px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        line-height: 12px;
        color: #222;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .role {
        margin-top: 4px;

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 12px;
        color: #8590a6;
      }
    }
  }

  .selector-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    transition-duration: 0.05s;

    &.open {
      transform: rotate(180deg);
    }
  }

  :global {
    .ant-dropdown-menu {
      overflow-y: scroll;

      max-height: 316px;
      padding: 0;

      background-color: #fff;
      border-radius: 12px 12px 0 0;

      .ant-dropdown-menu-item {
        padding: 10px 16px;

        &:hover,
        &:active,
        &:focus {
          background: #f3f8ff;
        }

        &:first-of-type {
          cursor: arrow;

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }

    .ant-dropdown-placement-bottomLeft {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}

.select-option {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 240px;
  height: 36px;

  .current-org-content .org-info {
    .org-name {
      max-width: 180px;
    }
  }
}

.icon-selected {
  display: block;

  width: 16px;
  height: 16px;

  background: url('~@/assets/images/ic-selected2.png') no-repeat;
  background-size: 16px 16px;
}

.drop-down-title {
  margin-top: 6px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: #222;
}

.content {
  background: #fff;
  border: 1px solid #e6e8ec;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);
}

.footer {
  position: relative;

  display: flex;
  align-items: center;

  width: 272px;
  height: 44px;
  padding: 0 16px;

  background: #fff !important;
  // border: 1px solid #e6e8ec;
  border-top: none;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 1px 0 0 #e6e8ec inset;

  .view-all {
    cursor: pointer;

    display: flex;
    align-items: center;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #4d84ff;

    .view-all-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      background: url('~@/assets/icons/ic-view.png') no-repeat;
      background-size: 16px 16px;
    }
  }
}

.org-name-logo {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
