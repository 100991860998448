.fail-content-container {
  padding-top: 40px;
  border-top: 1px solid #edf2f9;

  .fail-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;

    background: url('~@/assets/images/ic_mistake@2x.png') no-repeat;
    background-size: 80px;
  }

  .title {
    width: 100%;
    margin: 28px auto 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #e74e5a;
    text-align: center;
  }

  .content {
    width: 100%;
    height: 24px;
    margin: 0 auto 32px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .button-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    width: 512px;
    margin: 0 auto;
  }

  .check-btn {
    width: 240px;
    height: 48px;

    line-height: 20px;
    color: #4d84ff;

    border: 2px solid #4d84ff;
    border-radius: 12px;

    .browse-icon {
      position: relative;
      left: 20px;

      width: 10px;
      height: 10px;

      color: #4d84ff;
    }

    :global {
      .ant-btn {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 14px;
        font-weight: bold;
        line-height: 48px;
      }
    }
  }

  .retry-btn {
    width: 240px;
    height: 48px;
    margin: 0 auto;
    border-radius: 12px;

    :global {
      .ant-btn {
        height: 48px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 48px;
        color: #fff;
      }
    }
  }
}
