.strategy-board {
  width: 100%;
  padding: 24px 32px;

  .strategy-board-header {
    display: flex;
    flex-wrap: nowrap;
    place-content: center space-between;

    width: 100%;
    height: 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    color: #222;
    white-space: nowrap;

    .configured {
      display: flex;
      align-items: center;

      height: 32px;
      padding: 0 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      color: #26c165;
      text-align: right;

      background: #e8fff1;
      border-radius: 12px;

      .configured-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;

        background: url('~@/assets/icons/ic_success_dot.png') no-repeat;
        background-size: 16px 16px;
      }
    }

    .invested-button {
      cursor: pointer;

      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;

      height: 32px;
      padding: 0 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;

      background: #4d84ff;
      border-radius: 10px;

      .next-icon {
        display: block;

        width: 14px;
        height: 14px;

        background: url('~@/assets/icons/ic_next.png') no-repeat;
        background-size: 14px 14px;
      }
    }
  }

  .strategy-board-network {
    display: flex;
    flex-wrap: nowrap;

    width: 100%;
    height: 24px;
    margin-top: 22px;

    .strategy-board-network-item {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;

      margin-left: 48px;

      &:first-of-type {
        margin-left: 0;
      }

      .chain-icon {
        margin: 0 6px 0 8px;
      }

      .chain-name {
        font-family: Ubuntu;
        font-size: 14px;
        line-height: 24px;
        color: #060612;
      }
    }

    .strategy-website {
      cursor: pointer;

      height: 20px;
      margin-left: 4px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #4d84ff;
    }

    .strategy-board-network-label {
      font-family: Ubuntu;
      font-size: 14px;
      line-height: 24px;
      color: #8590a6;
    }

    .strategy-tokens {
      display: flex;
      flex-wrap: nowrap;
      place-content: center left;
      align-items: center;

      margin-left: 8px;
    }

    .strategy-token-item {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }

  .strategy-board-portfolio {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;

    height: 44px;
    margin-top: 34px;

    .portfolio-item {
      flex: 1;
      height: 44px;
      margin-right: 32px;

      .portfolio-title {
        display: block;

        height: 24px;
        margin-bottom: 4px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }

      .portfolio-text {
        display: flex;
        flex-wrap: nowrap;
        place-content: center left;
        align-items: center;

        height: 16px;

        font-family: Ubuntu;
        font-size: 12px;
        line-height: 16px;
        color: #8590a6;
      }
    }
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }
}
