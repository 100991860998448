.safe-role-selection {
  display: flex;
  flex-direction: column;

  .setup-role-container {
    display: flex;
    flex-direction: column;
    margin-top: -8px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .setup-role-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 160px;

      .name {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 20px;
        color: #222;
      }
    }

    .selector {
      flex: 1;
      max-width: 720px;

      :global {
        .ant-select-item-option-content {
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;

          padding-left: 6px;
        }
      }
    }
  }
}

.tip-icon {
  cursor: pointer;

  width: 16px;
  height: 16px;
  margin-left: 6px;

  vertical-align: middle;

  background: url('~@/assets/icons/role_tip.png') no-repeat;
  background-size: 16px 16px;
}

.icon-selected {
  width: 20px;
  height: 20px;
  background: url('~@/assets/icons/ic_selected.png') no-repeat;
  background-size: 20px 20px;
}

.selector {
  :global {
    .ant-select-item-option:not(.ant-select-item-option-disabled) {
      color: #222;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      .ant-select-item-option-content {
        color: #4d84ff;
      }
    }
  }
}
