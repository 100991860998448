.module-img {
  width: 160px;
  height: 160px;
  margin-bottom: 16px;

  background: url('~@/assets/images/png_module.png') no-repeat;
  background-size: contain;
}

.label {
  margin-bottom: 24px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 24px;
  color: #222;
}
