.permission-modal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-bottom: 32px;
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 8px;
    margin-bottom: 12px;

    .add-permission-btn {
      width: 178px;
      height: 40px;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;

  .action-btn {
    flex: 1;
  }

  .action-btn + .action-btn {
    margin-left: 32px;
  }

  .action-btn-danger {
    color: #fff;
    background: #e74e5a;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background: #e74e5a;
    }
  }
}

.tx-content {
  display: flex;
  flex-direction: column;

  .content {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    line-height: 24px;
    color: #8994ab;

    .gnosis-safe {
      cursor: pointer;
      font-family: Ubuntu-Medium;
      color: #4d84ff;
      text-decoration: underline #4d84ff;
    }
  }

  .content + .content {
    margin-top: 12px;
  }
}

.blod-text {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: bold;
  color: #222;
}
