.cobo-segmented {
  display: block;
  letter-spacing: 0;

  :global {
    .ant-segmented {
      height: 40px;
      padding: 0;

      background: #e7ebf2;
      border-radius: 20px;

      transition: none;

      .ant-segmented-group {
        height: 40px;
        padding: 4px;
        transition: none;
      }

      .ant-segmented-item {
        color: #8590a6;
        transition: none;
      }

      .ant-segmented-item:hover:not(
          .ant-segmented-item-selected,
          .ant-segmented-item-disabled
        ) {
        color: #8590a6;
      }

      .ant-segmented-item-selected {
        height: 32px;

        line-height: 32px;
        color: #4d84ff;

        background: #fff;
        border-radius: 16px;
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 9%);
      }

      .ant-segmented-item-label {
        height: 32px;
        padding: 0 24px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;

        background: transparent !important;

        transition: none;

        > span {
          line-height: 32px;
        }
      }

      .ant-segmented-item:hover:not(
          .ant-segmented-item-selected,
          .ant-segmented-item-disabled
        )::after {
        background-color: transparent;
      }

      .ant-segmented-thumb {
        height: 32px;
        margin-top: 4px;
        border-radius: 14px;
      }
    }
  }
}
