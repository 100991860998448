.tab-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px 40px 40px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
}

.collapse-wrapper {
  display: flex;
  width: 100%;
  max-width: 720px;
  margin-top: 40px;

  .module-desc-collapse {
    flex: 1;

    :global {
      .ant-collapse {
        background: rgb(248 249 254 / 50%);
        border: 1px solid #edf2f9;
        border-radius: 12px;

        .ant-collapse-item-active {
          .ant-collapse-header {
            box-shadow: inset 0 -1px 0 0 #edf2f9;
          }
        }

        .ant-collapse-item {
          .ant-collapse-content {
            background: rgb(248 249 254 / 50%);
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .ant-collapse-content-box {
              padding: 16px;

              font-family: Ubuntu;
              font-size: 12px;
              line-height: 20px;
              color: #222;
            }
          }
        }
      }
    }
  }
}
