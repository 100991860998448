.my-bot-container {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  .my-bots-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .section-title {
    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #222;

    &.first {
      margin-top: 0;
    }
  }

  .strategy-bot-list {
    display: flex;
    flex-direction: column;
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    place-content: center center;
    align-items: center;

    height: 100%;
    margin-right: 32px;
    padding: 40px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    .empty-text {
      height: 24px;
      margin-top: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
    }
  }

  .my-bots-tabs {
    margin-top: 32px;
  }

  .tip-icon {
    cursor: pointer;

    display: inline-block;

    width: 16px;
    height: 16px;
    margin-left: 8px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }
}
