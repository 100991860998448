.transaction-wrapper {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 44px;
  margin-right: 16px;
  padding: 0 12px;

  background: #fff;
  border: 2px solid #e7ebf2;
  border-radius: 12px;

  .transaction-text {
    height: 16px;
    margin-right: 6px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #222;
  }

  .transaction-number {
    display: flex;
    align-items: center;

    height: 16px;
    padding: 0 6px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    color: #fff;
    text-align: center;

    background: #ff5b00;
    border-radius: 8px;
  }
}

.transaction-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  background: url('~@/assets/icons/ic-transaction.png') no-repeat;
  background-size: 16px 16px;
}
