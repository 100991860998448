.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.spin-container {
  position: relative;
  width: 100%;
  height: 100%;
}

:global {
  .ant-form-item-label {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    padding: 8px 16px !important;

    font-family: Ubuntu-Medium, Ubuntu !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: #8590a6 !important;
    text-shadow: none !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 32px !important;
  }

  .ant-tabs-tab-btn:focus {
    color: #4d84ff;
  }

  .ant-tabs-tab-btn:active {
    color: #4d84ff;
  }

  .ant-tabs-tab-btn:hover {
    color: #4d84ff;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: Ubuntu-Medium, Ubuntu;
    line-height: 24px;
    color: #222 !important;
    text-shadow: none !important;
  }

  .ant-tabs-ink-bar {
    background: #4d84ff;
  }

  .ant-tooltip-inner {
    padding: 12px 16px !important;

    font-family: Ubuntu !important;
    font-size: 14px;
    line-height: 20px;
    color: #fff;

    background: #0a0a0a !important;
    border-radius: 12px !important;
    box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%) !important;
  }

  .ant-tooltip-arrow-content {
    background: #0a0a0a;
  }

  .ant-select-dropdown {
    background: #fff;
    border: 1.5px solid #e5e9f0;
    border-radius: 12px;
    box-shadow: 0 6px 24px 0 rgb(0 0 0 / 8%);
  }

  // .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  //   font-family: Ubuntu-Medium, Ubuntu;
  //   font-weight: 500;
  //   color: #181c27;
  //   background: #f1f5ff;
  // }

  .ant-select-item {
    font-family: Ubuntu;
    line-height: 24px;
    color: rgb(133 144 166 / 85%);
  }

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }

  .ant-message {
    z-index: 3010;
  }
}
