.queue {
  display: block;

  .status {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
  }

  .action {
    cursor: pointer;

    display: flex;
    align-items: center;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #4d84ff;

    .browse-icon {
      width: 10px;
      height: 10px;
      margin-left: 8px;
      color: #4d84ff;
    }
  }

  .btn-box {
    display: flex;
    flex-direction: row-reverse;
    margin: 24px 0;

    .email-icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;

      color: #4d84ff;

      background: url('~@/assets/icons/ic-email.png') no-repeat;
      background-size: cover;
    }

    .btn {
      box-sizing: content-box;
      width: 183px;
      // height: 44px;
      // line-height: 44px;
      height: 20px;
    }
  }

  .pag-wrapper {
    display: flex;
    justify-content: center;

    margin-top: 24px;
    padding: 8px 24px;
    padding-right: 16px;

    border-radius: 12px;
  }
}
