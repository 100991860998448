.form-item-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  &.form-item-multiline {
    align-items: flex-start;

    .name {
      line-height: 48px;
    }
  }

  .name {
    min-width: 160px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 20px;
    color: #222;
  }

  .content {
    float: right;
    flex: 1;
    // overflow: hidden;
    max-width: 720px;

    &.with-content-name {
      display: flex;
      flex-direction: row-reverse;
      max-width: unset;

      .content-name {
        margin-right: 16px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 48px;
        color: #222;
      }

      .children {
        flex: 1;
        max-width: 720px;
      }
    }
  }
}
