.empty-table {
  padding: 24px;
  text-align: center;

  &-img {
    width: 72px;
  }

  &-text {
    display: block;

    height: 16px;
    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 12px;
    line-height: 16px;
    color: #8590a6;
  }
}

.token-approval-hint {
  margin-bottom: 0;
}

.token-approval {
  &-header {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
    padding: 0 0 24px;

    &-button {
      cursor: pointer;
      font-family: Ubuntu-Medium;
    }

    .token-approval-header-button-content {
      height: 40px;
      line-height: 40px;
    }
  }
}

.token-approval-table {
  .spender-name {
    cursor: pointer;

    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    place-content: center left;
    align-items: center;

    max-width: 360px;
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .token-box {
    cursor: pointer;

    display: flex;
    align-content: center;
    align-items: center;

    height: 20px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #222;

    .token-img {
      height: 20px;
      margin-right: 10px;
    }
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 8px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }

  .revoke-button {
    cursor: pointer;

    width: 48px;
    height: 20px;
    margin-right: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #e74e5a;
  }

  .update-button {
    cursor: pointer;

    width: 48px;
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
  }
}
