.add-token-approval-modal {
  display: block;

  .form-item {
    margin-top: 32px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .username-item {
    margin-top: 0;
  }

  .settings-item-label {
    display: block;

    height: 20px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .settings-item-input-address {
    height: 48px;
  }

  .token-item {
    :global {
      .ant-input-affix-wrapper-disabled .ant-input[disabled] {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        color: #8590a6;

        border: none;
      }
    }
  }

  .token-img {
    height: 20px;
    margin-right: 10px;
  }

  .spender-item {
    margin-top: 24px;
  }

  .allowance-type-item {
    :global {
      .ant-input-affix-wrapper {
        height: 48px;
        margin-top: 16px;
        padding-left: 16px;
        line-height: 48px;

        .ant-input {
          border-radius: 0;
        }
      }
    }
  }

  :global {
    .ant-input {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      color: #222;

      border-color: #e7ebf2;
      border-radius: 12px;
    }

    .ant-input:hover {
      border-color: #e7ebf2;
      border-right-width: 2px;
    }

    .ant-input:focus {
      border-color: #e7ebf2;
      border-right-width: 2px;
    }

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: none;
    }

    .ant-input::-webkit-outer-spin-button,
    .ant-input::-webkit-inner-spin-button {
      appearance: none;
    }

    input[type='number'] {
      appearance: textfield;
    }
  }
}
