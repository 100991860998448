.gas-account {
  position: relative;
  width: 100%;
  margin-top: 24px;

  .gas-account-item {
    width: 240px;
    height: 132px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    .gas-account-top {
      padding: 12px 16px 0;
    }

    &.low-gas-item {
      border: 2px solid #e74e5a;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;

      &-left {
        .chain-icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .balance-number {
          height: 24px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #222;

          &.low-gas-number {
            color: #e74e5a;
          }
        }
      }

      &-right {
        display: flex;
        align-items: center;
        height: 24px;

        .token-name {
          height: 20px;
          margin-left: 8px;

          font-family: Ubuntu;
          font-size: 12px;
          line-height: 20px;
          color: #8590a6;
        }
      }
    }

    .chain-name {
      display: block;

      height: 20px;
      margin-top: 2px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 20px;
      color: #8590a6;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .safe-address {
        height: 20px;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #262626;
      }

      &-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .copy-btn {
          cursor: pointer;

          display: block;

          width: 20px;
          min-width: 20px;
          height: 20px;

          background: url('~@/assets/icons/ic_copy.png') no-repeat;
          background-size: cover;
        }

        .es-icon {
          cursor: pointer;
          margin-left: 16px;
        }
      }
    }

    &-bottom {
      display: flex;
      place-content: center space-between;
      align-items: center;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 32px;
      color: #4d84ff;

      box-shadow: inset 0 1px 0 0 #ecf1f8;

      .action-btn {
        cursor: pointer;

        position: relative;

        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        &:first-of-type::after {
          content: '';

          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;

          width: 1px;
          height: 18px;
          margin: auto;

          background: #ecf1f8;
        }
      }
    }
  }
}

.gas-account-warp {
  position: relative;
  display: block;
  width: 100%;

  .gas-account-next-btn {
    cursor: pointer;

    position: absolute;
    z-index: 100;
    top: 64px;
    right: -16px;

    width: 48px;
    height: 48px;

    background: url('~@/assets/icons/png_right.png') no-repeat;
    background-size: 48px 48px;
  }

  .gas-account-prev-btn {
    cursor: pointer;

    position: absolute;
    z-index: 100;
    top: 64px;
    left: -16px;

    width: 48px;
    height: 48px;

    background: url('~@/assets/icons/png_left.png') no-repeat;
    background-size: 48px 48px;
  }

  .hide-button {
    display: none;
  }
}

.empty-box {
  height: 132px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 132px;
  color: #8590a6;
  text-align: center;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);
}

.withdraw-modal-inner {
  .note {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .gnosis-safe-title {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .gas {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 12px;
    font-weight: 500;
    color: #222;
  }
}
