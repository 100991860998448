.permission-detail {
  .tip-text {
    display: flex;
    align-items: center;
  }

  .permission-detail-tabs {
    padding-bottom: 32px;
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }

  .permission-detail-desc {
    margin-bottom: 12px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }

  .permission-detail-func-box {
    margin-bottom: 16px;
    padding: 12px;
    background: #f1f5ff;
    border-radius: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .permission-detail-func-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 20px;
    margin-bottom: 4px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #4d84ff;
  }

  .permission-detail-func-text {
    font-family: Ubuntu;
    font-size: 12px;
    line-height: 24px;
    color: #222;
    vertical-align: middle;
  }

  .permission-detail-func-detail {
    overflow: hidden;
    display: block;

    max-width: 456px;

    font-family: Ubuntu;
    font-size: 12px;
    line-height: 24px;
    text-overflow: ellipsis;
  }

  .permission-detail-margin {
    display: block;
    height: 16px;
  }

  .token-box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    .reward-token-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .address-box {
    display: block;

    .address {
      display: block;

      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      color: #4d84ff;
    }
  }

  :global {
    .ant-modal-content .ant-modal-body {
      padding: 24px 32px 0;
    }
  }
}
