.auth-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background: url('~@/assets/images/png_bg.png') no-repeat;
  background-size: cover;
}

.auth-footer {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
}

.auth-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .auth-home-body {
    position: relative;
    margin-top: 86px;

    .auth-home-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 16px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      color: #222;
    }

    .auth-home-unleashDefi {
      margin-bottom: 48px;

      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
      text-align: center;
    }

    .auth-home-label {
      margin-bottom: 24px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .auth-home-btn {
    display: block;

    width: 480px;
    height: 56px;
    margin: 0 auto;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    text-align: center;

    // line-height: 56px;
    border-radius: 12px;
  }
}
