.wallet-connect-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 24px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;

  .loading-wrapper {
    position: relative;
    height: 64px;
  }

  &.input-container {
    padding: 24px 32px 40px;
  }

  &.waiting-container {
    padding: 24px 32px 40px;
  }

  &.parse-container {
    padding-top: 24px;
    padding-bottom: 32px;

    .tx-header {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  .logo {
    width: 78px;
    height: 48px;
    margin-top: 24px;
    margin-bottom: 24px;

    background: url('~@/assets/images/png_walletconnect.png') no-repeat;
    background-size: cover;
  }

  .uri-input {
    width: 100%;
    max-width: 720px;
    margin-bottom: 32px;
  }

  .tx-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .tx-detail {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 48px;
      color: #222;
    }

    .connect-info {
      display: flex;
      flex-direction: row;
      border: 2px solid #e7ebf2;
      border-radius: 12px;

      .protocol-info {
        cursor: pointer;

        display: flex;
        flex-direction: row;

        padding: 14px 12px;

        border-right: 2px solid #e7ebf2;

        .link-icon {
          width: 20px;
          min-width: 20px;
          height: 20px;

          background: url('@/assets/icons/ic_link.png') no-repeat;
          background-size: 20px 20px;
        }

        .protocol-img {
          width: 20px;
          height: 20px;
          margin-left: 8px;
        }

        .protocol-name {
          margin-left: 6px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: #000;
        }
      }

      .disconnect-btn {
        cursor: pointer;

        padding: 14px 12px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #4d84ff;
      }
    }
  }

  .empty-tx {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding-top: 24px;

    .tx-img {
      width: 160px;
      height: 160px;
      background: url('~@/assets/images/png_transaction.png') no-repeat;
      background-size: cover;
    }

    .empty-label {
      margin-top: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }
  }

  .lamp {
    margin-top: 24px;
    margin-right: 32px;
    margin-left: 32px;
  }

  .tx-detail-data {
    width: 100%;
    padding-right: 32px;
    padding-left: 32px;

    .tx-data-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 24px;

      .tx-data-label {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 48px;
        color: #222;
      }

      .tx-data-value {
        flex: 1;

        max-width: 720px;
        padding: 12px 16px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #8590a6;
        word-break: break-all;

        background: #f9f9f9;
        border: 2px solid #e7ebf2;
        border-radius: 12px;
      }
    }

    .tx-data-item + .tx-data-item {
      margin-top: 32px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 40px;
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 32px;

    box-shadow: inset 0 1px 0 0 #f0f4fa;
  }

  .expand-list {
    display: flex;
    flex-direction: column;

    .expand-item {
      display: flex;
      flex-direction: row;

      .expand-item-num {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-top: 2px;

        background-repeat: no-repeat;
        background-size: 16px 16px;

        &.num1 {
          background-image: url('@/assets/icons/ic_num_1.png');
        }

        &.num2 {
          background-image: url('@/assets/icons/ic_num_2.png');
        }

        &.num3 {
          background-image: url('@/assets/icons/ic_num_3.png');
        }

        &.num4 {
          background-image: url('@/assets/icons/ic_num_4.png');
        }

        &.num5 {
          background-image: url('@/assets/icons/ic_num_5.png');
        }
      }

      .expand-item-label {
        margin-left: 8px;

        font-family: Ubuntu;
        font-size: 12px;
        line-height: 20px;
        color: #222;
      }
    }

    .expand-item + .expand-item {
      margin-top: 12px;
    }
  }

  .reject-btn-wrapper {
    .reject-btn {
      background-color: #e74e5a;

      :hover {
        background-color: #e74e5a;
      }
    }
  }
}
