.position-table {
  .action {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-start;
    align-items: center;

    .gs-icon {
      cursor: pointer;

      display: inline-block;

      width: 20px;
      min-width: 20px;
      height: 20px;

      vertical-align: sub;

      background: url('~@/assets/icons/ic_gnosis_safe.png') no-repeat;
      background-size: cover;
    }

    .es-icon {
      cursor: pointer;

      display: inline-block;

      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 16px;

      vertical-align: sub;
    }
  }

  .safe {
    .safe-name {
      margin-bottom: 4px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: #222;
    }

    .safe-address {
      height: 16px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: #8590a6;
    }
  }

  .amounts {
    display: block;
    height: 100%;

    .token {
      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;

      height: 16px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .token-img {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      .token-number {
        height: 16px;

        font-family: Ubuntu;
        font-size: 12px;
        line-height: 16px;
        color: #222;
      }
    }
  }

  :global {
    .ant-table-tbody > tr > td {
      vertical-align: top;
    }
  }
}
