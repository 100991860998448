.bots-list {
  display: block;
  width: calc(100% - 32px);

  .group {
    position: relative;

    margin-bottom: 20px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      height: 56px;
      background: rgb(248 249 254 / 40%);
      border-radius: 12px 12px 0 0;
      box-shadow: inset 0 -1px 0 0 #edf2f9;

      &-chain-icon {
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: 12px 0 16px;
      }

      &-name {
        display: flex;
        align-items: center;
        height: 56px;
        margin-left: 40px;
      }

      &-card {
        // padding: 0 12px;
        height: 24px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #222;
      }
    }

    &-bots {
      display: flex;
      flex-wrap: wrap;
      place-content: center flex-start;
      align-items: center;

      padding: 24px 24px 0;

      background: #fff;
    }

    &-bot {
      cursor: pointer;

      display: flex;
      flex-direction: column;
      place-content: center flex-start;
      align-items: flex-start;

      width: 200px;
      height: 120px;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 16px;

      background: rgb(255 255 255 / 0%);
      border: 2px solid #e7ebf2;
      border-radius: 12px;

      &:hover {
        border: 2px solid #4d84ff;
      }

      .pool-id {
        overflow: hidden;

        max-width: 80px;
        height: 16px;
        padding: 0 4px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #fff;
        text-overflow: ellipsis;
        white-space: nowrap;

        background: #8590a6;
        border-radius: 3px;
      }

      &-tokens {
        display: flex;
        flex-wrap: nowrap;
        place-content: center flex-start;
        align-items: center;
      }

      &-token {
        width: 24px;
        height: 24px;
        margin-left: -6px;
        border-radius: 12px;

        &:first-of-type {
          margin-left: 0;
        }
      }

      .bot-name-box {
        display: flex;
        place-content: center flex-start;
        align-items: center;

        .bot-icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .bot-type {
          height: 17px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #8590a6;
        }
      }

      .bot-name {
        overflow-x: hidden;
        flex-shrink: 0;

        width: 164px;
        height: 20px;
        margin-top: 12px;
        margin-bottom: 8px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
