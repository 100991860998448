.setup-service-wrapper {
  padding: 20px 24px;

  .service-line {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .service {
      cursor: pointer;

      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding-top: 16px;
      padding-bottom: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #000;
      text-align: center;

      background: #f3f8ff;
      border-radius: 12px;
    }

    .service-placeholder {
      cursor: default;
      flex: 1;
      background: none;
    }

    .service + .service {
      margin-left: 16px;
    }
  }
}
