.investment-container {
  display: block;
  width: 100%;

  .investment-warp {
    position: relative;
    margin: 32px;

    .investment-box {
      display: flex;
    }

    .tip-icon {
      cursor: pointer;

      display: inline-block;

      width: 16px;
      height: 16px;
      margin-left: 8px;

      vertical-align: middle;

      background: url('~@/assets/images/tips.png') no-repeat;
      background-size: 16px 16px;
    }

    h1 {
      margin: 0;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #222;
    }

    .investment-title {
      display: flex;
      flex-wrap: nowrap;
      place-content: center space-between;
      align-items: center;

      margin-bottom: 24px;

      .button-wrapper {
        display: flex;
        flex-wrap: nowrap;
        place-content: center flex-end;
        align-items: center;
      }

      .next-icon {
        width: 12px;
        height: 12px;
        background: url('~@/assets/icons/ic_next.png') no-repeat;
        background-size: 12px 12px;
      }
    }
  }

  .flex {
    display: flex;
  }

  .top {
    margin-top: 24px;

    .investment-title {
      margin-bottom: 16px;
    }
  }

  .investment-title-content {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-start;
    align-items: center;
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }

  .fire-icon {
    display: block;

    width: 30px;
    height: 30px;
    margin-left: 8px;

    background: url('~@/assets/icons/strategy/campfire.png') no-repeat;
    background-size: 30px 30px;
  }
}
