.label {
  margin-bottom: 32px;

  font-family: Ubuntu;
  font-size: 16px;
  line-height: 24px;
  color: #222;
}

.module-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 32px;
  padding: 20px;

  background: #f9f9f9;
  border: 2px solid #e7ebf2;
  border-radius: 12px;

  .module-title {
    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #222;
  }

  .module-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-width: 616px;
    margin-top: 16px;

    .module-version {
      height: 24px;
      margin-right: 8px;
      padding: 0 8px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      color: #fff;

      background: #222;
      border-radius: 6px;
    }

    .module-address {
      margin-left: 32px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }
  }
}
