.search-token-modal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .search-input-wrapper {
    margin-right: 32px;
    margin-bottom: 16px;
    margin-left: 32px;

    .search-input {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }

    .search-icon {
      width: 20px;
      height: 20px;
      background: url('~@/assets/icons/ic_search.png') no-repeat;
      background-size: 20px 20px;
    }
  }

  .content {
    min-height: 272px;

    .token-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: 16px 32px;

      background: #f1f5ff;

      .token-info-wrapper {
        display: flex;
        flex-direction: row;

        .token-name-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .token-name {
            font-family: Ubuntu-Medium, Ubuntu;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #222;
          }

          .token-chain {
            font-family: Ubuntu;
            font-size: 12px;
            line-height: 16px;
            color: #8590a6;
          }
        }

        .token-icon {
          width: 40px;
          height: 40px;
        }
      }

      .add-btn {
        cursor: pointer;

        padding: 10px 20px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #4d84ff;

        border: 2px solid #4d84ff;
        border-radius: 12px;
      }
    }
  }
}
