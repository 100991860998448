.safe-selector {
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 208px;
  height: 72px;
  margin: 8px auto 12px;

  background: #f3f8ff;
  border-radius: 12px;

  transition: height 0.2s;

  .empty-safe {
    width: 100%;
    padding: 12px;

    .empty-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      background: url('~@/assets/icons/link-safe-default.png') no-repeat;
      background-size: 24px 24px;
    }

    .empty-text {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 24px;
      color: #222;
    }
  }

  .selector {
    position: relative;

    overflow: visible;

    width: 100%;
    height: 100%;
    padding: 12px;

    .current-safe {
      cursor: pointer;
      display: flex;

      .safe-address-box {
        display: flex;
        align-items: center;

        margin-top: 8px;

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 12px;
        color: #8590a6;
      }
    }
  }

  .current-safe {
    position: relative;

    .copy-btn {
      cursor: pointer;

      display: block;

      width: 16px;
      height: 16px;
      margin-left: 8px;
    }

    .safe-name {
      overflow: hidden;

      max-width: 136px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 24px;
      color: #222;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .selector-icon {
      cursor: pointer;

      position: absolute;
      top: 6px;
      right: 0;

      width: 12px;
      height: 12px;

      transition-duration: 0.05s;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.safe {
  position: relative;

  display: flex;
  align-items: center;

  width: 360px;
  height: 36px;

  .icon-selected {
    position: absolute;
    right: 0;

    width: 16px;
    height: 16px;

    background: url('~@/assets/images/ic-selected2.png') no-repeat;
    background-size: 16px 16px;
  }

  .safe-address {
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    color: #8994ab;
  }

  .safe-balance {
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    color: #8994ab;
  }
}

.safe-name-box {
  display: flex;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #222;

  .name {
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.chain-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.drop-down-title {
  margin-top: 6px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: #222;
}

.footer {
  position: relative;
  left: -1px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 360px;
  height: 44px;
  padding: 0 16px;

  background: #fff !important;
  border: 1px solid #e6e8ec;
  border-top: none;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 1px 0 0 #e6e8ec inset;

  .view-all {
    cursor: pointer;

    display: flex;
    align-items: center;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    color: #4d84ff;

    .view-all-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      background: url('~@/assets/icons/ic-view.png') no-repeat;
      background-size: 16px 16px;
    }
  }
}

.import-box {
  cursor: pointer;

  display: flex;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: #4d84ff;

  .import-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic-import.png') no-repeat;
    background-size: 16px 16px;
  }
}

.content {
  position: relative;
  top: 15px;
  left: -12px;

  width: 392px;
  max-height: 354px;

  background: #fff;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

  :global {
    .ant-dropdown-menu {
      overflow-y: scroll;

      max-height: 316px;
      padding: 0;

      background-color: transparent !important;
      border-radius: 12px 12px 0 0;

      .ant-dropdown-menu-item {
        padding: 10px 16px;

        &:hover,
        &:active,
        &:focus {
          background: #f3f8ff;
        }

        &:first-of-type {
          cursor: arrow;

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }

    .ant-dropdown-placement-bottomLeft {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
}
