.safe-container {
  position: relative;
  padding: 30px;

  .safe-add-icon {
    width: 12px;
    height: 12px;
  }

  .safe-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    &-title {
      height: 28px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #222;
    }

    .safe-add-button {
      width: 208px;
      height: 40px;

      font-family: Ubuntu-Bold;
      font-size: 16px;
      color: #fff;

      background-color: #4d84ff;
      border-radius: 8px;
    }
  }
}

.empty-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 152px;

  .empty-image {
    display: block;

    width: 160px;
    height: 160px;
    margin-bottom: 24px;

    background: url('~@/assets/images/empty.png') no-repeat;
    background-size: 160px 160px;
  }

  .empty-text {
    flex-basis: 100%;

    height: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .empty-link {
    cursor: pointer;
    color: #4d84ff;
  }
}

.safe-status-upgrade {
  cursor: pointer;

  width: 72px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;

  background: linear-gradient(270deg, #794ce8 0%, #e74caf 100%);
  border-radius: 8px;
}

.safe-status-empty {
  cursor: pointer;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 8px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  color: #8590a6;

  background: #e7ebf2;
  border-radius: 8px;

  .safe-status-empty-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;

    background: url('~@/assets/icons/ic_warning_1@2x.png') no-repeat;
    background-size: 14px 14px;
  }
}

.safe-status-disabled {
  cursor: pointer;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 8px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  color: #ffab2b;

  background: rgb(255 191 46 / 20%);
  border-radius: 8px;

  .safe-status-disabled-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;

    background: url('~@/assets/icons/ic_module_unable.png') no-repeat;
    background-size: 14px 14px;
  }
}

.safe-status-btn {
  height: 24px;
  line-height: 24px;
}

.safe-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  // justify-content: space-between;
  .safe-item {
    cursor: pointer;

    width: calc(100% / 3 - 14px);
    height: 160px;
    margin-right: 21px;
    margin-bottom: 21px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    &:hover {
      border: 1px solid #4d84ff;
    }

    &:nth-of-type(3n + 0) {
      margin-right: 0;
    }

    &-content {
      height: 80px;
      background: rgb(248 249 254 / 40%);
      border-radius: 12px 12px 0 0;
      box-shadow: inset 0 -1px 0 0 #edf2f9;

      .safe-item-header {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 8px;
        padding: 0 12px 0 33px;

        &-content {
          display: flex;
          justify-content: space-between;

          width: 100%;
          height: 28px;
          margin-top: 12px;
        }

        .safe-item-name {
          overflow: hidden;

          width: 198px;
          height: 24px;
          margin-bottom: 0;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #222;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .safe-item-chain-icon {
          position: absolute;
          top: -0.5px;
          left: -0.5px;

          width: 28px;
          height: 28px;
        }
      }

      .safe-item-address-box {
        display: flex;
        align-items: center;
        padding-left: 32px;

        .safe-item-address {
          height: 20px;

          font-family: Ubuntu;
          font-size: 14px;
          line-height: 20px;
          color: #8590a6;
        }

        .copy-btn {
          cursor: pointer;

          display: block;

          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-left: 12px;

          background: url('~@/assets/icons/ic-copy.png') no-repeat;
          background-size: cover;
        }

        .debank-btn {
          cursor: pointer;

          display: block;

          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-left: 12px;

          background: url('~@/assets/icons/png_debank.png') no-repeat;
          background-size: cover;
        }

        .es-icon {
          cursor: pointer;
          margin-left: 12px;

          &-content {
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-right: 0;
          }
        }

        .gs-icon {
          cursor: pointer;

          display: block;

          width: 16px;
          min-width: 16px;
          height: 16px;
          margin-left: 12px;

          background: url('~@/assets/icons/ic_gnosis_safe.png') no-repeat;
          background-size: cover;
        }
      }
    }

    .safe-item-balance {
      padding: 12px 32px;

      &-number {
        height: 28px;
        margin-bottom: 4px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #222;
      }

      &-text {
        height: 20px;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #8590a6;
      }
    }
  }
}

.safe-selected {
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 89px;
  height: 24px;
  padding-left: 8px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #26c165;

  background-color: rgb(232 255 241 / 100%);
  border: 2px solid #26c165;
  border-radius: 8px;

  .safe-selected-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 4px;

    background: url('~@/assets/icons/success.png') no-repeat;
    background-size: cover;
  }
}
