.wallet-container {
  height: 44px;

  .wallet-connect-button {
    padding: 10px 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
  }

  .wallet-wrapper {
    cursor: pointer;

    display: flex;
    flex-direction: row;

    padding: 4px 20px 4px 12px;

    background: #fff;
    border: 2px solid #e7ebf2;
    border-radius: 12px;

    .wallet-icon {
      position: relative;

      width: 32px;
      height: 32px;

      background-image: url('~@/assets/icons/ic_metamask.png');
      background-repeat: no-repeat;
      background-size: 32px 32px;

      .active {
        position: absolute;
        right: 0;
        bottom: 2px;

        width: 8px;
        height: 8px;

        background: #01b393;
        border: 2px solid #fff;
        border-radius: 4px;
      }

      &.metamask {
        background-image: url('~@/assets/icons/ic_metamask.png');
      }

      &.rabby {
        background-image: url('~@/assets/icons/ic_rabby.png');

        & .active {
          border: none;
        }
      }
    }

    .wallet-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 32px;
      margin-left: 8px;

      .wallet-name {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        color: #222;
      }

      .wallet-address {
        font-family: Ubuntu;
        font-size: 12px;
        line-height: 16px;
        color: #8590a6;
      }
    }
  }
}

.wallet-dropdown {
  :global {
    .ant-dropdown-menu {
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%);

      .ant-dropdown-menu-item {
        padding: 0;
        background: #fff;
      }
    }
  }
}

.wallet-disconnect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 36px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #242d4c;
}

.wallet-disconnect:hover {
  color: #4d84ff;
}
