.members {
  display: flex;
  flex-wrap: wrap;
}

.member {
  margin-right: 12px;
  padding: 0 8px;
  background: rgb(133 144 166 / 7%);
  border-radius: 4px;

  .member-text {
    overflow: hidden;

    max-width: 100px;

    font-family: Ubuntu;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.address-box {
  display: flex;
  flex-wrap: wrap;
}

.address {
  display: block;

  margin-bottom: 6px;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #222;
}

.label {
  display: block;

  height: 20px;
  padding: 0 12px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;

  background: #a36b2b;
  border-radius: 12px;
}

.safe-sub-title {
  height: 20px;
  margin-top: 8px;
  margin-bottom: 24px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8994ab;
}

.view-safe-role {
  :global {
    .ant-modal-content .ant-modal-body {
      padding: 0 32px 32px;
    }
  }
}
