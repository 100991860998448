.modal-body {
  width: 360px;

  .modal-btn-wrapper {
    .modal-btn {
      width: 100%;
      height: 48px;
      padding-top: 12px;
      padding-bottom: 12px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
  }
}

.modal-btn-wrapper {
  flex: 1;

  &:not(&:first-child) {
    margin-left: 32px;
  }
}

.modal-btn-single-wrapper {
  width: 100%;
}

.button-section {
  display: flex;
  justify-content: center;
  padding: 32px 32px 24px;
}

.modal-wrapper {
  :global {
    :where(.css-dev-only-do-not-override-2i2tap).ant-modal .ant-modal-content {
      pointer-events: auto;

      position: relative;

      padding: 0;

      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 8px;
    }

    .ant-modal-centered .ant-modal {
      border-radius: 20px;
    }

    .ant-modal-content {
      font-size: 14px;
      border-radius: 20px;

      .ant-modal-header {
        padding: 32px 32px 0;
        border-bottom: none;
        border-radius: 20px;

        .ant-modal-title {
          height: 32px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          color: #222;
          text-align: left;
        }
      }

      .ant-modal-body {
        width: 100%;
        padding: 32px 32px 0;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
        word-break: break-word;
      }

      .ant-modal-footer {
        padding: 0;
        border-top: none;
      }
    }
  }
}

.modal-close-icon {
  width: 32px;
  border: 2px solid #e7ebf2;
  border-radius: 16px;
}

:global {
  .ant-modal .ant-modal-close {
    cursor: pointer;

    top: 32px;
    right: 32px;

    width: 32px;
    height: 32px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }
  }
}
