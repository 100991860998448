.operation-wrapper {
  .operation-item {
    margin-bottom: 16px;
  }

  .operation-item-last {
    margin-bottom: 0;
  }

  .radio-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .radio-wrapper-name {
    line-height: 24px !important;
  }

  .radio-span {
    display: flex;
    align-items: center;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .disabled-span {
    color: #8590a6;
  }

  .amount-radio + .amount-radio {
    margin-left: 32px;
  }

  .slippage-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .recommend-slippage {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      min-width: 52px;
      height: 32px;
      padding: 4px 8px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      color: #8590a6;

      border: 1.5px solid #e7ebf2;
      border-radius: 12px;

      &.selected {
        color: #4d84ff;
        border: 1.5px solid #4d84ff;
      }
    }

    .recommend-slippage + .recommend-slippage {
      margin-left: 8px;
    }

    .slippage-input {
      width: 112px;
      height: 32px;
      margin-left: 8px;
      padding: 4px 8px 4px 12px !important;

      &.slippage-input-focus {
        border-color: #4d84ff !important;
      }

      :global {
        .ant-input {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}

.tip-icon {
  cursor: pointer;

  width: 16px;
  height: 16px;
  margin-left: 6px;

  vertical-align: middle;

  background: url('~@/assets/images/tips.png') no-repeat;
  background-size: 16px 16px;
}
