.wrapper {
  padding-right: 32px;
  padding-bottom: 40px;

  font-family: Ubuntu-Bold, Ubuntu;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #222;
}
