.qr-code-modal {
  width: 100%;

  .qr-code {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }

  .qr-code-content {
    padding-bottom: 32px;
  }

  .qr-code-address {
    display: flex;
    flex-wrap: nowrap;
    place-content: center space-between;
    align-items: center;

    width: 552px;
    height: 48px;
    margin: 32px auto 0;
    padding: 0 24px;

    background: #f9f9f9;
    border: 2px solid #e7ebf2;
    border-radius: 12px;
  }

  .qr-code-text {
    display: block;

    height: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .copy-btn {
    cursor: pointer;

    display: block;

    width: 20px;
    min-width: 20px;
    height: 20px;

    background: url('~@/assets/icons/ic_copy.png') no-repeat;
    background-size: cover;
  }
}
