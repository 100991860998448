.lamp-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 10px 12px;

  background: #fdf7ed;
  border-radius: 12px;

  .lamp {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_lamp.png') no-repeat;
    background-size: cover;
  }

  .note-text {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #a36b2b;
  }
}
