.add-address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .add-address {
    cursor: pointer;
    font-weight: bold;
    color: #4d84ff;
  }
}

.radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-wrapper-name {
  line-height: 24px !important;
}

.radio-span {
  display: flex;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222;
}

.disabled-span {
  color: #8590a6;
}

.amount-radio + .amount-radio {
  margin-left: 32px;
}

.operation-item + .operation-item {
  margin-top: 16px;
}

.tip-icon {
  cursor: pointer;

  width: 16px;
  height: 16px;
  margin-left: 6px;

  vertical-align: middle;

  background: url('~@/assets/images/tips.png') no-repeat;
  background-size: 16px 16px;
}
