.cobo-radio-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .cobo-radio-img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-size: 16px 16px;

    &.checked {
      background-image: url('~@/assets/icons/ic_radio_on.png');
    }

    &.unchecked {
      background-image: url('~@/assets/icons/ic_radio_off.png');
    }
  }
}
