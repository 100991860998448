.view-address-page {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 8px;

    .add-permission-btn {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.permission-modal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-top: 0;
      }
    }
  }

  .subtitle {
    margin-top: 4px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8994ab;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 8px;
  margin-bottom: 12px;

  .add-permission-btn {
    width: 178px;
    height: 40px;
  }
}

.action-btn {
  cursor: pointer;

  width: 80px;
  height: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #e74e5a;
}

.address-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.address-label {
  margin-top: 12px;
  padding: 2px 12px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;

  background: #a36b2b;
  border-radius: 12px;
}

.address-label-ellipsis {
  cursor: pointer;

  overflow: hidden;
  display: inline-block;

  max-width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.safe-role-bot {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #65c97a;

  .bot-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_role_bot.png') no-repeat;
    background-size: 16px 16px;
  }
}

.member {
  min-width: 60px;
}
