.manually-exe-item {
  background-color: #f1f5ff;
  transition: background-color;
}

.bot-detail-modal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-top: 16px;
      }
    }
  }

  .info-box {
    display: block;

    height: 76px;
    margin: 16px 0;
    padding: 12px 16px;

    background: #f9f9f9;
    border: 1px solid #edf2f9;
    border-radius: 12px;

    .info-item {
      display: flex;
      align-items: center;

      .info-item-value {
        &.text-success {
          color: #65c97a;
        }

        &.text-warning {
          color: #f5a623;
        }

        &.text-failed {
          color: #e74e5a;
        }
      }
    }

    .info-box-top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      width: 100%;
      height: 24px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      .info-item {
        margin-right: 32px;
      }
    }

    .info-box-bottom {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      width: 100%;
      height: 20px;
      margin-top: 8px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;

      .info-item {
        margin-right: 24px;
      }
    }
  }

  .bot-detail-modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bot-icon {
      width: 32px;
      height: 32px;
      background: url('~@/assets/images/png_robot.png') no-repeat;
      background-size: 32px 32px;
    }

    .bot-name {
      margin-right: 12px;
      margin-left: 8px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: #22233f;
    }

    .bot-status {
      padding: 6px 12px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;

      border-radius: 16px;

      &.running {
        background: #65c97a;
      }

      &.terminate {
        background: #e74e5a;
      }

      &.paused {
        background: #f5a623;
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;

    .safe-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .safe-name {
        margin-left: 8px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }
    }

    .status {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &.success {
        color: #65c97a;
      }

      &.failed {
        color: #e74e5a;
      }
    }

    .details {
      display: flex;
      flex-direction: row;

      .link-icon-wrapper {
        cursor: pointer;

        .gnosis-icon {
          display: inline-block;

          width: 20px;
          min-width: 20px;
          height: 20px;

          vertical-align: sub;

          background: url('@/assets/icons/ic_gnosis_safe.png') no-repeat;
          background-size: cover;
        }
      }

      .link-icon-wrapper + .link-icon-wrapper {
        margin-left: 12px;
      }
    }

    .value-wrapper {
      display: flex;
      flex-direction: column;

      .value-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .token-icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        .value-text {
          font-family: Ubuntu;
          font-size: 14px;
          line-height: 20px;
          color: #222;
        }

        .value-in {
          color: #65c97a;
        }
      }

      .value-item + .value-item {
        margin-top: 8px;
      }
    }

    .pag-wrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 32px;

      .action-btn {
        flex: 1;
      }

      .action-btn-inner {
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 12px;
      }

      .paused {
        color: #fff;
        background: #e86e2c;
      }

      .terminate {
        color: #fff;
        background: #e74e5a;
      }

      .edit {
        color: #4d84ff;
        background: #fff;
        border: 2px solid #4d84ff;
        border-radius: 12px;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;

        .tip-icon {
          cursor: pointer;

          width: 16px;
          height: 16px;

          vertical-align: middle;

          background: url('~@/assets/icons/ic_info.png') no-repeat;
          background-size: 16px 16px;
        }
      }
    }
  }
}
