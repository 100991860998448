.table-approval-container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 40px;

  .table-approval-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 8px;

    .notice {
      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }

  .table {
    margin-top: 24px;
  }
}
