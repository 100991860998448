.add-address-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   justify-content: center;
  margin-bottom: 0;

  .add-address {
    cursor: pointer;
    font-weight: bold;
    color: #4d84ff;
  }
}

.view-permission-page {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 8px;

    .add-permission-btn {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  padding-bottom: 32px;

  .action-btn {
    flex: 1;
  }

  .action-btn + .action-btn {
    margin-left: 32px;
  }
}
