.select-safe {
  display: block;
  max-height: 80vh;
  padding-top: 20px;

  .select-safe-content {
    overflow-y: scroll;
    max-height: 40vh;
  }

  .safe-item {
    width: 580px;
    margin-left: 12px;
    border: 2px solid #e7ebf2;
    border-radius: 12px;
  }

  .safe-item-header {
    display: flex;
    align-content: center;
    align-items: center;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;

    .chain-icon {
      margin-right: 8px;
    }
  }

  .safe-item-title {
    height: 20px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }

  .safe-item-content-blue {
    margin-bottom: 16px;
    padding: 12px 16px;
    background: #edf2f9;
    border-radius: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .safe-owner {
      height: 24px;
      margin-bottom: 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .arrow {
    width: 16px;
    height: 16px;
    background: url('~@/assets/icons/drop_down.png') no-repeat;
    background-size: 16px 16px;

    &.arrow-active {
      top: 20px !important;
      transform: rotate(180deg) !important;
    }
  }

  :global {
    .ant-checkbox + span {
      padding-right: 0;
      padding-left: 0;
    }

    .ant-collapse-expand-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
    }

    .ant-checkbox-group-item {
      display: flex;
      align-content: center;
      align-items: center;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding-top: 0;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      align-items: center;
    }
  }

  .select-safe-not-loaded {
    cursor: pointer;

    display: flex;
    place-content: center center;
    align-items: center;

    width: calc(100% + 64px);
    height: 56px;
    margin-left: -32px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 56px;
    color: #4d84ff;

    box-shadow: 0 2px 0 0 #e7ebf2;

    .select-safe-not-loaded-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;

      background: url('~@/assets/icons/refresh_blue.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  .safe-item-content-loading {
    position: relative;

    display: flex;
    place-content: center center;
    align-items: center;

    width: 100%;
    height: 40px;
  }
}
