.strategies {
  position: relative;

  .load-more-btn button {
    cursor: pointer;

    width: 128px;
    height: 40px;
    margin: 32px auto 30px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    color: #4d84ff;
    text-align: center;

    background: rgb(77 132 255 / 10%);
    border-radius: 12px;
  }
}
