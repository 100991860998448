.verify-body {
  position: relative;
  margin: 0 auto;

  .sign-up-image {
    width: 96px;
    height: 96px;
    margin: 0 auto;

    background: url('~@/assets/images/email.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sign-up-title {
    user-select: none;

    margin-top: 16px;
    margin-bottom: 12px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    color: #222;
    text-align: center;
  }

  .sign-up-subtitle {
    user-select: none;

    margin-bottom: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .sign-up-button {
    display: block;

    width: 480px;
    height: 56px;
    margin: 0 auto;
    padding: 16px 0;

    background: #4d84ff;
    border-radius: 12px;

    span {
      height: 24px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #fff;
    }
  }

  .sign-up-back {
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;

    height: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
    vertical-align: middle;

    .back-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }

  .sign-up-input-box {
    overflow: hidden;

    .sign-up-input {
      height: 48px;
      padding-left: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
      letter-spacing: 0.96px;

      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
      caret-color: #4d84ff;
    }

    .sign-up-input-error {
      user-select: none;

      display: inline-block;

      height: 16px;
      margin-top: 8px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: #e74e5a;
    }

    .sign-up-input-info {
      cursor: pointer;

      float: right;

      height: 24px;
      margin-top: 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #4d84ff;
    }
  }

  .sign-up-resend {
    cursor: pointer;
    height: 24px;

    span {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
    }

    .wait-button {
      color: #8590a6;
      letter-spacing: 0;
    }

    .resend-button {
      color: #4d84ff;
      letter-spacing: 0;
    }
  }

  :global {
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      z-index: 1;
      box-shadow: none;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #40a9ff;
      outline: 0;
      box-shadow: none;
    }

    .ant-btn-primary[disabled] {
      opacity: 0.3;
      background: #4d84ff;
    }

    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input,
    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input:hover {
      background: #fff;
      border-color: #e74e5a;
    }

    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input:focus,
    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input-focused {
      border-color: #e74e5a;
      border-right-width: 2px;
      outline: 0;
      box-shadow: none;
    }

    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled,
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper-focused {
      border-color: #e74e5a;
      border-right-width: 2px;
      outline: 0;
      box-shadow: none;
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: rgb(0 0 0 / 25%);
      text-shadow: none;

      opacity: 0.3;
      background: #4d84ff;
      box-shadow: none;
    }
  }
}

.fail-remind {
  padding: 12px 16px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #a36b2b;

  background: #fdf7ed;
  border-radius: 12px;
}
