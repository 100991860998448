.collapse-wrapper {
  margin-bottom: 16px;

  :global {
    .ant-collapse {
      background: #f0f7fe;
      border-radius: 12px;

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-header-text {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #000;
        }

        .ant-collapse-expand-icon {
          display: none;
        }
      }

      .ant-collapse-item {
        .ant-collapse-content {
          background: #f0f7fe;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          .ant-collapse-content-box {
            padding: 16px 0 8px;

            font-family: Ubuntu;
            font-size: 12px;
            line-height: 20px;
            color: #222;
          }
        }
      }
    }
  }
}
