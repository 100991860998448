.img-box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 42px;
  height: 42px;

  img {
    width: 24px;
    height: 24px;
  }

  .img-one {
    width: 32px;
    height: 32px;
  }

  .img-two {
    &:first-of-type {
      z-index: 100;
      margin-right: -6px;
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-three-last {
      margin-top: -6px;
    }
  }

  .row {
    display: flex;

    .img-three {
      z-index: 50;

      &:first-of-type {
        z-index: 100;
        margin-right: -6px;
      }
    }

    .img-four {
      z-index: 90;

      &:first-of-type {
        z-index: 100;
        margin-right: -6px;
      }
    }
  }

  .row-two {
    margin-top: -6px;

    .img-four {
      z-index: 80;

      &:first-of-type {
        z-index: 50;
        margin-right: -6px;
      }
    }
  }
}
