.auth-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .auth-home-body {
    position: relative;
    margin-top: 64px;

    .auth-home-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 40px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #222;
    }

    .auth-home-label {
      margin-bottom: 24px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .auth-home-logo-box {
    display: flex;
    justify-content: center;

    height: 64px;
    margin-top: 120px;
    margin-bottom: 56px;

    .auth-home-logo {
      width: 386px;
      height: 66px;
      background: url('~@/assets/icons/logo_cobo.png') no-repeat;
      background-size: 386px 66px;
    }
  }

  .auth-home-title {
    width: 100%;
    height: 56px;
    margin-bottom: 64px;

    font-family: Ubuntu-Bold;
    font-size: 48px;
    font-weight: bolder;
    line-height: 56px;
    color: #222;
    text-align: center;
  }

  .auth-home-btn {
    display: block;

    width: 480px;
    height: 56px;
    margin: 0 auto;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #fff;

    background: #4d84ff;
    border-radius: 12px;
  }

  .auth-home-text {
    height: 24px;
    margin-top: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;

    .sign-up-button {
      padding: 0 0 0 10px;
      color: #4d84ff;

      span {
        font-weight: bold;
      }
    }

    :global {
      .ant-btn-text:active,
      .ant-btn-text:hover {
        color: #4d84ff;
        background: transparent;
        border-color: transparent;
      }
    }
  }

  .email-btn-wrapper {
    height: 56px;
    background: #fff;
    border: 2px solid #4d84ff;
    border-radius: 12px;

    &:hover,
    &:focus {
      background: #fff;
      border-color: #4d84ff;
    }

    .email-btn-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      color: #4d84ff;
    }
  }
}
