.wrapper {
  display: flex;
  flex-direction: row;

  .inner-wrapper {
    flex: 1;
  }
}

.text {
  display: flex;
  align-items: center;
}

.tip-icon {
  cursor: pointer;

  width: 16px;
  height: 16px;
  margin-left: 6px;

  vertical-align: middle;

  background: url('~@/assets/images/tips.png') no-repeat;
  background-size: 16px 16px;
}
