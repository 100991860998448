.import-safe {
  display: block;

  .import-safe-sub-title {
    margin-bottom: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .btns-box .cancel-btn-warp {
    display: flex;
    justify-content: center;
    text-align: center;

    .cancel-btn {
      width: 292px !important;
    }
  }
}

.btns-box {
  display: flex;
  justify-content: center;
  padding: 24px 0 32px;

  .modal-btn-wrapper {
    .modal-btn {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
  }

  .cancel-btn {
    height: 48px;

    color: #8590a6;
    text-shadow: none;

    background-color: #fff;
    border: 2px solid #e7ebf2;
    border-radius: 12px;
    box-shadow: none;

    &:focus,
    &:hover {
      color: #8590a6;
      background-color: #fff;
      border: 2px solid #e7ebf2;
      box-shadow: none;
    }

    &[disabled] {
      color: #8590a6;
      background-color: #fff;
      border: 2px solid #e7ebf2;
      box-shadow: none;
    }

    &[disabled]:focus,
    &[disabled]:hover {
      color: #8590a6;
      background-color: #fff;
      border: 2px solid #e7ebf2;
      box-shadow: none;
    }
  }
}

.modal-btn-wrapper {
  flex: 1;

  &:not(&:first-child) {
    margin-left: 32px;
  }
}

.single-btn-box .modal-btn-wrapper {
  width: 100%;
}

.loading-safe {
  position: relative;
  display: block;

  .loading-box {
    position: relative;
    display: block;
    width: 100%;
    height: 64px;
  }
}
