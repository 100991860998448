.gas-account-item {
  background: #fff;

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;

    &-left {
      .chain-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .balance-number {
        display: flex;
        align-items: center;

        height: 24px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #222;

        &.low-gas-number {
          color: #e74e5a;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      height: 24px;

      .token-name {
        height: 20px;
        margin-left: 8px;

        font-family: Ubuntu;
        font-size: 12px;
        line-height: 20px;
        color: #8590a6;
      }
    }
  }

  .chain-name {
    display: block;

    height: 20px;
    margin-top: 2px;

    font-family: Ubuntu;
    font-size: 12px;
    line-height: 20px;
    color: #8590a6;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .safe-address {
      height: 20px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #262626;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .copy-btn {
        cursor: pointer;

        display: block;

        width: 20px;
        min-width: 20px;
        height: 20px;

        background: url('~@/assets/icons/ic_copy.png') no-repeat;
        background-size: cover;
      }

      .es-icon {
        cursor: pointer;
        margin-left: 16px;
      }
    }
  }

  .browser-icon {
    margin-right: 0;
  }
}

.gas-account-bottom {
  cursor: pointer;

  display: flex;
  place-content: center center;
  align-items: center;

  height: 48px;
  margin-top: 20px;

  font-family: Ubuntu-Bold, Ubuntu;
  font-size: 16px;
  font-weight: bold;
  line-height: 48px;
  color: #4d84ff;

  border: 2px solid #4d84ff;
  border-radius: 12px;
}

.authorization {
  width: 100%;

  &-name {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    width: 100%;
    height: 56px;
    margin-bottom: 0;
    padding-left: 24px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow:
      inset 0 -1px 0 0 #edf2f9,
      inset 0 1px 0 0 #edf2f9;
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 6px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }

  .authorization-content {
    padding: 24px 24px 32px;
  }
}
