.transaction-page {
  position: relative;
  padding: 24px 32px 32px;

  .tip-text {
    display: flex;
    align-items: center;
  }

  .tip-icon {
    cursor: pointer;

    display: block;

    width: 16px;
    height: 16px;
    margin-left: 8px;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }
}
