.strategy-description {
  width: 100%;

  &-rule-item {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    &-content {
      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }

  &-title {
    width: 100%;
    height: 56px;
    padding-left: 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
    color: #222;

    border-bottom: 1px solid #edf2f9;
  }

  &-content {
    padding: 24px 32px;
  }
}
