.content {
  position: relative;

  flex: 1;

  min-width: 548px;
  max-width: 720px;
  height: 172px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 56px;
    padding: 0 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    color: #222;

    box-shadow: 0 -1px 0 0 #edf2f9 inset;

    .icon {
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:last-of-type {
        margin-left: 24px;
      }
    }
  }

  .safe-info {
    padding: 16px 24px;

    .safe-name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .safe-name {
        display: flex;
        align-items: center;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
        color: #222;
      }

      .chain-icon-inner {
        width: 24px;
        height: 24px;
      }

      .module-label {
        display: flex;
        align-items: center;

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 16px;
      }
    }

    .unable-module {
      display: flex;
      align-items: center;
      color: #ffab2b !important;

      .icon {
        display: block;

        width: 14px;
        height: 14px;
        margin-right: 4px;

        background: url('~@/assets/icons/ic_module_unable.png') no-repeat;
        background-size: 14px 14px;
      }
    }

    .no-module {
      display: flex;
      align-items: center;
      color: #8590a6 !important;

      .icon {
        display: block;

        width: 14px;
        height: 14px;
        margin-right: 4px;

        background: url('~@/assets/icons/ic_warning_1@2x.png') no-repeat;
        background-size: 14px 14px;
      }
    }

    .address-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .address {
        display: flex;
        flex: 1;
        align-items: center;

        margin-right: 5px;

        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 16px;
        color: #8590a6;
      }

      .address-label {
        display: block;

        min-width: 86px;

        font-family: Ubuntu;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        color: #8590a6;
      }
    }
  }

  .safe-balance {
    display: flex;
    align-items: center;

    .number {
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 24px;
      color: #222;
      text-align: right;
    }

    .total-balance {
      margin-right: 8px;

      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;
      color: #8590a6;
      text-align: right;
    }
  }

  .module {
    position: relative;

    display: flex;
    flex: 1;
    align-items: center;

    margin-top: 12px;

    .module-label {
      min-width: 86px;

      font-family: Ubuntu;
      font-size: 12px;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
    }

    .module-content {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
    }

    .module-info {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;

      .actions {
        min-width: 100px;
        margin-left: 5px;
      }
    }
  }
}

.safe-status-upgrade {
  cursor: pointer;

  width: 72px;
  height: 24px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  text-align: center;

  background: linear-gradient(270deg, #794ce8 0%, #e74caf 100%);
  border-radius: 8px;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;

    background-repeat: no-repeat;
    background-size: 16px;

    &.copy {
      background-image: url('~@/assets/icons/ic-copy.png');
    }

    &.debank {
      background-image: url('~@/assets/icons/png_debank.png');
    }

    &.explorer {
      position: relative;

      .browser-icon {
        width: 16px;
        height: 16px;
        margin-right: 0;
      }
    }

    &.gnosis-safe {
      background-image: url('~@/assets/icons/ic_gnosis_safe.png');
    }

    &.delete {
      background-image: url('~@/assets/icons/ic_delete.png');
    }

    &.rabby {
      background-image: url('~@/assets/icons/png-Rabby.png');
    }
  }

  .action-icon + .action-icon {
    margin-left: 12px;
  }
}
