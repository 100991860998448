.organization-table {
  width: 100%;
  margin-top: 10px;

  .organization-table-text {
    cursor: pointer;

    height: 20px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #222;
  }

  .organization-table-text-green {
    cursor: pointer;

    height: 20px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #65c97a;
  }

  .org-table-action {
    display: inline-block;
    width: 20px;
    margin-left: 17px;
  }

  :global {
    th::before {
      width: 0 !important;
    }
  }
}
