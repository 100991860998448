.option-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  margin-right: 16px;

  .option-address {
    overflow: hidden;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    // color: #222222;
    line-height: 24px;
    text-overflow: ellipsis;
  }

  .option-tags {
    float: right;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;

    .option-tag {
      padding: 2px 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;

      border-radius: 12px;
    }

    .option-tag + .option-tag {
      margin-right: 8px;
    }

    .option-label {
      color: #fff;
      background: #a36b2b;
    }

    .option-whitelist {
      color: #643de4;
      background: #f2effe;
    }

    .option-gnosissafe {
      color: #3b8570;
      background: rgb(59 133 112 / 10%);
    }
  }

  .option-address-info {
    display: flex;
    flex-direction: column;

    .option-name {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .option-name-disabled {
      color: #8590a6;
    }

    .option-address {
      margin-top: 4px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: rgb(133 144 166 / 85%);
    }
  }
}
