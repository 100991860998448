@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(360deg);

  width: 64px;
  height: 64px;
  margin: -32px;

  background: url('~@/assets/icons/ic_loading.png') no-repeat;
  background-size: 64px;

  animation: rotation 1.4s linear infinite;
}

.loadingIcon-dark-small {
  transform: rotate(360deg);

  width: 16px;
  height: 16px;

  background: url('~@/assets/icons/ic-loading.png') no-repeat;
  background-size: 16px;

  animation: rotation 1.4s linear infinite;
}
