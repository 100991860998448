.permission-modal {
  transition: visibility 0.5s;

  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-top: 0;
      }
    }
  }

  .subtitle {
    margin-top: 4px;
    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8994ab;
  }
}

.item-wrapper {
  display: flex;
  flex-direction: column;

  .name {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 8px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
  }
}

.item-wrapper + .item-wrapper {
  margin-top: 32px;
}

.actions {
  margin-top: 24px;
}

.switch-contract-type-label {
  margin-top: 8px;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #8590a6;

  span {
    cursor: pointer;
    color: #4d84ff;
  }
}

.contract-detail-wrapper {
  display: flex;
  flex-direction: column;

  padding: 16px 24px 0;

  background: #f0f7fe;
  border-radius: 8px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #000;
    }

    .contract-name {
      cursor: pointer;

      margin-left: 24px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #4d84ff;
    }
  }

  .content {
    overflow-y: scroll;
    max-height: 336px;
    margin-top: 16px;
    padding-bottom: 24px;

    .subtitle {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #000;
    }

    .abi-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      :global {
        .ant-checkbox-wrapper {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;

          .ant-checkbox {
            top: 0;
            background: #fff;
          }

          .ant-checkbox + span {
            flex: 1;
            padding-left: 12px;
          }
        }
      }

      .abi-item-inner {
        display: flex;
        flex-direction: column;

        padding: 12px 16px;

        background: #edf2f9;
        border: 2px solid #e7ebf2;
        border-radius: 12px;

        .signature {
          font-family: Ubuntu;
          font-size: 14px;
          line-height: 20px;
          color: #222;
        }

        .selector {
          font-family: Ubuntu;
          font-size: 12px;
          line-height: 20px;
          color: #8994ab;
        }
      }
    }

    .abi-item + .abi-item {
      margin-top: 12px;
    }
  }
}

.contract-preview-wrapper {
  display: flex;
  flex-direction: row;
  place-content: center space-between;
  align-items: center;

  margin-top: 20px;

  .edit {
    background: url('~@/assets/images/ic_edit_default@2x.png') no-repeat;
  }

  .delete {
    background: url('~@/assets/images/ic_remove_default@2x.png') no-repeat;
  }

  .edit,
  .delete {
    cursor: pointer;
    // color: #4d84ff;
    width: 20px;
    height: 20px;
    background-size: cover;
  }

  .contract-collapse {
    width: 530px;

    :global {
      .ant-collapse {
        background-color: #fff;
        border: 2px solid #e7ebf2;
        border-radius: 12px;

        .ant-collapse-item-active {
          .ant-collapse-header {
            border-bottom: 2px solid #e7ebf2;
          }
        }

        .ant-collapse-content {
          background-color: #fff;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;

    .contract {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #000;
    }

    .contract-address {
      margin-left: 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #8590a6;
    }
  }

  .function-wrapper {
    display: flex;
    flex-direction: column;

    .function {
      margin-top: 15px;
      margin-bottom: 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #000;
    }

    .function-item {
      padding: 12px 16px;
      background: #edf2f9;
      border-radius: 12px;

      .signature {
        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #222;
      }

      .selector {
        font-family: Ubuntu;
        font-size: 12px;
        line-height: 20px;
        color: #8994ab;
      }
    }

    .function-item + .function-item {
      margin-top: 8px;
    }
  }
}

.tip-icon {
  cursor: pointer;

  width: 16px;
  height: 16px;
  margin-left: 6px;

  vertical-align: middle;

  background: url('~@/assets/images/tips.png') no-repeat;
  background-size: 16px 16px;
}
