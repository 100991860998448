.strategy-mart {
  position: relative;
  display: flex;
  width: 100%;

  .strategy-tabs {
    width: 100%;
    min-width: 1200px;
  }

  .fire-icon {
    display: block;

    width: 30px;
    height: 30px;
    margin-left: 8px;

    background: url('~@/assets/icons/strategy/campfire.png') no-repeat;
    background-size: 30px 30px;
  }

  .tab-title {
    display: flex;
    flex-wrap: nowrap;
    place-content: center center;
    align-items: center;
  }
}
