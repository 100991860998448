.userinfo-container {
  min-width: 176px;
}

.user-info-wrapper {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 14px 12px;

  background: #fff;
  border: 1px solid #e7ebf2;
  border-radius: 12px;

  .user-avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_user_default.png') no-repeat;
    background-size: 24px 24px;
  }

  .user-info-content {
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;

    text-overflow: ellipsis;
    white-space: nowrap;

    .user-info-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding-bottom: 4px;

      .user-info-name-label {
        overflow: hidden;

        max-width: 90%;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        color: #222;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .user-info-email {
      overflow: hidden;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 12px;
      color: #8590a6;
      text-overflow: ellipsis;
    }
  }

  .arrow {
    transform: rotate(0);
    width: 12px;
    height: 12px;
  }

  .arrow-down {
    transform: rotate(180deg);
    color: #4d84ff;
  }
}

.user-info-actions {
  display: flex;
  flex-direction: column;
  height: 112px;
  min-height: 112px;
}

.user-info-dropdown {
  :global {
    .ant-dropdown-menu {
      overflow: hidden;
      border-radius: 12px;
      box-shadow: 0 0 18px 0 rgb(10 31 68 / 12%);

      .ant-dropdown-menu-item {
        padding: 0;
        background: #fff;
      }
    }
  }
}

.user-info-action-item {
  cursor: pointer;
  margin: 0;
  padding-left: 16px !important;
  // background: #f3f8ff;

  &::after {
    top: 10px;
    bottom: 10px;
    border-radius: 2px;
  }

  .user-info-action-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;

    .user-info-action-inner-icon {
      width: 20px;
      height: 20px;
    }

    .icon-account {
      background: url('~@/assets/icons/ic_account.png') no-repeat;
      background-size: 20px 20px;
    }

    .icon-org {
      background: url('~@/assets/icons/ic_org.png') no-repeat;
      background-size: 20px 20px;
    }

    .icon-logout {
      background: url('~@/assets/icons/ic_logout.png') no-repeat;
      background-size: 20px 20px;
    }

    .icon-switch {
      background: url('~@/assets/icons/ic-Switch.png') no-repeat;
      background-size: 20px 20px;
    }

    .user-info-action-inner-label {
      margin-left: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242d4c;
    }

    .user-info-action-inner-label:hover {
      color: #4d84ff;
    }
  }
}

.user-info-action-item-selected {
  background: rgb(77 132 255 / 8%) !important;

  ::after {
    content: '';

    position: absolute;
    top: 10px;
    right: 0;

    width: 3px;
    height: 16px;

    background: #4d84ff;
  }
}
