.bot-detail-container {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  .bot-detail-header {
    display: flex;
    flex-direction: column;
  }

  .bot-detail-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    margin-top: 32px;

    .bot-detail-item {
      width: 100%;

      background: #fff;
      border: 1px solid #edf2f9;
      border-radius: 12px;
      box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);
    }

    .bot-detail-left {
      flex: 1;
      margin-right: 32px;
    }

    .bot-detail-right {
      width: 360px;
    }

    .bot-detail-description {
      margin-top: 32px;
    }
  }

  .customized-bots-header {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    .customized-bots-icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;

      background: url('~@/assets/images/png_robot.png') no-repeat;
      background-size: 32px 32px;
    }

    .customized-bots-name {
      height: 32px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      color: #222;
    }
  }
}
