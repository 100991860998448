.support-container {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 32px;

  .support-split {
    width: 2px;
    height: 16px;
    background: #e7ebf2;
    border-radius: 1px;
  }

  .support-item {
    cursor: pointer;

    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-start;
    align-items: center;

    height: 20px;
    padding: 0 16px;

    .support-icon {
      cursor: pointer;

      display: block;

      width: 20px;
      height: 20px;
      margin-right: 8px;

      color: #8590a6;

      background-size: cover;
    }

    .support-icon-support {
      background: url('~@/assets/icons/ic_contact.png') no-repeat;
    }

    .support-icon-guide {
      background: url('~@/assets/icons/ic_guides.png') no-repeat;
    }

    .support-text {
      display: block;

      height: 20px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }

  .support-guide {
    margin-bottom: 4px;
  }
}

.support-popover {
  width: 328px;

  .support-popover-title {
    display: flex;
    place-content: center space-between;
    align-items: center;

    height: 20px;
    margin-bottom: 8px;

    h2 {
      height: 20px;
      margin-bottom: 0;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: #222;
    }

    .support-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: #222;
    }
  }

  .support-popover-content {
    p {
      height: 20px;
      margin: 0;

      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #8590a6;

      .color-blue {
        cursor: pointer;
        color: #4d84ff;
      }
    }
  }
}

:global {
  #support-container .ant-popover {
    .ant-popover-content {
      .ant-popover-inner-content {
        padding: 16px;
      }
    }
  }
}

.support-split {
  width: 2px;
  height: 16px;
  background: #e7ebf2;
  border-radius: 1px;
}

.support-item {
  cursor: pointer;

  display: flex;
  flex-wrap: nowrap;
  place-content: center flex-start;
  align-items: center;

  height: 20px;
  padding: 0 16px;

  .support-icon {
    cursor: pointer;

    display: block;

    width: 20px;
    height: 20px;
    margin-right: 8px;

    color: #8590a6;

    background-size: cover;
  }

  .support-icon-support {
    background: url('~@/assets/icons/ic_contact.png') no-repeat;
  }

  .support-icon-guide {
    background: url('~@/assets/icons/ic_guides.png') no-repeat;
  }

  .support-text {
    display: block;

    height: 20px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

.support-guide {
  margin-bottom: 4px;
}
