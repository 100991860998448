.upgrade-safe {
  .tip-icon {
    flex-shrink: 0;

    width: 16px;
    height: 16px;
    margin-right: 12px;

    background: url('~@/assets/icons/ic_color_warning.png') no-repeat;
    background-size: 16px 16px;
  }

  .upgrade-text {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .safe-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 24px;
    padding: 12px 16px;

    background: #f9f9f9;
    border: 2px solid #e7ebf2;
    border-radius: 12px;

    .name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        overflow: hidden;

        max-width: 148px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #222;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .address {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    &.center {
      align-items: center;
      justify-content: center;
    }
  }

  .multisig-icon {
    width: 96px;
    height: 96px;
    background-image: url('~@/assets/images/png_confirm.png');
    background-size: 96px 96px;
  }

  .multisig-title {
    margin-top: 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    color: #222;
  }

  .multisig-subtitle {
    margin-top: 12px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;

    .multisig-btn {
      cursor: pointer;
      color: #4d84ff;
    }
  }

  :global {
    .ant-modal-footer {
      .ant-btn-loading {
        background: #c2c7d2;
      }
    }
  }
}
