.select-wallet {
  display: flex;
  flex-wrap: nowrap;
  place-content: center center;
  align-items: center;

  padding-bottom: 32px;

  .wallet {
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    place-content: center center;
    align-items: center;

    width: 292px;
    height: 100px;
    margin-right: 32px;

    background: #f9f9f9;
    border-radius: 16px;

    &:hover {
      border: 2px solid #4d84ff;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .wallet-icon {
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 7px;
    }

    .wallet-title {
      flex-basis: 100%;

      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #000;
      text-align: center;
    }

    .metamask {
      background: url('~@/assets/icons/png_metamask.png') no-repeat;
      background-size: 40px 40px;
    }

    .walletConnect {
      background: url('~@/assets/icons/png_walletconnect.png') no-repeat;
      background-size: 40px 40px;
    }
  }
}
