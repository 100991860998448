.verify-safe {
  display: block;

  width: calc(100% + 64px);
  margin-left: -32px;
  padding-bottom: 16px;

  box-shadow: 0 2px 0 0 #e7ebf2;

  .verify-safe-content {
    overflow-y: scroll;
    max-height: 660px;
    padding: 0 32px;
  }

  .safe-item {
    margin-bottom: 12px;
    border: 2px solid #e7ebf2;
    border-radius: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .safe-name {
    .safe-title {
      height: 20px;
      margin-top: 16px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .safe-name-input {
      margin-top: 8px;
    }

    .error-message {
      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;

      margin-top: 8px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #e74e5a;
    }
  }

  .safe-item-header {
    display: flex;
    align-content: center;
    align-items: center;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;

    .chain-icon {
      margin-right: 8px;
    }
  }

  .safe-item-content-blue {
    margin-bottom: 16px;
    padding: 12px 16px;
    background: #edf2f9;
    border-radius: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .safe-module {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 24px;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .module-address {
        height: 24px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #222;
      }

      .module-version {
        height: 24px;
        margin-right: 8px;
        padding: 0 8px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 12px;
        font-weight: bold;
        line-height: 24px;
        color: #fff;

        background: #222;
        border-radius: 6px;
      }

      .module-default {
        height: 20px;
        padding: 0 8px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #a36b2b;

        background: #ecc99d;
        border-radius: 12px;
      }
    }
  }

  .arrow {
    width: 16px;
    height: 16px;
    background: url('~@/assets/icons/drop_down.png') no-repeat;
    background-size: 16px 16px;

    &.arrow-active {
      transform: rotate(180deg) !important;
    }
  }

  :global {
    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding-top: 0;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      display: flex;
      align-items: center;
    }

    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header {
      padding-right: 16px;
    }

    .ant-collapse-expand-icon {
      position: absolute;
      right: 16px;
      bottom: 14px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
    }

    .ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      position: relative;
      top: 0;
      right: 0;
      transform: none;

      margin: 0;
    }
  }

  .safe-item-content-loading {
    position: relative;

    display: flex;
    place-content: center center;
    align-items: center;

    width: 100%;
    height: 40px;
  }
}
