code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Ubuntu;
  src: url('./assets/fonts/Ubuntu-Regular.ttf');
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url('./assets/fonts/Ubuntu-Bold.ttf');
}

@font-face {
  font-family: Ubuntu-BoldItalic;
  src: url('./assets/fonts/Ubuntu-BoldItalic.ttf');
}

@font-face {
  font-family: Ubuntu-Italic;
  src: url('./assets/fonts/Ubuntu-Italic.ttf');
}

@font-face {
  font-family: Ubuntu-Light;
  src: url('./assets/fonts/Ubuntu-Light.ttf');
}

@font-face {
  font-family: Ubuntu-LightItalic;
  src: url('./assets/fonts/Ubuntu-LightItalic.ttf');
}

@font-face {
  font-family: Ubuntu-Medium;
  src: url('./assets/fonts/Ubuntu-Medium.ttf');
}

@font-face {
  font-family: Ubuntu-MediumItalic;
  src: url('./assets/fonts/Ubuntu-MediumItalic.ttf');
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

.ant-select-open .arrow-animation {
  transform: rotate(180deg) !important;
}

.ant-input-affix-wrapper {
  height: 48px;
  padding: 12px 16px;
  border: 1.5px solid #e7ebf2;
  border-radius: 12px;
}

.ant-input {
  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222;

  caret-color: #4d84ff;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 1;
  border-color: #e7ebf2;
  border-right-width: 1.5px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #e7ebf2;
  outline: 0;
  box-shadow: none;
}

.ant-btn-primary[disabled] {
  opacity: 0.3;
  background: #4d84ff;
}

.ant-form-item-explain,
.ant-form-item-extra {
  transition: none 0.3s 0.017s linear;
}

.ant-form-item-with-help .ant-form-item-explain {
  position: absolute;
  transition: none 0.3s 0.017s linear;
}

.ant-input-suffix .ant-input-clear-icon {
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0;
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled,
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper-focused {
  border-right-width: 1.5px;
  box-shadow: none;
}

body {
  margin: 0;
  font-family:
    Ubuntu,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
