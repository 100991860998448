.error-msg {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 8px;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #e74e5a;

  .error-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_error_info.png') no-repeat;
    background-size: 16px 16px;
  }
}
