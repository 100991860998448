.profile {
  display: block;

  .settings-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .username-item,
    .address-item,
    .network-item {
      display: inline-block;
      margin-top: 32px;

      .settings-item-label {
        display: block;

        height: 20px;
        margin-bottom: 8px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #222;
      }
    }
  }

  .network-item {
    width: 240px;
    margin-right: 30px;

    :global {
      .ant-select {
        height: 48px;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 48px;
        border-radius: 12px;
      }
    }
  }

  .username-item {
    width: 480px;
    margin-right: 30px;

    :global {
      .ant-input[disabled] {
        cursor: not-allowed;

        font-family: Ubuntu-Medium, Ubuntu;
        font-weight: 500;

        opacity: 1;
        box-shadow: none;
      }
    }
  }

  .address-item {
    width: 752px;
  }

  :global {
    .ant-input {
      height: 48px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;

      border: 1.5px solid #e7ebf2;
      border-radius: 12px;
      caret-color: #4d84ff;
    }

    .ant-input[disabled] {
      color: #8590a6;
      border-color: #e7ebf2;
    }

    .ant-input[disabled]:hover {
      border-color: #e7ebf2;
      border-right-width: 1.5px;
    }

    .ant-input:hover {
      border-color: #e7ebf2;
      border-right-width: 1.5px;
    }
  }
}
