.settings {
  &-threshold {
    padding: 32px 0;
  }

  &-threshold-content {
    margin-top: 32px;
    font-size: 14px;
    color: #222;
  }
}

.threshold {
  padding-bottom: 32px !important;
}
