.content {
  position: relative;

  min-width: 548px;
  // flex: 1;
  height: 172px;
  margin-left: 24px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 56px;
    padding: 0 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    color: #222;

    box-shadow: 0 -1px 0 0 #edf2f9 inset;
  }

  .info {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 116px;
    padding: 30px 24px;

    .item {
      cursor: pointer;

      .item-number {
        position: relative;

        display: flex;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        line-height: 28px;
        color: #222;

        .running-box {
          position: relative;
          margin-left: 8px;
        }

        .running {
          position: absolute;
          top: -4px;
          left: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          height: 18px;
          padding: 0 8px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 10px;
          font-weight: 500;
          font-style: normal;
          line-height: 12px;
          color: #fff;
          text-align: center;

          background: #00c08b;
          border-radius: 12px 2px;
        }
      }

      .item-title {
        margin-top: 8px;

        font-family: Ubuntu;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 20px;
        color: #8590a6;
      }
    }
  }
}
