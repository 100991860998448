.token-receiver-selector-wrapper {
  display: flex;
  flex-direction: column;

  .selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 24px;

    .name {
      min-width: 160px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 20px;
      color: #222;
    }

    .member-select {
      flex: 1;
      max-width: 720px;
    }
  }
}
