.create-box {
  margin-left: 32px;

  .create-bot-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 208px;
    margin-top: 16px;
    padding-top: 24px;
    padding-bottom: 16px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    .create-bot-icon {
      width: 56px;
      height: 56px;
      background: url('~@/assets/images/png_robot.png') no-repeat;
      background-size: 56px 56px;
    }

    .create-btn {
      width: 144px;
      height: 32px;
      margin-top: 16px;
    }
  }
}
