.steps-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.steps-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  margin-top: 40px;

  font-family: Ubuntu;
  font-size: 14px;
  line-height: 20px;
  color: #8590a6;

  .steps-node-container {
    position: relative;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;

    &.done {
      font-family: Ubuntu-Medium, Ubuntu;
      font-weight: 500;
      color: #65c97a;
    }

    &.current {
      font-family: Ubuntu-Medium, Ubuntu;
      font-weight: 500;
      color: #181c27;
    }

    .steps-node-wrapper {
      position: absolute;
      top: -40px;

      display: flex;
      flex-direction: row;
      align-items: center;

      height: 32px;

      line-height: 32px;
      text-align: center;

      .steps-node {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #181c27;

        border: 2px solid #65c97a;
        border-radius: 16px;

        &.steps-node-done {
          background: url('~@/assets/icons/ic_finish.png') no-repeat;
          background-size: 32px 32px;
          border: none;
        }

        &.steps-node-undone {
          border: 2px solid #e7ebf2;
        }
      }

      .steps-progressbar {
        position: relative;

        height: 4px;
        margin-left: 16px;

        background: #e7ebf2;
        border-radius: 3px;

        .progress {
          position: absolute;
          inset: 0 50% 0 0;

          background: #65c97a;
          border-radius: 3px;

          transition: height 0.5s;
        }
      }
    }
  }
}
