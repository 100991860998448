.empty-view-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  .image {
    width: 160px;
    height: 160px;
    background: url('~@/assets/images/png_empty_search.png') no-repeat;
    background-size: 160px 160px;
  }

  .title {
    margin-top: 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .subtitle {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #8590a6;
  }
}
