.code-box {
  display: flex;
  flex-direction: row;

  &-input {
    box-sizing: border-box;
    width: 72px;
    height: 72px;
    margin-right: 24px;
    padding: 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;

    border: 2px solid #e7ebf2;
    border-radius: 11px;
    outline: none;

    &:active,
    &:focus {
      border-color: #4d84ff;
      caret-color: #4d84ff;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &-input-has-string {
    border-color: #e7ebf2;
  }
}
