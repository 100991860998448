.success-content-container {
  padding-top: 32px;
  border-top: 1px solid #edf2f9;

  .success-img {
    width: 96px;
    height: 96px;
    margin: 0 auto;

    background: url('~@/assets/images/png_congratulations@2x.png') no-repeat;
    background-size: 96px;
  }

  .title {
    width: 100%;
    margin: 20px auto 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;
  }

  .content {
    width: 100%;
    height: 24px;
    margin: 0 auto 32px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .btn {
    width: 240px;
    height: 48px;
    margin: 0 auto;
    border-radius: 12px;

    :global {
      .ant-btn {
        height: 48px;

        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 48px;
        color: #fff;
      }
    }
  }
}
