.leverage-table {
  display: block;
  margin-top: 24px;
  background: #fff;
  border-radius: 12px;

  .leverage-title {
    height: 44px;
    padding-left: 24px;

    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 44px;
    color: #8590a6;

    background: #fbfcfe;
    box-shadow: 0 -1px 0 0 #edf2f9 inset;
  }

  .leverage-table-wrapper {
    padding: 16px 24px;

    .leverage-table-item {
      position: relative;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .leverage-table-item-title {
        display: flex;
        justify-content: flex-start;

        height: 24px;

        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
        color: #222;

        .icon-box {
          position: relative;
          width: 24px;
          height: 24px;
          margin-right: 16px;

          .leverage-table-item-title-icon {
            width: 24px;
            height: 24px;
          }

          .leverage-table-item-sub-icon {
            position: absolute;
            right: -8px;
            bottom: -8px;

            width: 16px;
            height: 16px;
          }
        }
      }

      .leverage-table-item-content {
        display: flex;
        justify-content: space-between;

        width: calc(100% - 40px);
        height: 40px;
        margin-top: 8px;
        margin-left: 40px;
        padding-right: 40px;
        padding-left: 10px;

        background: #f0f7fe;
        border-radius: 8px;

        .chian {
          display: flex;
          flex-shrink: 1;
          align-items: center;

          margin-right: 196px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          line-height: 20px;
          color: #4d84ff;

          .chain-icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }

        .extra {
          display: flex;
          flex: 1;
          flex-grow: 2;
          align-items: center;
          justify-content: space-between;

          .spender {
            display: flex;
            align-items: center;

            .spender-info {
              display: flex;
              align-items: center;
              margin-right: 16px;
            }

            .spender-label {
              margin-right: 8px;

              font-family: Ubuntu-Medium, Ubuntu;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              line-height: 20px;
              color: #222;
            }

            .spender-address {
              margin-right: 8px;

              font-family: Ubuntu-Medium, Ubuntu;
              font-size: 14px;
              font-weight: 500;
              font-style: normal;
              line-height: 20px;
              color: #4d84ff;
            }

            .spender-text {
              font-family: Ubuntu;
              font-size: 14px;
              font-weight: 400;
              font-style: normal;
              line-height: 20px;
              color: #222;
            }
          }

          .allowance {
            font-family: Ubuntu;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            line-height: 20px;
            color: #222;

            .allowance-bold {
              font-weight: 600;
            }
          }
        }
      }

      .leverage-table-item-divider {
        position: absolute;
        bottom: 4px;
        left: 12px;

        width: 2px;
        height: 32px;

        background: #e6f0ff;
      }
    }
  }
}
