.limited-input-wrapper {
  :global {
    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input:focus,
    .ant-input-status-error:not(
        .ant-input-disabled,
        .ant-input-borderless
      ).ant-input-focused {
      border-color: #ff4d4f;
      border-right-width: 2px;
      outline: 0;
      box-shadow: none;
    }

    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):hover {
      z-index: 1;
      border-color: #ff4d4f;
      box-shadow: none;
    }

    .ant-input-affix-wrapper-status-error:focus,
    .ant-input-affix-wrapper-status-error-focused {
      border-color: #ff4d4f;
      outline: 0;
      box-shadow: none;
    }
  }

  .error-message {
    display: flex;
    flex-wrap: nowrap;
    place-content: center flex-start;
    align-items: center;

    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #e74e5a;
  }

  .error-icon {
    display: block;

    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_warning_error.png') no-repeat;
    background-size: 16px 16px;
  }

  .note {
    margin-top: 8px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #8590a6;
  }
}

.boolean-select {
  :global {
    .ant-select-item-option:not(.ant-select-item-option-disabled) {
      color: #222;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      &::after {
        content: '';

        position: absolute;
        top: 16px;
        right: 16px;

        width: 12px;
        height: 12px;

        background: url('~@/assets/icons/ic_right.png') no-repeat;
        background-size: 12px 12px;
      }

      .ant-select-item-option-content {
        color: #4d84ff;
      }
    }
  }
}
