.bot-item {
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px 24px;

  .bot-name-box {
    display: flex;
    place-content: center space-between;
    align-items: center;

    .bot-name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .bot-icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }

    .bot-type {
      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .bot-item-run {
      cursor: pointer;

      width: 24px;
      min-width: 24px;
      height: 24px;

      background: url('~@/assets/icons/ic_bot_run.png') no-repeat;
      background-size: 24px 24px;
    }
  }

  &-content {
    margin-bottom: 16px;
  }

  &-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  &-customize-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    background: url('~@/assets/images/png_robot.png') no-repeat;
    background-size: 20px 20px;
  }

  &-title {
    display: flex;
    place-content: center flex-start;
    align-items: center;
    margin-bottom: 12px;
  }

  &-name {
    overflow: hidden;

    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #222;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bot-data-item {
    display: flex;
    place-content: center space-between;
    align-items: center;
    margin-top: 12px;

    .bot-item-data-label {
      height: 20px;
      font-family: Ubuntu;
      font-size: 14px;
      color: #8590a6;
    }

    .bot-data-content {
      height: 20px;
      font-family: Ubuntu;
      font-size: 14px;
      color: #222;
    }
  }

  .leverage-info {
    margin-top: 8px;
  }

  .bot-item-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bot-item-button {
      display: flex;
      place-content: center center;
      align-items: center;

      width: 148px;
      height: 48px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: bold;
      color: #4d84ff;

      border: 2px solid #4d84ff;
      border-radius: 12px;
    }

    .bot-item-button-split {
      width: 1px;
      height: 16px;
      background: #ecf1f8;
    }
  }
}
