.user-settings-container {
  width: 100%;
  padding-top: 32px;
  padding-right: 32px;
  padding-left: 32px;

  .title {
    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: #222;
  }

  .tab-content-wrapper {
    margin-top: 16px;
    padding-top: 32px;
    padding-bottom: 32px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;

    .inner-form {
      padding-right: 32px;
      padding-bottom: 32px;
      padding-left: 32px;
      border-bottom: 1px solid #edf2f9;

      .inner-form-item {
        max-width: 480px;
      }

      :global {
        .ant-form-item-label {
          padding-bottom: 8px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #222;
        }

        .ant-input-affix-wrapper-disabled {
          background-color: #f9f9f9;
          border-color: #e7ebf2;
          border-width: 2px;

          .ant-input-disabled {
            color: #222;
          }
        }
      }
    }

    .submit-wrapper {
      display: flex;
      flex-direction: row-reverse;

      margin-top: 32px;
      padding-right: 32px;
      padding-left: 32px;

      .submit-button {
        :global {
          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }
      }
    }

    .tab-security-item {
      display: flex;
      flex-direction: column;
      padding-right: 32px;
      padding-left: 32px;

      .item-title {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #222;
      }

      .item-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-top: 8px;

        .item-label {
          font-family: Ubuntu;
          font-size: 14px;
          line-height: 20px;
          color: #222;
        }

        .item-label-right {
          display: flex;
          flex-direction: row;

          .enable-text {
            margin-right: 16px;

            font-family: Ubuntu-Medium, Ubuntu;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #65c97a;
          }
        }
      }
    }
  }
}

.profile-container {
  display: flex;
  flex-direction: column;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow: inset 0 -1px 0 0 #edf2f9;

    .edit-btn {
      width: 82px;
      height: 32px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      line-height: 32px;

      border-radius: 8px;
    }
  }

  .content-wrapper {
    padding: 16px 32px 40px;

    .item-label {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;
    }

    .username-form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      :global {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }

    .subtitle-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .subtitle {
        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #8590a6;
      }
    }

    .accounts {
      :global {
        .ant-input[disabled] {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          line-height: 24px;
          color: #8590a6;

          background: #f9f9f9;
          border: 1.5px solid #e7ebf2;
        }
      }
    }
  }

  .content-wrapper + .title {
    box-shadow:
      inset 0 -1px 0 0 #edf2f9,
      inset 0 1px 0 0 #edf2f9;
  }
}

.security-container {
  display: flex;
  flex-direction: column;
  background: #fff;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 16px 32px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #222;

    background: rgb(248 249 254 / 50%);
    box-shadow: inset 0 -1px 0 0 #edf2f9;

    .tip-icon {
      cursor: pointer;

      width: 16px;
      height: 16px;
      margin-left: 6px;

      vertical-align: middle;

      background: url('~@/assets/images/tips.png') no-repeat;
      background-size: 16px 16px;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 24px 32px 40px;

    .desc-wrapper {
      display: flex;
      flex-direction: column;

      .desc {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 20px;
        font-weight: bold;
        line-height: 32px;
        color: #222;
      }

      .sub-desc {
        margin-top: 8px;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #222;
      }
    }
  }
}

.account-title {
  margin: 24px 0 8px;

  font-family: Ubuntu-Bold, Ubuntu;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 32px;
  color: #222;
}

.tip-icon {
  background: url('~@/assets/images/tips.png') no-repeat !important;
  background-size: 16px 16px !important;
}
