.success-body {
  position: relative;
  width: 480px;
  margin: 0 auto;
  margin-top: 80px;

  .sign-up-title {
    user-select: none;

    height: 40px;
    margin-top: 20px;
    margin-bottom: 16px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #222;
    text-align: center;
  }

  .sign-up-subtitle {
    user-select: none;

    height: 24px;
    margin-bottom: 40px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }

  .sign-up-back {
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;

    height: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
    vertical-align: middle;

    .back-icon {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }
}
