.wrapper {
  display: flex;
  margin-bottom: 8px;
  padding: 0 16px;

  .label-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search-icon {
      width: 16px;
      height: 16px;
      background: url('~@/assets/icons/ic_search_primary.png') no-repeat;
      background-size: 16px 16px;
    }

    .label {
      height: 24px;
      margin-left: 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #4d84ff;
    }
  }
}
