.bundle {
  .bundle-btns {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 24px;
  }

  .remove-btn {
    height: 40px !important;
    border-radius: 10px;
  }

  .submit-btn {
    height: 40px !important;
    border-radius: 10px;
  }

  .nonce-box {
    display: flex;
    align-items: center;

    padding: 16px 24px;

    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    color: #000;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);
  }

  .transaction-number {
    display: flex;
    align-items: center;

    height: 24px;
    margin-left: 12px;
    padding: 0 8px;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;
    color: #fff;
    text-align: center;

    background: #ff5b00;
    border-radius: 6px;
  }

  .transaction-header-text {
    display: flex;
    align-items: center;

    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;
    color: #222;
  }

  .collapse-box {
    :global {
      .ant-collapse {
        background: #fff;
        border: 1px solid #edf2f9;
        box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

        .ant-collapse-header {
          display: flex;
          align-items: center;
          padding: 20px 24px;
          border-bottom: 1px solid #edf2f9;
        }
      }

      .ant-collapse .ant-collapse-content-box {
        padding: 0;
      }

      .ant-collapse .ant-collapse-item .ant-collapse-content {
        background: #fff;
        border: none;
      }

      .ant-collapse-item {
        .ant-collapse-extra {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          line-height: 24px;
          color: #8590a6;

          transition: 0.8s ease all;
        }
      }

      .ant-collapse-item-active {
        .ant-collapse-extra {
          color: #4d84ff;
        }
      }
    }
  }
}

.empty-tx {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding-top: 24px;
  padding-bottom: 32px;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;

  .tx-img {
    width: 160px;
    height: 160px;
    background: url('~@/assets/images/png_transaction.png') no-repeat;
    background-size: cover;
  }

  .empty-label {
    margin-top: 24px;
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
  }
}
