.safe-selector-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .name {
    min-width: 160px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 48px;
    color: #222;
  }

  .selector {
    float: right;
    overflow: hidden;
    flex: 1;
    max-width: 720px;

    .safe-selector {
      :global {
        .ant-select-single .ant-select-selector .ant-select-selection-item {
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;
        }
      }
    }

    .safe-label-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.name-logo {
  display: flex;
  align-items: center;
  margin-right: 8px;

  .safe-chain-icon {
    margin-right: 0;
  }
}

:global {
  .ant-select-item-option-content {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    padding-left: 6px;
  }

  .ant-select-dropdown {
    .ant-select-item {
      padding: 8px 12px;
    }
  }
}

.safe-option-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .safe-option-content {
    overflow: hidden;
    flex: 1;

    .safe-name {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #222;

      .safe-chain-icon {
        top: 0;
      }
    }

    .safe-address {
      margin-top: 4px;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;
      color: rgb(133 144 166 / 85%);
    }
  }

  .icon-selected {
    width: 20px;
    height: 20px;
    background: url('~@/assets/icons/ic_selected.png') no-repeat;
    background-size: 20px 20px;
  }
}

.copy-btn {
  margin-top: 12px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #4d84ff;
  text-align: end;

  span {
    cursor: pointer;
  }
}
