.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  .line-label {
    width: 100%;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .address {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 12px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;

    background: rgb(231 235 242 / 30%);
    border: 2px solid #e7ebf2;
    border-radius: 12px;
  }

  .loading-wrapper {
    position: relative;
    height: 64px;
  }

  .fail-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;

    background: url('~@/assets/images/ic_mistake@2x.png') no-repeat;
    background-size: 80px;
  }

  .success-img {
    width: 96px;
    height: 96px;
    margin: 0 auto;

    background: url('~@/assets/images/png_success@2x.png') no-repeat;
    background-size: 96px;
  }

  .title {
    font-family: Ubuntu-Bold, Ubuntu;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    color: #222;
  }

  .subtitle {
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
  }
}

.sign-modal {
  &.connecting {
    background: transparent;
  }
}

.btn-wrapper {
  min-width: 360px;
}

.white-btn-wrapper {
  .white-btn {
    color: #4d84ff;
    background-color: #fff;
    border-color: #4d84ff;
    border-width: 2px;
  }

  :hover {
    .white-btn {
      color: #4d84ff;
      background-color: #fff;
      border-color: #4d84ff;
      border-width: 2px;
    }
  }
}
