.invite-modal-wrapper {
  display: flex;
  flex-direction: column;

  .invite-desc {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;
  }

  .invite-records-wrapper {
    margin-top: 32px;
    border: 2px solid #e7ebf2;
    border-radius: 12px;

    .invite-record-item {
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      padding: 22px 24px;

      & + .invite-record-item {
        border-top: 1px solid #edf2f9;
      }

      .invite-record-title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .selected-icon {
          width: 20px;
          height: 20px;
          background: url('~@/assets/icons/checkbox_on.png') no-repeat;
          background-size: 20px 20px;
        }

        .icon {
          width: 20px;
          height: 20px;
          background: url('~@/assets/icons/checkbox_off.png') no-repeat;
          background-size: 20px 20px;
        }

        .invite-record-title {
          margin-left: 24px;

          font-family: Ubuntu;
          font-size: 16px;
          line-height: 24px;
          color: #222;
        }
      }

      .invite-record-role {
        min-width: 108px;

        font-family: Ubuntu;
        font-size: 16px;
        line-height: 24px;
        color: #222;
      }
    }
  }
}
