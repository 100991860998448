.multisig-container {
  display: block;

  margin-right: 32px;
  margin-left: 32px;
  padding-top: 40px;
  padding-bottom: 40px;

  border-top: 1px solid #edf2f9;

  .multisig-content {
    .authorization-content-img {
      width: 96px;
      height: 96px;
      margin: 0 auto;

      background-repeat: no-repeat;
      background-size: 96px 96px;
    }

    .img-confirm {
      background-image: url('~@/assets/images/png_confirm.png');
    }

    .authorization-content-title {
      width: 100%;
      margin: 20px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 32px;
      font-weight: bold;
      line-height: 40px;
      color: #222;
      text-align: center;
    }

    .authorization-content-text {
      width: 100%;
      margin-bottom: 32px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
      text-align: center;
    }

    .go-safe-btn {
      cursor: pointer;
      font-weight: bold;
      color: #4d84ff;
      text-decoration: underline;
    }

    .authorization-content-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
