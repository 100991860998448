.team-management {
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;

  padding: 30px;
}

.empty {
  margin-bottom: 40px;
  text-align: center;
}

.img {
  width: 160px;
  margin-top: 80px;
  margin-bottom: 24px;
}

.text {
  margin-bottom: 5px;
  font-size: 16px;
  color: #8590a6;
}

.link {
  cursor: pointer;
  font-size: 16px;
  color: #4d84ff;
}
