.permission-modal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        padding-top: 0;
        padding-bottom: 32px;
      }
    }
  }

  .subtitle {
    display: flex;
    align-items: center;

    margin-top: 4px;
    margin-bottom: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #8994ab;

    .label {
      overflow: hidden;

      max-width: 100px;
      margin-left: 8px;
      padding-right: 12px;
      padding-left: 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;

      background: #a36b2b;
      border-radius: 12px;
    }
  }
}

.revoke {
  cursor: pointer;

  width: 48px;
  height: 20px;
  margin-right: 20px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4d84ff;
}

.safe-role-bot {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #65c97a;

  .bot-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_role_bot.png') no-repeat;
    background-size: 16px 16px;
  }
}
