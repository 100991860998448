.strategy-search {
  position: relative;

  .strategy-networks {
    display: flex;
    margin-bottom: 24px;
  }

  .strategy-select-warp {
    display: flex;
    flex-wrap: nowrap;
    place-content: center left;
    align-items: center;

    .strategy-select-item {
      display: flex;
      place-content: center left;
      align-items: center;
      margin-right: 24px;

      .strategy-select-label {
        display: block;

        height: 20px;
        margin-right: 8px;

        font-family: Ubuntu;
        font-size: 14px;
        line-height: 20px;
        color: #222;
      }

      .arrow_icon {
        width: 12px;
        height: 12px;
        transition: transform 300ms;
      }

      :global {
        .ant-select {
          height: 32px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 32px;
          background: transparent;
          border: 1.5px solid #e7ebf2;
          border-radius: 8px;
        }

        .ant-select-focused .ant-select-selector,
        .ant-select-selector:focus,
        .ant-select-selector:active,
        .ant-select-open .ant-select-selector {
          box-shadow: none !important;
        }

        .ant-select-selection-item {
          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 14px;
          font-weight: 500;
          color: #222;
        }

        .ant-select-arrow {
          right: 16px;
        }
      }
    }
  }
}
