.multisig-modal {
  .content {
    display: flex;
    flex-direction: column;

    &.center {
      align-items: center;
      justify-content: center;
    }

    .tip {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #222;
    }

    .safe-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;
      padding: 12px 16px;

      background: #f9f9f9;
      border: 2px solid #e7ebf2;
      border-radius: 12px;

      .name-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
          overflow: hidden;

          max-width: 148px;
          margin-left: 8px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #222;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .address {
        font-family: Ubuntu-Medium, Ubuntu;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #222;
      }
    }

    .multisig-icon {
      width: 96px;
      height: 96px;
      background-image: url('~@/assets/images/png_confirm.png');
      background-size: 96px 96px;
    }

    .multisig-title {
      margin-top: 16px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      color: #222;
    }

    .multisig-subtitle {
      margin-top: 12px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;

      .multisig-btn {
        cursor: pointer;
        color: #4d84ff;
      }
    }

    .success-image {
      width: 96px;
      height: 96px;
      background: url('~@/assets/images/png_congratulations@2x.png') no-repeat;
      background-size: 96px;
    }

    .success-module-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      margin-top: 20px;
      padding: 20px;

      background: #f9f9f9;
      border: 2px solid #e7ebf2;
      border-radius: 12px;

      .success-module-title {
        font-family: Ubuntu-Bold, Ubuntu;
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        color: #222;
      }

      .success-module-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;

        .version {
          padding: 2px 8px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 12px;
          font-weight: bold;
          line-height: 20px;
          color: #fff;

          background: #222;
          border-radius: 6px;
        }

        .address {
          margin-left: 32px;

          font-family: Ubuntu-Medium, Ubuntu;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #222;
        }
      }
    }

    .fail-image {
      width: 80px;
      height: 80px;
      background: url('~@/assets/images/ic_mistake@2x.png') no-repeat;
      background-size: 80px;
    }

    .ok-btn {
      width: 360px;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .check-btn {
      width: 360px;
      margin-top: 32px;
      margin-bottom: 32px;

      line-height: 20px;
      color: #4d84ff;

      border: 2px solid #4d84ff;
      border-radius: 12px;

      .browse-icon {
        position: relative;
        left: 20px;

        width: 10px;
        height: 10px;

        color: #4d84ff;
      }

      :global {
        .ant-btn {
          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 14px;
          font-weight: bold;
          line-height: 48px;
        }
      }
    }
  }
}
