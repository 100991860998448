.leverage-bot-info {
  display: block;
  width: 100%;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      color: #8590a6;
    }

    .data {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;
      color: #222;
      text-align: right;
    }

    .red {
      color: #f5222d;
    }
  }
}

.manage-mode {
  .item {
    .label,
    .data {
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 20px;
    }
  }
}
