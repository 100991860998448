.safe-role-wrapper {
  display: flex;
  flex-direction: column;
}

.safe-role-bot {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #65c97a;

  .bot-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    background: url('~@/assets/icons/ic_role_bot.png') no-repeat;
    background-size: 16px 16px;
  }
}

.safe-role-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 24px;

  .submit-modification {
    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4d84ff;

    background-color: #fff;
    border: 2px solid #4d84ff;

    :hover {
      background-color: #fff !important;
    }

    .count {
      margin-left: 8px;
      padding: 4px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #fff;

      background-color: #ff5c02;
    }
  }

  .safe-role-action-btn + .safe-role-action-btn {
    margin-left: 24px;
  }

  .safe-role-action-btn-content {
    height: 40px;
  }

  .queue-action-btn {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    height: 40px;
    margin-right: 24px;
    padding-right: 20px;
    padding-left: 16px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    color: #4d84ff;

    border: 2px solid #4d84ff;
    border-radius: 12px;

    .safe-role-btn-length {
      width: 20px;
      height: 20px;
      margin-left: 8px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      text-align: center;

      background: #ff5c02;
      border-radius: 10px;
    }
  }
}

.safe-role-item-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .btn {
    cursor: pointer;

    display: flex;
    // flex: 1;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;

    width: 152px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
    white-space: nowrap;
  }

  .remove {
    width: 80px;
  }
}

.delegate-wrapper {
  .delegate-address {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      overflow: hidden;

      max-width: 100px;
      margin-left: 8px;
      padding-right: 12px;
      padding-left: 12px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;

      background: #a36b2b;
      border-radius: 12px;
    }
  }
}

.tooltip-wrapper {
  display: flex;
  flex-direction: column;

  .member-info {
    display: flex;
    flex-direction: column;

    .member-item {
      display: flex;
      flex-direction: row;

      font-family: Ubuntu;
      font-size: 12px;
      line-height: 16px;

      .member-item-key {
        margin-right: 4px;
        color: #8590a6;
      }
    }

    .member-item + .member-item {
      margin-top: 4px;
    }
  }

  .member-info + .member-info {
    margin-top: 10px;
  }
}

.safe-role-edited {
  position: relative;
  top: -16px;
  left: -32px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 72px;
  height: 24px;

  font-family: Ubuntu-Medium, Ubuntu;

  /* height: 16px; */
  font-size: 12px;
  font-weight: 500;
  color: #fff;

  background: #8590a6;
  border-radius: 0 0 14px;

  .edit-icon {
    display: block;

    width: 12px;
    height: 12px;
    margin-right: 8px;

    background: url('~@/assets/images/ic_edit_12px@2x.png') no-repeat;
    background-size: cover;
  }
}
