.token-approval-table {
  .spender-name {
    cursor: pointer;

    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    place-content: center left;
    align-items: center;

    width: 360px;
    height: 20px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d84ff;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .token-box {
    display: flex;
    align-content: center;
    align-items: center;

    height: 20px;

    font-family: Ubuntu;
    font-size: 14px;
    line-height: 20px;
    color: #222;

    .token-img {
      height: 20px;
      margin-right: 10px;
    }
  }

  .tip-icon {
    cursor: pointer;

    width: 16px;
    height: 16px;
    margin-left: 8px;

    vertical-align: middle;

    background: url('~@/assets/images/tips.png') no-repeat;
    background-size: 16px 16px;
  }
}
