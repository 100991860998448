.organization-container {
  position: relative;
  width: 100%;
}

.organization-body {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 32px;

  .organization-header {
    display: block;
    width: 100%;
  }

  .organization-button {
    float: right;
    display: block;

    height: 40px;
    margin-bottom: 32px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;

    border-radius: 8px;

    .organization-icon {
      width: 12px;
      height: 12px;
    }
  }
}
