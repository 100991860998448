.bot-list {
  width: 100%;

  .strategy-card {
    margin-bottom: 32px;

    background: #fff;
    border: 1px solid #edf2f9;
    border-radius: 12px;
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

    .card-body {
      display: flex;
      flex-wrap: wrap;
      place-content: center flex-start;
      align-items: flex-start;

      padding-right: 24px;
      padding-bottom: 24px;
    }

    .card-header {
      position: relative;

      height: 56px;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 56px;
      color: #222;

      background: rgb(248 249 254 / 40%);
      border-radius: 12px 12px 0 0;
      box-shadow: inset 0 -1px 0 0 #edf2f9;

      .group-title-chain-icon {
        position: absolute;
        top: 0;

        width: 28px;
        height: 28px;

        border-radius: 12px 0 16px;
      }

      .group-bot-tokens {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        place-content: center flex-start;
        align-items: center;

        width: 24px;
        height: 24px;

        &.group-bot-tokens-2 {
          width: 36px;
        }

        &.group-bot-tokens-3 {
          width: 36px;
          height: 36px;

          .group-bot-token {
            &:nth-child(3) {
              z-index: 1;
              top: 12px;
              left: 7.5px;
            }
          }
        }

        &.group-bot-tokens-4 {
          width: 36px;
          height: 36px;

          .group-bot-token {
            &:nth-child(3) {
              z-index: 1;
              top: 12px;
              left: 0;
            }

            &:nth-child(4) {
              z-index: 1;
              top: 12px;
              left: 16px;
            }
          }
        }
      }

      .group-bot-token {
        position: absolute;
        top: 0;
        left: 0;

        width: 24px;
        height: 24px;
        margin-left: -6px;

        border-radius: 16px;

        &:nth-child(2) {
          z-index: 1;
          left: 16px;
        }
      }

      .card-header-content {
        display: flex;
        place-content: center flex-start;
        align-items: center;

        height: 56px;
        margin-left: 42px;

        .tokens-name {
          height: 24px;
          margin-left: 6px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #222;
        }

        .protocols-logos {
          // margin-left: 16px;
          .protocols-logo {
            height: 24px;

            font-family: Ubuntu-Bold, Ubuntu;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #222;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .card-title {
      display: flex;
      place-content: center flex-start;
      align-items: center;

      height: 52px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 52px;
      color: #8590a6;

      .card-label {
        color: #8590a6;
      }

      .card-text {
        margin-right: 32px;
        color: #222;
      }
    }
  }
}

.auth-empty {
  display: flex;
  flex-wrap: wrap;
  place-content: center center;
  align-items: center;

  width: 100%;
  padding: 40px 0;

  background: #fff;
  border: 1px solid #edf2f9;
  border-radius: 12px;
  box-shadow: 0 12px 24px 0 rgb(0 0 0 / 3%);

  .empty-text {
    width: 100%;
    margin-top: 20px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #8590a6;
    text-align: center;
  }
}
