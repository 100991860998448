.task-options {
  display: flex;
  flex-direction: row;

  .task-name {
    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }

  .task-checkbox {
    margin-left: 16px;

    span {
      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }

    :global {
      .ant-checkbox + span {
        padding-right: 0;
        padding-left: 8px;
      }

      .ant-checkbox {
        font-size: 20px;
        line-height: 20px;

        .ant-checkbox-inner {
          width: 20px;
          height: 20px;

          font-size: 20px;
          line-height: 20px;

          background: url('~@/assets/icons/checkbox_off_reg.png') no-repeat;
          background-size: 20px 20px;
          border: none;
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background: url('~@/assets/icons/checkbox_on_reg.png') no-repeat;
          background-size: 20px 20px;
          border: none;
        }

        .ant-checkbox-inner::after {
          display: none;
        }
      }
    }
  }

  .task-checkbox + .task-checkbox {
    margin-left: 32px;
  }
}

.task-content {
  .operation-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 26px;

    .operation-actions {
      display: flex;
      flex-direction: row;

      .operation-action-title {
        font-family: Ubuntu;
        font-size: 16px;
        line-height: 40px;
        color: #222;
      }

      .operation-action-btns {
        display: flex;
        flex-flow: row wrap;

        .operation-action-btn {
          margin-left: 16px;
        }

        .operation-action-btn-inner {
          min-width: 174px;
          padding: 8px 15px;

          font-family: Ubuntu-Bold, Ubuntu;
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: #fff;
        }
      }
    }

    .multiple-operation-note {
      margin-top: 24px;
    }
  }

  .notification-task {
    padding: 16px 24px;
    background: #f0f7fe;
    border-radius: 12px;
  }

  .task-item:first-child {
    margin-top: 32px;
  }

  .task-item:last-child {
    margin-bottom: 32px;
  }

  .task-item + .task-item {
    margin-top: 24px;
  }
}
