.authorization-popover-container {
  .key-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.popover-content {
  .authorization-popover-title {
    display: flex;
    place-content: center space-between;
    align-items: center;
    height: 20px;

    h2 {
      height: 24px;
      margin-bottom: 0;

      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #222;
    }

    .close-icon {
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: #222;
    }
  }

  .authorization-popover-content {
    display: block;

    .authorization-name {
      display: flex;
      flex-wrap: nowrap;

      width: auto;
      margin-top: 12px;
      margin-bottom: 16px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }

    .authorization-roles-title {
      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #000;
    }

    .authorization-role {
      margin-top: 8px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #8590a6;
    }
  }
}
