.walletConnect-wrapper {
  cursor: pointer;

  display: flex;
  align-items: center;

  height: 44px;
  padding: 0 40px;

  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: #8590a6;

  .walletConnect-icon {
    display: block;

    width: 20px;
    height: 20px;
    margin-right: 16px;

    background: url('~@/assets/icons/png_walletconnect.png') no-repeat;
    background-size: cover;
  }
}

.walletConnect-wrapper:hover {
  color: #4d84ff;
}

.walletConnect-modal {
  :global {
    .ant-modal-content .ant-modal-body {
      min-height: 456px;
      padding-bottom: 24px;
    }
  }
}
