.rewards {
  .rewards-selection {
    margin-bottom: 40px;
    padding: 24px;
    background: #f0f7fe;
    border-radius: 12px;
  }

  .rewards-switch-warp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 16px 0;

    .rewards-switch-title {
      height: 24px;
      margin-right: 12px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #222;
    }
  }

  :global {
    .ant-switch:focus {
      outline: 0;
      box-shadow: none;
    }

    .ant-select-selection-placeholder {
      color: #8590a6 !important;
    }

    .ant-radio + span {
      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #8590a6;
    }

    .ant-radio-wrapper-checked {
      .ant-radio-checked + span {
        color: #222;
      }
    }
  }
}

.operation-item {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 24px;

  .operation-item-label {
    height: 24px;

    font-family: Ubuntu;
    font-size: 16px;
    line-height: 24px;
    color: #222;
  }

  .reward-token {
    display: flex;
    place-content: center flex-start;
    align-items: center;
    margin-left: 16px;

    &-icon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }

    &-label {
      height: 24px;

      font-family: Ubuntu;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
    }
  }

  &.operation-item-member {
    justify-content: space-between;

    .member-select {
      width: 612px;
    }
  }
}

.operation-item-radio {
  justify-content: flex-start;
}

.add-address-wrapper {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 20px;

  line-height: 20px;

  .add-address {
    cursor: pointer;
    font-weight: bold;
    color: #4d84ff;
  }
}

.advance-settings {
  width: 100%;

  &-item {
    display: flex;
    place-content: center space-between;
    align-items: center;

    width: 100%;
    margin-bottom: 24px;

    .operation-item-content {
      flex: 1;
      max-width: 720px;
    }

    .reward-token-label {
      display: flex;
      flex-wrap: nowrap;
      place-content: center flex-start;
      align-items: center;

      min-width: 120px;
    }
  }
}
