.risk-content {
  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #222;

  span {
    color: #4d84ff;
  }
}

.btn-box {
  display: flex;
  width: 100%;

  .cancel-btn-box {
    button {
      width: 160px;
      font-family: Ubuntu-Bold, Ubuntu;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .confirm-btn-box {
    flex: 1;
    margin-right: 32px;

    button {
      width: 100%;
      margin-left: 32px;
    }
  }
}
