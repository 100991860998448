.batch-revoke-modal {
  .text-box {
    margin-bottom: 24px;

    font-family: Ubuntu-Medium, Ubuntu;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #222;

    p {
      margin-bottom: 0;
    }
  }

  .token-approval-table {
    .spender-name {
      cursor: pointer;

      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      place-content: center left;
      align-items: center;

      max-width: 240px;
      height: 20px;

      font-family: Ubuntu-Medium, Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #4d84ff;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .token-box {
      cursor: pointer;

      overflow: hidden;
      display: flex;
      align-content: center;
      align-items: center;

      width: 100px;
      height: 20px;

      font-family: Ubuntu;
      font-size: 14px;
      line-height: 20px;
      color: #222;
      text-overflow: ellipsis;
      white-space: nowrap;

      .token-img {
        height: 20px;
        margin-right: 10px;
      }
    }

    .tip-icon {
      cursor: pointer;

      width: 16px;
      height: 16px;
      margin-left: 8px;

      vertical-align: middle;

      background: url('~@/assets/images/tips.png') no-repeat;
      background-size: 16px 16px;
    }

    .allowance {
      width: 80px;
      white-space: nowrap;
    }

    :global {
      .ant-table-tbody > tr > td {
        padding: 16px;
      }

      .ant-table-thead > tr > th {
        padding: 12px 16px;
      }
    }
  }
}
