.item-content {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-left: 16px;
}

.operations {
  display: flex;
  align-items: center;

  .operation {
    display: flex;
    align-items: center;
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    .operation-title {
      margin-right: 16px;

      font-family: Ubuntu;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 24px; /* 150% */
      color: #222;
    }

    .token-selector {
      width: 240px;
    }
  }
}
