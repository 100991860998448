.notice {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 48px;
  padding: 0 32px;

  font-family: Ubuntu-Medium, Ubuntu;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #a16207;

  background: #fef08a;
}
